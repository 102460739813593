.cards-wrapper {
	margin-bottom: 80px;

	&__title {
		color: black;
		.museobold();
		font-size: 32px;
		margin-bottom: 40px;
		text-align: center;
	}
	&__list {
		text-align: center;
		margin-bottom: 40px;
	}
	&__item {
		display: inline-block;
		vertical-align: top;
		width: 20%;
		margin-right: -4px;
		padding-right: 4px;
		.box-sizing(border-box);
		margin-bottom: 1px;
	}
	&__button-wrap {
		text-align: center;
	}
}

@media screen and (max-width: 1350px) {
	.cards-wrapper {

		&__list {
			text-align: left;
		}
		&__item {
			width: 25%;
		}
	}
}

@media screen and (max-width: 1110px) {
	.cards-wrapper {
		&__item {
			width: 33.3333%;
		}
	}
}

@media screen and (max-width: 810px) {
	.cards-wrapper {
		&__item {
			width: 50%;
		}
	}
}

@media screen and (max-width: 570px) {
	.cards-wrapper {
		background-color: #fbfbfb;
		margin-bottom: 0;
		padding-bottom: 40px;

		&__item {
			width: 100%;
			margin-right: 0;
		}
	}
}

@media screen and (max-width: 415px) {
	.cards-wrapper {

		&__list {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
}
