.about {
	margin-top: 68px;

	&__block {
		margin-bottom: 70px;
		&:nth-child(2n) {
			background:  #fbfbfb;
			padding-top: 92px;
			padding-bottom: 136px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__block-photo {
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	&__block-photo-img {
		max-width: 100%;
		&_mobile {
			display: none;
		}
	}

	&__block-text {

	}
	&__title {
		color: black;
		.museobold();
		font-size: 42px;
		line-height: 1.3;
		text-align: center;
		margin-bottom: 30px;
	}
	&__link-wrap {
		margin-top: 30px;
		margin-bottom: 60px;
		text-align: center;
	}
	&__link {
		display: inline-block;
		vertical-align: top;
		height: 44px;
		background-color: #fed54e;
		border-radius: 2px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.11);
		text-decoration: none;
		line-height: 44px;
		padding: 0 18px;

		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: uppercase;
		.transition(all .3s ease);
		&:hover {
			background-color: #f5cd46;
		}
	}
	&__list {
		text-align: center;
	}
	&__item {
		display: inline-block;
		vertical-align: top;
		margin-right: 40px - 4px;
		width: calc(~"(100% - 40px * 2) / 3");
		&:nth-child(3) {
			margin-right: -4px;
		}
	}
	&__item-title {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		color: black;
		.museobold();
		font-size: 18px;
		margin-bottom: 20px;
	}
	&__item-desc {
		color: black;
		.regular();
		font-size: 14px;
		line-height: 1.4;
	}
	&__text {
		color: black;
		.regular();
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 18px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 840px) {
	.about {

		&__item {
			display: block;
			margin-right: 0;
			width: 100%;
			margin-bottom: 30px;
			&:nth-child(3) {
				margin-right: 0;
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 660px) {
	.about {
		margin-top: 20px;

		&__block {
			margin-bottom: 30px;
			&:nth-child(2) {
				padding-top: 30px;
				padding-bottom: 47px;
			}
		}
		&__block-photo-img {
			display: none;
			&_mobile {
				display: inline;
			}
		}
		&__title {
			font-size: 24px;
			margin-bottom: 20px;
		}
		&__link-wrap {
			margin-bottom: 30px;
		}
	}
}

@media screen and (max-width: 415px) {
	.about {

		&__block {
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
		}
	}
}