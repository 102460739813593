.route {
	margin-left: 20px;
	margin-right: 20px;
	display: flex;
	flex-direction: row;
	min-height: 70vh;

	&__days {
		width: 130px;
	}
	&__days-list {
		flex-direction: column;
		display: flex;
		margin-bottom: 20px;
	}
	&__days-item {
		display: block;
		width: 100%;
		box-sizing: border-box;
		text-decoration: none;
		height: 61px;
		line-height: 61px;
		background: #fbfbfb;
		padding-left: 16px;

		font-size: 14px;
		.museobold();
		color: #000000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		.transition(all .3s ease);

		&:hover {
			background: #ffffff;
			color: @green-color-hover;
		}
		&_active {
			background: #ffffff;
			&:hover {
				color: #000000;
			}
		}
		&_disabled {
			color: rgba(0, 0, 0, 0.4);
			cursor: default;
			&:hover {
				background: #fbfbfb;
				color: rgba(0, 0, 0, 0.4);
			}
		}
	}
	&__days-content-item {
		display: none;
		width: 100%;
		box-sizing: border-box;
		text-decoration: none;
		height: 40px;
		line-height: 40px;
		background: white;
		text-align: center;

		font-size: 14px;
		.museobold();
		color: #000000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		.transition(all .3s ease);
	}
	&__days-add-new {
		font-size: 14px;
		.museobold();
		color: @green-color;
		text-decoration: none;
		margin-left: 8px;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
		&_response {
			display: none;
		}
	}

	&__content-wrap {
		width: calc(~"100% - 130px");
		overflow: hidden;
		.route_watch-route-lite & {
			width: 100%;
		}
	}
	&__content {
		display: none;
		flex-direction: column;

		&_empty {
			display: none;
			justify-content: center;
		}
		&_active {
			display: flex;
		}
	}

	&__add-info {
		margin-top: 40px;
		padding-bottom: 40px;
	}
	&__add-info-text {
		.regular();
		color: rgba(0, 0, 0, 0.4);
		font-size: 16px;
		line-height: 1.5;
		//max-width: 348px;
		margin-bottom: 30px;
		text-align: center;
	}
	&__add-info-button-block {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__add-info-place {
		text-decoration: none;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: #ffffff;
		height: 40px;
		line-height: 40px;
		padding: 0 17px;
		.transition(all .3s ease);
		border: 0;
		cursor: pointer;
		margin-right: 14px;

		display: flex;
		flex-direction: row;
		align-items: center;
		&:hover {
			background: #fbfbfb;
		}
	}
	&__add-info-place-icon {
		margin-right: 10px;
		.svg-icon(icon-map-black, 12px, 16px);
	}
	&__add-info-place-text {
		font-size: 14px;
		color: #000000;
		letter-spacing: 1px;
		text-transform: uppercase;
		.museobold();
	}
	&__add-info-or {
		font-size: 14px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
		font-style: italic;
		margin-right: 12px;
	}
	&__add-info-choose-transport {
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: #ffffff;
		height: 40px;
		padding: 0 20px;

		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__add-info-transport {
		display: block;
		text-decoration: none;
		margin-right: 20px;
		width: 20px;
		height: 20px;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.transition(all .3s ease);
		}


		&_plane {

			&:after {
				.svg-icon('icon-transport-plane', 20px, 7px);
				margin-top: -3px;
				margin-left: -10px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-plane', 20px, 7px, '[fill]: #509b7f');
				}
			}
		}
		&_car {
			&:after {
				.svg-icon('icon-transport-car', 14px, 12px);
				margin-top: -6px;
				margin-left: -7px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-car', 14px, 12px, '[fill]: #509b7f');
				}
			}
		}
		&_bus {
			&:after {
				.svg-icon('icon-transport-bus', 12px, 15px);
				margin-top: -7px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-bus', 12px, 15px, '[fill]: #509b7f');
				}
			}
		}
		&_train {
			&:after {
				.svg-icon('icon-transport-train', 12px, 17px);
				margin-top: -8px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-train', 12px, 17px, '[fill]: #509b7f');
				}
			}
		}
		&_bike {
			&:after {
				.svg-icon('icon-transport-bike', 22px, 15px);
				margin-top: -7px;
				margin-left: -11px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-bike', 22px, 15px, '[fill]: #509b7f');
				}
			}
		}
		&_by-see {
			&:after {
				.svg-icon('icon-transport-by-see', 16px, 16px);
				margin-top: -8px;
				margin-left: -8px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-by-see', 16px, 16px, '[fill]: #509b7f');
				}
			}
		}
		&_by-foot {
			&:after {
				.svg-icon('icon-transport-by-foot', 12px, 18px);
				margin-top: -9px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-by-foot', 12px, 18px, '[fill]: #509b7f');
				}
			}
		}
	}


	&__content-day-title {
		width: 100%;
		.box-sizing(border-box);
		padding-left: 20px;
		color: #000000;
		font-size: 14px;
		.museobold();
		height: 49px;
		line-height: 49px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	}
	&__content-list {
		width: 100%;
		//margin-bottom: 47px;
	}
	&__item {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 40px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		background: white;
		padding-top: 32px;
		padding-bottom: 32px;
		.box-sizing(border-box);
	}
	&__item-left-part {
		width: calc(~"100% - 80px - 19px");
		.box-sizing(border-box);
		padding-right: 15px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		.route__item_place & {
			flex-direction: column;
		}
		.route_watch-route & {
			width: 100%;
		}
	}
	&__item-left-part-top-wrap {
		display: flex;
		flex-direction: row;
	}
	&__item-left-part-bottom-wrap {
		margin-left: 75px + 30px;
	}
	&__item-img {
		position: relative;
		width: 75px;
		height: 75px;

		border-radius: 50%;
		margin-right: 30px;

		.route__item_transport & {
			background: #efefef;
		}
		.route__item_place & {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
	&__item-img-icon {
		position: absolute;
		top: 50%;
		left: 50%;

		&_plane {
			.svg-icon-param('icon-transport-plane', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}

		&_car {
			.svg-icon-param('icon-transport-car', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_by-foot {
			.svg-icon-param('icon-transport-by-foot', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_train {
			.svg-icon-param('icon-transport-train', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_bike {
			.svg-icon-param('icon-transport-bike', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_bus {
			.svg-icon-param('icon-transport-bus', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_by-see {
			.svg-icon-param('icon-transport-by-see', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
	}
	&__item-number {
		position: absolute;
		top: -8px;
		left: -8px;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		line-height: 40px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		background: #ffffff;

		color: #000000;
		font-size: 16px;
		.museobold();
		text-align: center;
	}
	&__item-info {
		//width: 100%;
		flex-basis: calc(~"100% - 75px - 30px");
	}
	&__item-time {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		.museo();
		margin-bottom: 10px;
	}
	&__item-transport {
		color: #000000;
		font-size: 24px;
		.museobold();
		margin-bottom: 12px;
		line-height: 1.3;
	}
	&__item-places {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&__item-places-text {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		margin-right: 4px;
		line-height: 1.3;
	}
	&__item-places-item {
		font-size: 14px;
		color: @green-color;
		text-decoration: none;
		.medium();
		.transition(all .3s ease);
		margin-right: 4px;
		line-height: 1.3;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__item-country {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		.museo();
		margin-bottom: 10px;
	}
	&__item-place-name-wrap {
		margin-bottom: 10px;
	}
	&__item-place-name {
		color: #000000;
		font-size: 24px;
		.museobold();
		text-decoration: none;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}
	&__item-place-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
	}
	&__item-been-here {
		color: black;
		.museo();
		font-size: 13px;
		position: relative;
		margin-right: 23px;
		white-space: nowrap;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -12px;
			top: 6px;
		}
	}
	&__item-want-to-visit {
		color: black;
		.museo();
		font-size: 13px;
		white-space: nowrap;
	}
	&__item-button-block {
		display: flex;
		flex-direction: row;
	}
	&__item-button {
		&:first-child {
			margin-right: 11px;
		}
	}
	&__item-place-desc {
		margin-top: 20px;
		color: #000000;
		.regular();
		line-height: 1.43;
		font-size: 14px;
		width: 70%;
	}

	&__item-right-part {
		display: flex;
		flex-direction: row;
	}
	&__item-reorder {
		text-decoration: none;
		display: block;
		width: 40px;
		height: 40px;
		margin-right: 19px;
		position: relative;
	}
	&__item-reorder-line {
		background: #b2b2b2;
		width: 14px;
		height: 2px;
		position: absolute;
		left: 50%;
		margin-left: -7px;
		&:nth-child(1) {
			top: 14px;
		}
		&:nth-child(2) {
			top: 19px;
		}
		&:nth-child(3) {
			top: 24px;
		}
	}
	&__item-menu-wrap {
		position: relative;
		&_response {
			display: none;
			position: absolute;
			right: -15px;
			top: -20px;
		}
	}
	&__item-menu-link {
		text-decoration: none;
		display: block;
		width: 40px;
		height: 40px;
		position: relative;
	}
	&__item-menu-link-span {
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: #b2b2b2;
		position: absolute;
		left: 50%;
		margin-left: -2px;
		&:nth-child(1) {
			top: 11px;
		}
		&:nth-child(2) {
			top: 17px;
		}
		&:nth-child(3) {
			top: 23px;
		}
	}
	&__item-menu {
		position: absolute;
		right: 10px;
		top: 0;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: #ffffff;
		width: 145px;
		padding-top: 10px;
		padding-bottom: 15px;

		display: flex;
		flex-direction: column;
	}
	&__item-menu-item {
		display: inline-block;
		font-size: 14px;
		.museo();
		color: #000000;
		.transition(all .3s ease);
		text-decoration: none;
		margin-bottom: 15px;
		margin-left: 14px;
		&:hover {
			color: @green-color-hover;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__add-new-activity {
		background-color: rgba(239, 239, 239, 0.5);
		padding-top: 38px;
		padding-bottom: 42px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 1030px) {
	.route {
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (max-width: 980px) {
	.route {
		flex-direction: column;

		&__days {
			display: none;
		}
		&__days-content-item {
			display: block;
		}
		&__days-add-new {
			width: 100%;
			margin-left: 0;
			height: 40px;
			line-height: 40px;
			text-align: center;
			&_response {
				display: block;
			}
		}


		&__content-wrap {
			width: 100%;
		}
		&__content {
			flex-direction: column;
			display: block;
			&_empty {
				background-color: rgba(239, 239, 239, 0.5);
			}
		}
		&__content-list {
			display: none;
			&_active {
				display: block;
			}
		}
		&__add-info {
			display: none;
			.route__content_active & {
				display: block;
			}
		}
		&__add-info-button-block {
			flex-direction: column;
		}
		&__add-info-place {
			margin-bottom: 10px;
			margin-right: 0;
		}
		&__add-info-or {
			display: none;
		}


		&__item {
			padding-left: 18px;
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&__item-left-part {
			width: calc(~"100% - 40px - 10px");
			padding-right: 10px;
		}
		&__item-left-part-bottom-wrap {
			margin-left: 75px + 18px;
		}
		&__item-img {
			margin-right: 18px;
		}

		&__item-info {
			width: calc(~"100% - 75px - 18px");
		}
		&__item-transport {
			font-size: 18px;
		}

		&__item-right-part {
			flex-direction: column;
		}
		&__item-place-name {
			font-size: 18px;
		}
		&__item-place-desc {
			width: 100%;
		}
		&__item-reorder {
			margin-right: 0;
		}

		&__add-new-activity {
			display: none;
			&_active {
				display: flex;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.route {

		&__item {
			padding-right: 15px;
			padding-left: 15px;
			position: relative;
		}
		&__item-left-part {
			width: calc(~"100% - 30px");
			padding-right: 0;
			position: relative;
		}
		&__item-left-part-bottom-wrap {
			margin-left: 0;
		}

		&__item-place-desc {
			padding-right: 30px;
		}
		&__item-place-info {
			flex-wrap: wrap;
		}

		&__item-been-here {
			font-size: 12px;
			margin-right: 23px;
			&:after {
				content: '';
				position: absolute;
				width: 2px;
				height: 2px;
				background-color: rgba(0, 0, 0, 0.2);
				right: -12px;
				top: 6px;
			}
		}
		&__item-want-to-visit {
			font-size: 12px;
		}

		&__item-right-part {
			//display: none;
			position: absolute;
			top: 10px;
			right: 10px;
		}
		&__item-menu-wrap {

			&_response {
				display: block;
			}
		}
		&__item-menu {
			right: 0;
		}
	}
}
