.followers {
	width: 500px;
	max-height: 500px;
	//min-height: 500px;
	background-color: white;
	border-radius: 2px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 30px 20px 0 20px;
	.box-sizing(border-box);
	overflow: hidden;
	&_mobile {
		width: auto;
		max-height: none;
		border-radius: 0;
		box-shadow: none;
		max-width: 415px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 18px;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	&__return-link {
		display: inline-block;
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
		margin-bottom: 30px;
	}
	&__return-link-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
	}

	&__title {
		color: black;
		font-size: 30px;
		.museobold();
		margin-bottom: 27px;
		margin-left: 20px;
		.followers_mobile & {
			font-size: 24px;
			margin-bottom: 20px;
			margin-left: 3px;
			margin-right: 0;
		}
	}
	&__list {
		overflow: hidden;
		max-height: 400px;
		.followers_mobile & {
			overflow: visible;
			max-height: none;
		}
	}
	&__item {
		display: block;
		height: 60px;
		padding-left: 20px;
		padding-right: 20px;
		background: white;
		.transition(all .3s ease);
		//cursor: pointer;
		&:hover {
			background: #fbfbfb;
		}
		.followers_mobile & {
			padding: 0;
		}
	}
	&__item-person {
		float: left;
		text-decoration: none;
		margin-top: 10px;
		width: 70%;
		.followers_mobile & {
			width: 55%;
		}
	}
	&__item-person-photo {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		display: inline-block;
		vertical-align: middle;
		margin-right: 10px - 4px;
	}
	&__item-person-name {
		color: black;
		font-size: 16px;
		.museobold();
		width: calc(~"100% - 40px - 10px");
		padding-right: 20px;
		.box-sizing(border-box);
		margin-right: -4px;
		display: inline-block;
		vertical-align: middle;

		&:hover {
			color: @green-color;
		}
	}

	&__item-button {
		float: right;
		margin-top: 13px;
		width: 30%;
		text-align: right;

		.followers_mobile & {
			width: 45%;
		}
	}
}