.return-link {
	position: absolute;
	left: 20px;
	top: 20px;
	z-index: 25;

	background: white;
	border-radius: 2px;
	box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
	height: 30px;
	padding-left: 13px;
	padding-right: 13px;
	text-decoration: none;
	line-height: 30px;
	&:hover {
		background: #fbfbfb;
	}
	&_discussion-inner {
		display: none;
	}

	&__icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-slider-arrow);
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__text {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
	}
}

@media screen and (max-width: 1360px) {
	.return-link {
		position: relative;
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 18px;
		left: auto;
		top: auto;
		&_with-margin {
			margin-left: 18px;
		}
	}
}

@media screen and (max-width: 415px) {
	.return-link {

		&_hide-on-mobile {
			display: none;
		}
	}
}