.places-list {
	margin-bottom: 20px;
	margin-top: 17px;

	&_collection {
		padding-right: 20px;
	}
	&_community {
		margin-top: 19px;
	}
	&_resize {
		padding-right: 0;
	}

	&__title-wrap {
		padding-bottom: 51px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 20px;
		margin-top: 59px - 17px;
		padding-left: 30px;
		position: relative;
		.places-list_resize & {
			//.box-sizing(border-box);
			//max-width: calc(~"1440px - 50%");
			//width: calc(~"(100% + 300px) / 2");
			//background: #fbfbfb;
			margin-top: -10px;
			padding-top: 59px - 17px + 10px;
		}
	}
	&__title-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	&__title-line {
		margin-bottom: 20px;
		flex-basis: 70%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-right: 30px;
	}

	&__title {
		.museobold();
		font-size: 24px;
		margin-bottom: 20px;
		display: none;
		text-align: center;
		&_visible {
			text-align: left;
			display: block;
			padding-bottom: 51px;
			border-bottom: 1px solid @border-color;
			font-size: 42px;
			margin-top: 60px;
		}
		.places-list__title-wrap & {
			.museobold();
			font-size: 42px;
			display: block;
			text-align: left;
			margin-bottom: 0;
			flex-basis: calc(~"100% - 44px - 14px");
		}
		.places-list_resize & {
			width: 70%;
		}
	}
	&__tags-line {
		margin-bottom: 24px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__tags-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 9px;
		margin-bottom: 5px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__tags-item-hash {
		font-size: 14px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
	}
	&__tags-item-tag {
		font-size: 14px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			text-decoration: underline;
		}
	}

	&__title-button-block {
		flex-basis: 30%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	&__title-button {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		&:first-child {
			margin-right: 10px;
		}
	}

	&__text-info {
		width: 70%;
		.regular();
		font-size: 14px;
		.regular();
		color: black;
		line-height: 1.43;
	}
	&__text-info-link {
		.regular();
		font-size: 14px;
		color: @green-color;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__show-on-map-link {
		color: #56a789;
		.museo();
		font-size: 14px;
		text-align: center;
		text-decoration: none;
		//display: block;
		padding-bottom: 15px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 20px;
		display: none;
	}
	&__top-line {
		display: block;
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__cards-quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		margin-top: 11px;
	}
	&__sort-block {
		float: right;
		min-width: 165px;
	}
	&__sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__sort-block-select {
		width: 100%;
	}

	&__progress-block {
		position: absolute;
		right: 24px;
		top: 50px;

		.places-list_resize & {
			right: 40px;
			bottom: 41px;
			top: auto;
		}
	}
	&__progress {
		width: 44px;
		height: 44px;
		position: relative;
		//margin-left: auto;
		//margin-right: auto;
		//position: absolute;
		//right: 16px;
		//bottom: 16px;
		z-index: 20;
		//margin-bottom: 10px;
		margin-right: 14px;
		canvas {
			width: 100%;
			height: 100%;
		}
		strong {
			font-size: 11px;
			.museo();
			color: black;

			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 16px;
			line-height: normal;
		}
		&_mobile {
			float: right;
			display: none;
			margin-right: 20px;
		}
	}
	&__progress-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		.regular();
		text-align: center;
		line-height: 1.43;
	}

	&__list {
		.places-list_collection & {
			padding-left: 30px;
		}
	}
	&__item {
		padding-bottom: 30px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 30px;
		//.places-list_collection & {
		//	margin-bottom: 0;
		//	border-bottom: 0;
		//}
	}
	&__item-left-part {
		float: left;
		width: 54%;
		//padding-right: 80px;
		//.box-sizing(border-box);
		.places-list_resize & {
			width: 100%;
		}
	}
	&__item-top {
		margin-bottom: 30px;
	}
	&__place-image-wrap {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin-right: 30px - 4px;
		width: 150px;
		height: 150px;

		//.places-list_collection & {
		//	width: 75px;
		//	height: 75px;
		//}
	}
	&__place-image {
		width: 150px;
		height: 150px;
		margin-right: 30px - 4px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		text-decoration: none;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		//&_collection {
		//	margin-right: 0;
		//	width: auto;
		//	height: auto;
		//}

		//.places-list_collection & {
		//	width: 75px;
		//	height: 75px;
		//}
	}
	&__place-position {
		position: absolute;
		width: 34px;
		height: 34px;
		background-color: white;
		border-radius: 100px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		left: 0;
		top: 0;
		z-index: 20;
		line-height: 34px;
		text-align: center;

		color: black;
		.museobold();
		font-size: 16px;
		letter-spacing: 0.8px;
	}
	&__place-img {
		max-width: 100%;
		width: 100%;
		height: 100%;
	}
	&__place-text-wrap {
		display: inline-block;
		vertical-align: top;
		width: calc(~"100% - 150px - 30px");
		margin-right: -4px;
	}
	&__place-country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 10px;
	}
	&__place-name-wrap {
		margin-bottom: 14px;
		max-width: 80%;
	}
	&__place-name {
		color: black;
		.museobold();
		font-size: 24px;
		.transition(all .3s ease);
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__place-info {
		margin-bottom: 20px;
	}
	&__place-been-here {
		display: inline-block;
		vertical-align: middle;
		color: black;
		font-size: 13px;
		.museo();
		position: relative;
		margin-right: 23px;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 6px;
		}
	}
	&__place-want-to-visit {
		display: inline-block;
		vertical-align: middle;
		color: black;
		font-size: 13px;
		.museo();
	}
	&__place-button-block {
		margin-bottom: 15px;
	}
	&__place-button {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__place-person-block {

	}
	&__place-person-photo {
		margin-right: 10px;
		float: left;
	}
	&__place-person-photo-item {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		overflow: hidden;
		border-radius: 50%;
		.box-sizing(border-box);
		margin-left: -13px;
		&:first-child {
			margin-left: 0;
		}
	}
	&__place-person-photo-img {
		max-width: 100%;
	}
	&__place-person-text-wrap {
		max-width: 285px;
	}
	&__place-person-name-block {
		display: inline;
	}
	&__place-person-name {
		.museo();
		font-size: 12px;
		color: @green-color;
		text-decoration: none;
		display: inline;
		position: relative;
		&:after {
			content: ',';
			.museo();
			font-size: 12px;
			color: @green-color;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	&__place-person-text {
		.museo();
		font-size: 12px;
		color: black;
		display: inline;
	}
	&__place-text-block {
		//margin-bottom: 31px;
		padding-right: 60px;
	}
	&__place-text {
		display: block;
		font-size: 14px;
		.regular();
		line-height: 1.43;
		color: black;

	}
	&__place-text-link {
		display: inline;
		font-size: 14px;
		.regular();
		line-height: 1.43;
		color: @green-color;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__item-right-part {
		float: right;
		width: 46%;
		//padding-right: 25px;
		.box-sizing(border-box);
		text-align: left;
		.places-list_resize & {
			display: none;
		}
	}
	&__collection-item {
		width: calc(~"(100% - 17px * 2) / 3");
		margin-right: 17px - 4px;
		text-decoration: none;
		display: inline-block;
		vertical-align: top;
		&:last-child {
			margin-right: -4px;
		}
	}
	&__collection-item-image {
		height: 117px;
		width: 150px;
		border-radius: 1.4px;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		.places-list__collection-item:hover & {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__collection-item-title {
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: black;
		.museobold();
		font-size: 14px;
		line-height: 1.25;
		text-align: center;
	}

	&__button {
		text-align: center;
		margin-bottom: 12px;
		display: none;
	}
}

@media screen and (max-width: 1345px) {
	.places-list {
		&__item-left-part {
			width: 60%;
		}
		&__item-right-part {
			width: 40%;
		}
		&__collection-item {
			width: calc(~"(100% - 20px) / 2");
			&:nth-child(3) {
				display: none;
			}
			&:nth-child(2) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 1170px) {
	.places-list {
		&__progress-block {
			display: none;
		}
		&__progress {
			&_mobile {
				display: block;
			}
		}
		&__title {
			.places-list__title-wrap & {
				font-size: 32px;
			}
		}
		&__item-left-part {
			width: 75%;
		}
		&__item-right-part {
			width: 25%;
		}
		&__collection-item {
			width: 100%;
			margin-right: 0;
			&:nth-child(3) {
				display: none;
			}
			&:nth-child(2) {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 980px) {
	.places-list {

		&__item-left-part {
			width: 100%;
		}
		&__item-right-part {
			display: none;
		}
		&__title-button-block {
			flex-direction: column-reverse;
			align-items: flex-end;
		}
		&__title-button {
			&:last-child {
				margin-bottom: 10px;
			}
			&:first-child {
				margin-right: 0;
			}
		}
	}
}

@media screen and (max-width: 815px) {
	.places-list {
		&_collection {
			padding-right: 0;
		}
		&__title-wrap {
			border-bottom: 0;
			padding-bottom: 0;
			padding-left: 18px;
			padding-right: 18px;
		}
		&__title {
			display: block;

			&_visible {
				margin-bottom: 51px;
				border-bottom: 0;
				padding-bottom: 0;
			}
		}
		&__text-info {
			width: 100%;
		}
		&__top-line {
			border-top: 1px solid  #e7e7e7;
			border-bottom: 1px solid  #e7e7e7;
		}

		&__show-on-map-link {
			display: block;
			border-top: 1px solid @border-color;
			padding-top: 15px;
		}
	}
}

@media screen and (max-width: 530px) {
	.places-list {

		&__title-container {
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&__title-line {
			align-items: center;
			flex-basis: 100%;
			padding-right: 0;
		}
		&__title {
			text-align: center;
			padding-bottom: 20px;
			margin-top: 20px;
			font-size: 24px;
			padding-left: 18px;
			padding-right: 18px;
			&_visible {
				margin-bottom: 20px;
				padding-bottom: 0;
			}
			.places-list__title-wrap & {
				font-size: 24px;
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
				margin-bottom: 0;
				margin-top: 0;
			}
		}
		&__title-button-block {
			flex-direction: row;
			justify-content: flex-start;
			flex-basis: 100%;
			margin-bottom: 15px;
		}
		&__title-button {
			&:last-child {
				margin-bottom: 0;
			}
			&:first-child {
				margin-right: 10px;
			}
		}
		&__list {
			.places-list_collection & {
				padding-left: 0;
			}
		}
		&__item {
			//padding-bottom: 0;
		}
		&__place-image {
			width: 120px;
			height: 120px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		&__place-image-wrap {
			width: 120px;
			height: 120px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		&__place-text-wrap {
			display: block;
			width: 100%;
			text-align: center;
		}
		&__place-name-wrap {
			padding-left: 20px;
			padding-right: 20px;
			margin-left: auto;
			margin-right: auto;
		}

		&__place-person-block {
			max-width: 256px;
			margin-left: auto;
			margin-right: auto;
			text-align: left;
		}
		&__place-person-text-wrap {
			max-width: none;
		}
		&__place-person-name-block {
			display: inline;
		}
		&__button {
			display: block;
		}
		&__bottom-line {
			padding-left: 20px;
			padding-right: 20px;
		}
		&__cards-count-on-page {
			float: none;
			display: block;
			text-align: center;
		}
		&__pagination {
			display: none;
		}

		&__place-text-block {
			margin-bottom: 0;
			padding-right: 0;
		}

	}
}

@media screen and (max-width: 415px) {
	.places-list {

		&_collection {
			margin-top: 0;
		}

		&__title-wrap {

			.places-list_collection & {
				margin-top: 19px;
			}
		}

		&__place-text-block {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
}