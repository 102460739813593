.top-line {
	display: block;
	margin-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;

	&_with-border {
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		//padding-bottom: 1px;
		margin-bottom: 30px;
	}
	&_discussion-mobile {
		display: none;
	}
	&_with-no-padding {
		padding-left: 0;
		padding-right: 0;
	}
	//.discussion & {
	//	display: none;
	//	margin-bottom: 0;
	//
	//	&_discussion {
	//		display: block !important;
	//		margin-bottom: 40px;
	//	}
	//}
	.map-block & {
		margin-bottom: 19px;
	}


	&__left-part {
		float: left;
	}
	&__list {
		//display: inline-block;
		//vertical-align: middle;
		//margin-right: 50px;
		margin-top: 8px;
	}
	&__item {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		margin-right: 20px;
		&_map {
			//margin-top: 6px;
			margin-right: 0;
			margin-left: 30px;
		}
	}
	&__item-icon {
		display: inline-block;
		vertical-align: middle;
		&_cards {
			.svg-icon(icon-menu-cards-green, 14px, 14px);
			.top-line__item_active & {
				.svg-icon(icon-menu-cards, 14px, 14px);
			}
		}
		&_collections {
			.svg-icon(icon-menu-collections-green, 14px, 14px);
			.top-line__item_active & {
				.svg-icon(icon-menu-collections, 14px, 14px);
			}
		}
		&_places {
			.svg-icon(icon-menu-places-green, 12px, 16px);
			.top-line__item_active & {
				.svg-icon(icon-menu-places, 12px, 16px);
			}
		}
		&_map {
			.svg-icon(icon-menu-map-green, 14px, 14px);
			.top-line__item_active & {
				.svg-icon(icon-menu-map, 14px, 14px);
			}
		}
	}
	&__item-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		.museo();
		color: @green-color;
		.top-line__item:hover & {
			color: @green-color-hover;
		}
		.top-line__item_active & {
			color: rgba(0, 0, 0, 0.4);
			&:hover {
				color: rgba(0, 0, 0, 0.4);
			}
		}
	}
	&__quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		margin-top: 11px;
	}
	&__sort-block-wrap {
		float: right;
		display: flex;
		flex-direction: row;
		align-items: center;
		.map-block & {
			display: none;
		}
	}
	&__sort-block-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
	&__sort-block {

	}
	&__sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__sort-block-select {
		width: 100%;
	}
}

@media screen and (max-width: 1000px) {
	.top-line {

		&_search-results {
			padding-left: 0;
			padding-right: 0;
		}
		&__left-part {
			display: block;
			border-bottom: 1px solid rgba(0, 0, 0, 0.08);
			width: 100%;
			float: none;
			padding-bottom: 20px;
			.box-sizing(border-box);
			padding-left: 20px;
		}
	}
}

@media screen and (max-width: 815px) {
	.top-line {
		border-top: 1px solid #e7e7e7;
		border-bottom: 1px solid #e7e7e7;
		margin-bottom: 0;
		&_with-border {
			margin-bottom: 20px;
		}
		.map-block & {
			margin-bottom: 0;
			padding-bottom: 11px;
		}

		&__left-part {
			padding-bottom: 12px;
		}

		&__sort-block {
			.map-block & {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.top-line {
		.discussion & {
			display: block;
		}

		&__item {
			margin-bottom: 10px;
			&_map {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.top-line {

		&__left-part {
			padding-top: 10px;
			padding-right: 20px;
		}
		&__list {
			margin-right: 0;
			white-space: nowrap;
			overflow-x: scroll;
		}
		&__item {
			margin-right: 15px;
		}
	}
}