.photo-lists-block {
	margin-bottom: 102px;
	&_error-page {
		margin-bottom: 0;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px - 4px;
		width: calc(~"(100% - 20px * 2) / 3");

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		position: relative;
		margin-bottom: 20px;
		text-decoration: none;
		&:last-child {
			margin-right: -4px;
		}
		&:nth-child(2) {
			margin-right: -4px;
			width: calc(~"100% - 60% - 20px");
			height: 260px;
		}
		&:first-child {
			width: 60%;
			height: 260px;
		}
	}
	&__item-label {
		position: absolute;
		top: 20px;
		left: 20px;
		height: 22px;
		background-color: #fed54e;
		border-radius: 11px;
		line-height: 22px;
		padding-left: 12px;
		padding-right: 12px;

		color: black;
		.museo();
		font-size: 9px;
	}
	&__item-people-photo-block {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	&__item-people-photo {
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		.box-sizing(border-box);
		margin-right: -10px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__item-people-photo-img {
		max-width: 100%;
	}
	&__item-title {
		position: absolute;
		left: 30px;
		bottom: 30px;
		color: white;
		.museobold();
		font-size: 30px;
		line-height: 1.2;
		width: 65%;
	}
	&__item-img {
		height: 200px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		margin-bottom: 16px;
	}
	&__item-title-special {
		color: black;
		.museobold();
		font-size: 22px;
		line-height: 1.3;
		text-align: center;
		padding-left: 18px;
		padding-right: 18px;
		text-decoration: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (max-width: 1000px) {
	.photo-lists-block {
		margin-bottom: 32px;

		&__item {
			display: block;
			width: 100%;

			&:last-child {

			}
			&:nth-child(2) {
				width: 100%;
			}
			&:first-child {
				width: 100%;
			}
		}

		&__item-people-photo-block {
			.photo-lists-block__item:nth-child(1) & {
				left: 20px;
				top: 62px;
			}
		}
		&__item-title {
			left: 20px;
			bottom: 20px;
			font-size: 24px;
		}
		&__item-img {
			height: 200px;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			margin-bottom: 16px;
		}
		&__item-title-special {
			color: black;
			.museo();
			font-size: 22px;
			line-height: 1.3;
			text-align: center;
			padding-left: 18px;
			padding-right: 18px;
			text-decoration: none;
		}
	}
}

@media screen and (max-width: 570px) {
	.photo-lists-block {
		background-color: #fbfbfb;
		margin-bottom: 0;
		padding-bottom: 32px;
	}
}