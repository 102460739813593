.export-page {
  &__block-title {
    margin-top: 60px;
    text-align: center;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;

    @media (max-width: 415px) {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  &__title {
    .museobold();
    font-size: 42px;
    color: black;
    letter-spacing: 0;
    line-height: 50px;
  }

  &__sub-title {
    .regular();
    margin: 20px auto;
    font-size: 16px;
    color: black;
    letter-spacing: 0;
    line-height: 24px;

    &.container-min {
      @media (max-width: 415px) {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }
  }
}

.export-page-form {
  &__preview {
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    margin-right: 50px;
  }
  &__iframe {
    padding: 15px;
  }
  &__container {
    display: flex;
    justify-content: space-around;
    max-width: 980px;
    margin: 0 auto;
  }

  &__form-label {
    .museobold();
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px;
    position: relative;
  }

  &__form-radio input[type='radio'] {
    width:20px;
    height:20px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0 gray inset;
    &:hover {
      box-shadow:0 0 5px 0px orange inset;
    }
    &:before {
      content:'';
      display:block;
      width:60%;
      height:60%;
      margin: 20% auto;
      border-radius:50%;
    }
    &:checked {
      background: #56A789;
      box-shadow: 2px 1px 4px 0 rgba(0,0,0,0.11);
    }
  }

  &__radio-group {
    display: inline-flex;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    &, li  {
        &:not(:last-child) {
        border-right: 1px solid #CCCCCC;
      }
    }

  }


  &__radio-list {
    margin-top: 10px;
  }

  &__widget {
    margin-top: 30px;
  }

  &__size {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  &__size-input-holder {
    width: 230px;
    position: relative;
    margin-right: -15px;
  }

  &__size-input {
    width: 80%;
    border-radius: 2px;
    height: 40px;
    line-height: normal;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.2);
    box-sizing: border-box;
    padding: 9px 37px 11px 14px;
  }

  &__radio {
  position: relative;

  margin: 0 1rem 0 0 ;

  cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: rem(2);
      z-index: 1;
      width: rem(12);
      height: rem(12);
      background: #16a085;
      border-radius: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      top: rem(-4);
      left: rem(-2);
      width: 1rem;
      height: 1rem;
      background: #fff;
      border: 2px solid #f2f2f2;
      border-radius: 50%;
    }
}

  &__save-line {
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__save-right-part {
    text-align: left;
    width: 46%;
  }

  &__save-button {
    display: inline-block;
    vertical-align: middle;
    &:first-child {
      margin-right: 10px;
    }
  }

  &__save-image {
    padding: 0 50px;
  }

  &__save-code {
    padding: 0 50px;
  }
  &__save-or-label {
    margin-left: 5px;
    margin-right: 10px;
    font-size: 12px;
    color: rgba(0,0,0,0.40);
    letter-spacing: 0;
    line-height: 18px;
    font-family: Roboto;
  }

  &__radio-input {
    position: absolute;
    visibility: hidden;
    display: none;
    &:checked + .export-page-form__radio-label {
      background: #FFFFFF;
      border: 1px solid #56A789;
      border-radius: 2px;
      color: #56A789;
      margin: -1px;
    }

  }

  &__radio-label {
    .museobold();
    background: #FFFFFF;
    display: inline-block;
    cursor: pointer;
    padding: 9px 20px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__widget-label {
    .regular();
    font-size: 14px;
    color: #000000;
    line-height: 20px;
  }

  &__radio-list {
    margin-top: 16px;
  }

  &__radio-item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__title-textarea {
    display: block;
    width: 100%;
    .box-sizing(border-box);
    height: 40px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 10px;
    line-height: normal !important;
    color: black;
    .regular();
    font-size: 14px;

    &::-moz-placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, 0.4);
      font-style: italic;
      word-wrap: break-word !important;
    }
    &::-webkit-input-placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, 0.4);
      font-style: italic;
      word-wrap: break-word !important;
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, 0.4);
      font-style: italic;
      word-wrap: break-word !important;
    }
  }

  &__aditional-options-handler {
    .medium();
    text-decoration: none;
    border-bottom: 1px dashed #56A789;
    font-size: 14px;
    color: #56A789;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      right: -12px;
      height: 4px;
      width: 6px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../img/arrow-down.svg);
    }

    &__opened::after {
      transform: rotate(180deg);
    }    
  }

  &__additional-options {
    display: none;
    margin-bottom: 25px;
  }

  &__additional-options-title {
    margin-bottom: 15px;
    margin-top: 20px;
  }

  &__second-option {
    margin-top: 25px;
  }

  &__radio-map-view li {
    display: inline;
    &:not(:first-child) {
      margin-left: 25px;
    }
  }
  &__select {
    font-size: 14px;
    color: #56A789;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;
  }

  &__image-btns {
    margin-top: 10px;
  }

  &__image-btns-divider {
    margin-left: 10px;
    margin-right: 10px;
    border: 0.5px solid rgba(0,0,0,0.20)
  }

  &__change-img {
    .regular();
    font-size: 14px;
    color: #56A789;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  &__change-img-disable {
    font-size: 14px;
    color: rgba(0,0,0,0.20);
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
  }

}


.js-open {
  display: block;
}

.export-popup {
  &__tag-list {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__tag-list > div {
    display: flex;
    flex-basis: calc(50% - 14px);
    justify-content: flex-start;
    flex-direction: row;
  }

  &__tag-list > div:last-child {
    display: flex;
    margin-top: 0px;
    flex-basis: calc(100% - 22px);
    justify-content: flex-start;
    flex-direction: row;
  }

  &__tag-list > div > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  &__tag {
    padding: 5px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    &:hover {
      cursor: pointer;
    }

    span {
      font-size: 9px;
      color: rgba(0, 0, 0, 0.4);
      margin-left: 3px;
    }
  }

  &__tag-active {
    color: #56A789;
  }

  &__button-block{
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__button {
    display: inline-block;
    vertical-align: middle;
    &:first-child {
      margin-right: 10px;
    }
  }
  &__submit {
    border: 0;
    height: 40px;
    background: #56a789;
    border-radius: 2px;
    box-shadow: 2px 1px 4px rgba(0,0,0,0.11);
    padding: 0 107px;
    line-height: 40px;
    color: white;
    .museobold();
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__chosen-tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  &__chosen-tags li {
    float: left;
  }

  &__chosen-tag {
    border: 1px solid #FFFFFF;
    padding-left: 5px;
    padding-right: 5px;
    background: #FFFFFF;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.20);
    border-radius: 2px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    display: inline-block;
    height: 26px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    &:before {
      background: #fff;
      border-radius: 10px;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
      content: '';
      height: 6px;
      left: 10px;
      position: absolute;
      width: 6px;
      top: 10px;
    }
    &:after {
      background: #fff;
      border-bottom: 13px solid transparent;
      border-left: 10px solid #eee;
      border-top: 13px solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &__route-image {
    background-color: #F8F8F8;
  }
}

.export__loader {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(242, 242, 242, 0.8);
    background-image: url(../img/ajax-loader.gif);
    background-position: center center;
    background-repeat: no-repeat;
  }
  
}

@media (max-width: 1024px) {
  .export-page-form__container {
    flex-direction: column;
  }

  .export-page-form__preview {
    margin-right: 0px;
  }

  .export-page-form__form {
    padding: 16px;
  }

  .export-page-form__save-right-part {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .export-page-form__radio-group {
    flex-direction: column;
    display: flex;
  }

  .export-page-form__radio-group:not(:last-child), 
  .export-page-form__radio-group li:not(:last-child) {
    border: none;
    border-bottom: 1px solid #CCCCCC;
  }

  .export-page-form__radio-label {
    display: block;
    width: 100%;
  }

  .export-page-form__radio-input-item {
    display: flex;
  }

  .export-page-form__size {
    flex-direction: column;
  }

  .export-page-form__size-input-holder {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }

  .export-page-form__size-input-holder:last-child {
    margin-bottom: 0px;
  }

  .export-page-form__size-input {
    width: 100%;
  }

  .export-page-form__radio-map-view {
    display: flex;
    flex-direction: column;
  }

  .export-page-form__radio-item {
    margin-left: 0px;
  }

  .export-page-form__radio-map-view {
    display: flex;
    flex-direction: column;
  }

  .export-page-form__save-code {
    padding: 0 24px;
  }

  .export-page-form__save-button:first-child {
    margin-right: 0px;
  }

  .export-page-form__save-or-label {
    margin: 10px 0;
  }
}

.export-page-form__preview__outer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.export-page-form__radio-label {
  text-align: center;
}

.export-page-form__preview {
  max-width: 100vw;
}

.card-error {
  max-width: 100vw;
}
