.auth-mobile-menu {
	width: 240px;
	position: absolute;
	right: -240px;
	top: 57px;
	background: white;
	box-shadow: 0 -1px 0 #f2f2f2;
	height: 100%;
	z-index: 300;
	.transition(all .2s ease);
	&.active {
		right: 0;
	}

	&__list {
		padding-bottom: 200px;
		background: white;
	}
	&__item {
		height: 50px;
		background-color: white;
		border-bottom: 1px solid @border-color;
		display: block;
		.box-sizing(border-box);
		padding-top: 17px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__item-link {
		color: black;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		&_left {
			float: left;
		}
	}
	&__item-notification {
		width: 30px;
		height: 30px;
		float: right;
		.sprite(@icon-notification);
		margin-top: -8px;
	}
	&__item-add-new {
		width: 30px;
		height: 30px;
		float: right;
		.sprite(@icon-add-new);
		margin-top: -8px;
	}
	&__sign-out-wrap {
		height: 50px;
		background-color: white;
		border-top: 1px solid @border-color;
		display: block;
		.box-sizing(border-box);
		padding-top: 17px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__sign-out {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
}
