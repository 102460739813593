.travel-stat {
	margin-top: 50px;
	margin-bottom: 35px;

	&__all-categories-link {
		margin-bottom: 40px;
		height: 30px;
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		text-decoration: none;
		padding: 0 14px;
		line-height: 30px;
		display: inline-block;
		.transition(all .3s ease);
		&:hover {
			background: #fbfbfb;
		}
	}
	&__all-categories-link-icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-slider-arrow);
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__all-categories-link-span {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
		font-weight: 400;
		line-height: 1.25em;
	}

	&__title {
		font-size: 24px;
		color: black;
		.museobold();
		margin-bottom: 21px;
	}
	&__desc {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		line-height: 1.3;
		margin-bottom: 30px;
		padding-right: 120px;
	}
	&__stat {

	}
	&__list {
		max-width: 770px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 50%;
			top: 0;
			bottom: 0;
			width: 1px;
			background-color: #ebebeb;
		}
	}
	&__column {
		display: inline-block;
		vertical-align: top;
		margin-right: 160px - 4px;
		width: calc(~"(100% - 160px) / 2");

		&:last-child {
			margin-right: -4px;
		}

		.travel-stat__stat & {
			width: 245px;
			//margin-right: 0;
			display: inline-block;
			vertical-align: middle;
			margin-right: 45px - 4px;
			//float: left;
		}
		&_mobile {
			width: 100%;
			.box-sizing(border-box);
			padding-left: 58px;
			padding-right: 58px;
			margin-bottom: 15px;
			margin-right: 0;
		}
	}
	&__column-top {
		margin-bottom: 24px;
		min-height: 60px;
	}
	&__column-top-item {
		display: inline-block;
		vertical-align: middle;
		margin-right: 80px - 4px;
		width: calc(~"(100% - 80px) / 2");
		&:first-child {
			text-align: right;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				right: -40px;
				top: -3px;
				width: 1px;
				height: 51px;
				background-color: #ebebeb;
			}
		}
		&:last-child {
			margin-right: -4px;
			text-align: left;
		}
	}
	&__column-top-item-wrap {
		text-align: center;
		display: inline-block;
	}
	&__column-top-count {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 7px;
	}
	&__column-top-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		.travel-stat__column-top_country & {
			font-size: 30px;
			color: black;
			.museobold();
			margin-left: 20px;
		}
	}

	&__table {
		width: 100%;
	}
	&__table-head {
		border-top: 1px solid #ebebeb;
		border-bottom: 1px solid #ebebeb;

	}
	&__table-head-tr {

	}
	&__table-head-td {
		padding-top: 9px;
		padding-bottom: 9px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;

		&:first-child {
			text-align: left;
			padding-left: 20px;
		}
		&:last-child {
			text-align: right;
			padding-right: 20px;
		}
	}

	&__table-body {

	}
	&__table-tr {

	}
	&__table-td {
		padding-top: 6px;
		padding-bottom: 6px;
		&:first-child {
			text-align: left;
			padding-left: 20px;
		}
		&:last-child {
			.museo();
			font-size: 14px;
			text-align: right;
			color: rgba(0, 0, 0, 0.4);
			padding-right: 20px;
		}
		.travel-stat__table-tr:nth-child(1) & {
			padding-top: 18px;
		}
	}
	&__table-td-link {
		.museobold();
		font-size: 14px;
		color: @green-color;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}

		.travel-stat__column_black-color-links & {
			color: black;
		}
	}
	&__table-td-text {
		.museobold();
		font-size: 14px;
		color:black;
	}

	&__diagram-wrap {
		//float: right;
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 245px - 45px");
		margin-right: -4px;
		height: auto;
	}
	&__diagram {
		width: 100% !important;
		height: auto !important;
		margin-bottom: 40px;
	}
	&__diagram-text {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		line-height: 1.5;
		text-align: center;
		max-width: 425px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (max-width: 750px) {
	.travel-stat {
		.tabs_my-profile & {
			display: none;
		}
	}
}