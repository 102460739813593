.search-results {
	max-width: 1030px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	position: relative;
	height: calc(~"100vh - 61px - 30px");

	&__title {
		.museobold();
		text-align: center;
		font-size: 24px;
		color: black;
		margin-bottom: 30px;
	}
	&__list {
		margin-bottom: 20px;
		text-align: center;
	}
	&__item {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__item-link {
		color: black;
		text-decoration: none;
		font-size: 14px;
		.regular();
	}
	&__show-tags-link {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		height: 44px;
		line-height: 44px;
		width: 100%;
		text-decoration: none;
		text-align: center;
		background: white;
		font-size: 14px;
		.medium();
		color: @green-color;
		border-top: 1px solid @border-color;
	}
}