.comments {
	background: white;
	//border-top: 1px solid #f6f6f6;
	//box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.11);
	box-shadow: 0px -1px 3px -1px rgba(0, 0, 0, 0.11);
	padding-top: 30px;
	margin-bottom: 70px;

	&__title {
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 22px;
	}
	&__content {

	}
	&__leave-a-comment {
		display: block;
		margin-bottom: 20px;
	}
	&__person-photo {
		width: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: top;
		margin-top: 8px;
		margin-right: 10px - 4px;
		border-radius: 50%;
		overflow: hidden;
		.comments__list & {
			margin-top: 0;
		}
	}
	&__person-photo-img {
		max-width: 100%;
	}
	&__textarea-holder {
		display: inline-block;
		vertical-align: top;
		width: calc(~"100% - 24px - 10px");
		margin-right: -4px;
		position: relative;
	}
	&__textarea {
		background-color: white;
		border: 1px solid #e2e2e2;
		border-radius: 2px;
		height: 40px;
		width: 100%;

		color: black;
		.regular();
		font-size: 14px;
		line-height: 20px;
		padding-left: 12px;
		padding-right: 75px;
		padding-top: 10px;
		.box-sizing(border-box);

		&:focus {
			border-color: @green-color;
		}

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
	}
	&__submit {
		font-size: 14px;
		.museo();
		position: absolute;
		right: 10px;
		bottom: 10px;
		border: 0;
		background: transparent;
		padding: 0;
		color: @green-color;
		transition: all .2s ease;
		cursor: pointer;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__item-content {
		display: inline-block;
		vertical-align: top;
		width: calc(~"100% - 24px - 10px");
		margin-right: -4px;
		margin-top: 7px;
	}
	&__list {

	}
	&__item {
		margin-bottom: 20px;
	}
	&__person-name {
		color: @green-color;
		.museo();
		font-size: 14px;
		margin-bottom: 10px;
	}
	&__person-comment {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.3;
		margin-bottom: 10px;
	}
	&__serv-line {

	}
	&__date {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 14px;
	}
	&__link {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		margin-right: 14px;
	}
	&__respond {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
	}
	&__thanks {
		display: inline-block;
		vertical-align: 0;
		margin-right: 20px;
		text-decoration: none;
	}
	&__thanks-icon {
		display: inline-block;
		vertical-align: -2px;
		.svg-icon(icon-say-thanks-grey, 15px, 16px);
		.comments__thanks_active & {
			.svg-icon(icon-say-thanks-green, 15px, 16px);
		}
	}
	&__thanks-count {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		.comments__thanks_active & {
			color: @green-color;
		}
	}
	&__thanks-text {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		.comments__thanks_active & {
			color: @green-color;
		}
	}
}

@media screen and (max-width: 415px) {
	.comments {
		padding-left: 18px;
		padding-right: 18px;
	}
}