body {
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.add-new {

  &__place-label {
    position: absolute;
    margin-top: 10px;
  }

  &__place-photo {
    &:hover {
      background-color: #f2f2f2;
    }
  }

  &__place-photo-hide {
    display: none;
  }

  &__place-photo-link {
    font-family: 'MuseoSansCyrl-700',sans-serif;
    font-size: 14px;
  }

  &__map-place-search {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;

    box-sizing: border-box;
    width: 330px;
    height: 30px;
    margin-top: 10px;
    margin-left: 12px;
    padding: 0 11px 0 13px;

    text-overflow: ellipsis;

    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    outline: none;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);

    &:focus {
      border-color: #4d90fe;
    }
  }
}

.places-list {
	&__text-info {
		padding-top: 20px;
	}
}

.conv-wrap {
	margin-bottom: 0px;
	
  &__item-place-photo-img {
    height: 100%;
		width: 100%;
		object-fit: cover;
  }
}

.card-item {
  &__author {
    max-width: 250px;
  }
  &__info-line {
    bottom: 13px;
  }
  &__info-long {
    bottom: 3px;

    .card-item {
      &__said-thanks {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 1345px) {
	.places-list {
		&__title-button-block {
			flex-direction: column-reverse;
			align-items: flex-end;
		}
		&__title-button {
			&:first-child {
				margin-right: 0;
            }
        }
    }
}


@media screen and (max-width: 1170px) {
	.places-list {
		&__title-container {
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&__title-line {
			align-items: center;
			flex-basis: 100%;
			padding-right: 0;
		}
		&__title {
			font-size: 30px;
		}
		&__title-button-block {
			margin-bottom: 15px;
		}
		&__title-button {
			&:first-child {
              margin-right: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
    }
    &__title-button-block {
			flex-direction: row;
			align-items: start;
		}
  }
}

.form {
  &__input-tablet-place-label {
    display: none;
  }
  &__input-desktop-place-label {
    display: block;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    &__input-tablet-place-label {
      display: block;
      width: 100%;
    }
  }

}

@media screen and (max-width: 980px) {
	.places-list {
		&__title-container {
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&__title-line {
			align-items: center;
			flex-basis: 100%;
			padding-right: 0;
		}

		&__item-left-part {
			width: 100%;
		}
		&__item-right-part {
			display: none;
		}
		&__title-button-block {
			flex-direction: row;
			align-items: start;
		}
		&__title-button {
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-bottom: 0;
          }
		}
	}

    .form {
      &__input-desktop-place-label {
        display: none;
      }
    }
}

@media screen and (max-width: 815px) {
	.places-list {
		&__title-container {
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&__title-line {
			align-items: center;
			flex-basis: 100%;
			padding-right: 0;
    	}

		&__title-button {
			&:first-child {
              margin-right: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
		}

	.cards-desc {
		&__bottom-block {
			margin-bottom: 0;
		}
	}
  }
}


@media screen and (max-width: 600px) {
	.add-new {
      &__map-place-search {
			width: 257px;
		}
  }
}



@media screen and (max-width: 500px) {
  .add-new {
    &__block {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__cards-block-wrap {
      flex-direction: column;
    }
    &__title {
      font-size: 22px;
    }
    &__block-title {
      font-size: 18px;
    }
    &__map-place-search {
      width: 200px;
    }
    &__heading-label {
      margin-top: 20px;
    }
    &__place-photo {
      width: 150px;
      height: 150px;
      min-width: 150px;
    }
    &__map-place-search {
      width: 200px;
    }
  }
  .cards-desc {
    &__author-block {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 360px) {
  .add-new {
    &__map-place-search {
          width: 120px;
		}
	}
}

.card-on-map {
  &__image {
    width:30px;
    height:30px;
    border:4px solid #fff;
    border-radius:50%;
    cursor:pointer;
  }

  &__close {
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  &__image-is-been {
    border: 5px solid #56a789;
  }

  &__image-is-want {
    border: 5px solid #bc76a3;
  }

  &__marker {
    width:26px;
    height:40px;
    cursor:pointer;
  }


  &__marker-position {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 100px;
    box-shadow: 2px 1px 4px rgba(0,0,0,0.11);
    right: 0;
    top: 20px;
    z-index: 20;
    line-height: 20px;
    text-align: center;
    color: black;
    font-family: 'MuseoSansCyrl-700',sans-serif;
  }
}

@media screen and (max-width: 1345px){
  .content-wrap_resize {
    .places-list {
      &__title-button-block {
        flex-direction: row;
      }
    }
  }
}

.content-wrap_resize {
  .places-list {
    &__title-container {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &__title-line {
      align-items: center;
      flex-basis: 100%;
      padding-right: 0;
    }
    &__title-button-block {
      margin-bottom: 15px;
    }
    &__title-button {
			&:last-child {
        margin-bottom: 0;
			}
			&:first-child {
				margin-right: 10px;
			}
		}
  }
}

.route {
    &__item-img-icon {
		position: absolute;
		top: 50%;
		left: 50%;

		&_plane {
			.svg-icon-param('icon-transport-plane', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}

		&_car {
			.svg-icon-param('icon-transport-car', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_walk {
			.svg-icon-param('icon-transport-by-foot', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_train {
			.svg-icon-param('icon-transport-train', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_bicycle {
			.svg-icon-param('icon-transport-bike', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_bus {
			.svg-icon-param('icon-transport-bus', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
		&_ship {
			.svg-icon-param('icon-transport-by-see', 40px, 40px, '[fill]: #ffffff');
			margin-top: -20px;
			margin-left: -20px;
		}
	}

    &__add-info-transport {
		display: block;
		text-decoration: none;
		margin-right: 20px;
		width: 20px;
		height: 20px;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.transition(all .3s ease);
		}


		&_plane {

			&:after {
				.svg-icon('icon-transport-plane', 20px, 7px);
				margin-top: -3px;
				margin-left: -10px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-plane', 20px, 7px, '[fill]: #509b7f');
				}
			}
		}
		&_car {
			&:after {
				.svg-icon('icon-transport-car', 14px, 12px);
				margin-top: -6px;
				margin-left: -7px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-car', 14px, 12px, '[fill]: #509b7f');
				}
			}
		}
		&_bus {
			&:after {
				.svg-icon('icon-transport-bus', 12px, 15px);
				margin-top: -7px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-bus', 12px, 15px, '[fill]: #509b7f');
				}
			}
		}
		&_train {
			&:after {
				.svg-icon('icon-transport-train', 12px, 17px);
				margin-top: -8px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-train', 12px, 17px, '[fill]: #509b7f');
				}
			}
		}
		&_bicycle {
			&:after {
				.svg-icon('icon-transport-bike', 22px, 15px);
				margin-top: -7px;
				margin-left: -11px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-bike', 22px, 15px, '[fill]: #509b7f');
				}
			}
		}
		&_ship {
			&:after {
				.svg-icon('icon-transport-by-see', 16px, 16px);
				margin-top: -8px;
				margin-left: -8px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-by-see', 16px, 16px, '[fill]: #509b7f');
				}
			}
		}
		&_walk {
			&:after {
				.svg-icon('icon-transport-by-foot', 12px, 18px);
				margin-top: -9px;
				margin-left: -6px;
			}
			&:hover {
				&:after {
					.svg-icon-param('icon-transport-by-foot', 12px, 18px, '[fill]: #509b7f');
				}
			}
		}
	}

	&__list-top-line {
		margin-bottom: 0;
		border-bottom: none;
		padding-top: 10px;
	}
}

.route-top {
	&__route-dates-item {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
	}
	&__dates-divider {
		margin-left: 5px;
		margin-right: 5px;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
	}
}

.route-popup {
	&__select-icon {
		display: block;
		width: 20px;
		height: 20px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_car {
			&:after {
				.svg-icon(icon-transport-car, 14px, 12px);
				margin-top: -6px;
				margin-left: -7px;
			}
		}
		&_bicycle {
			&:after {
				.svg-icon(icon-transport-bike, 22px, 17px);
				margin-top: -8px;
				margin-left: -11px;
			}
		}
		&_bus {
			&:after {
				.svg-icon(icon-transport-bus, 12px, 15px);
				margin-top: -7px;
				margin-left: -6px;
			}
		}
		&_walk {
			&:after {
				.svg-icon(icon-transport-by-foot, 13px, 19px);
				margin-top: -9px;
				margin-left: -7px;
			}
		}
		&_ship {
			&:after {
				.svg-icon(icon-transport-by-see, 16px, 16px);
				margin-top: -8px;
				margin-left: -8px;
			}
		}
		&_plane {
			&:after {
				.svg-icon(icon-transport-plane, 20px, 8px);
				margin-top: -4px;
				margin-left: -10px;
			}
		}
		&_train {
			&:after {
				.svg-icon(icon-transport-train, 12px, 17px);
				margin-top: -9px;
				margin-left: -6px;
			}
		}
	}
}

@media screen and (max-width: 980px) {
	.route {
		&__add-info {
			display: block;
		}
	}
}

.choose-location {
	max-width: 100vw;
	&__list-scroll {
		overflow: auto;
	}
}

@media screen and (max-width: 500px) {
	.choose-location {
		height: 100vh;
		padding-top: 20px;
	}
	.choose-location__item {
		flex-direction: column;
		align-items: flex-start;
	}
	.choose-location__item-left-part {
		width: auto;
	}
	.choose-location__item-right-part {
		width: auto;
		margin-left: 80px;
	}
	.choose-location__title {
		font-size: 24px;
	}
	.choose-location__item-photo {
		width: 60px;
		height: 60px;
		margin-right: 20px;
	}
	.choose-location__item-name {
		font-size: 18px;
	}
	.choose-location__item-button {
		.btn_simple-white {
			padding-left: 20px;
			padding-right: 20px;
			font-size: 12px;
		}
	}
	
	#export-choose-tags {
		.choose-location__list {
			height: ~"calc(100vh - 300px)";
		}
	}
	#export-choose-route {
		.choose-location__list {
			height: ~"calc(100vh - 155px)";
		}
	}
	.export-popup__tag-list {
		flex-direction: column;
	}

	.export-popup__button-block {
		justify-content: flex-start;
	}

	.export-popup__right-part,
	.export-popup__button, 
	.export-popup__submit {
		width: 100%;
	}
}

.tabs {
	&__collection-item_no-image {
		background-image: url(../img/add-new-card-bg.jpg);
	}
}

.cards-desc {
	&__post-date {
		font-size: 13px;
	}
	&__author-name {
		font-size: 13px;
	}
	&__copyright-text {
		font-size: 13px;
	}
	&__copyright-name {
		font-size: 13px;
	}
	&__copyright-line {
		margin-top: 5px;
	}
	&__author-top {
		display: flex;
    	justify-content: space-between;
	}
	&__top-wrap {
		margin-bottom: 12px;
	}
	&__title {
		margin-top: 28px;

	}
	&__desc {
		margin-bottom: 12px;
	}
	&__serv-line {
		margin-top: 40px;
    	margin-bottom: 50px;
	}
	&__buttons-block {
		flex-flow: row wrap;
	}
}

.btn_report-button {
  background: white;
  border-radius: 2px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
  padding-left: 25px;
  color: black;
  font-family: 'MuseoSansCyrl-700', sans-serif;
  font-size: 12px;
  letter-spacing: 0.6px;
}
.btn_report-button:before {
  content: '';
  position: absolute;
  left: 14px;
  top: 9px;
  background: url(../img/src/report-icon.png) no-repeat 0 0;
  background-size: 14px 15px;
  width: 14px;
  height: 15px;
  z-index: 20;
}
.btn_report-button:hover {
  background: #fbfbfb;
}
.btn_report-button.active {
  background: #fbfbfb;
}

@media screen and (max-width: 450px) {
	.cards-desc {
		&__author-top {
			display: inline;
		}
		&__bottom-block {
			margin-bottom: 5px;
		}
	}

}

@media screen and (max-width: 402px) {
	.cards-desc {
		&__last-button {
			margin-top: 5px;
		}
	}

}


.about-mobile-block {
	max-width: 415px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 25px;

	&__title {
		font-family: 'MuseoSansCyrl-700',sans-serif;
    	margin-bottom: 22px;
    	margin-top: 7px;
		font-size: 32px;
		color: #000000;
		letter-spacing: 0;
		line-height: 38px;
	}

	&__sub-title {
    	margin-bottom: 20px;
		font-size: 22px;
		color: #000000;
		letter-spacing: 0;
		line-height: 26px;
	}

	&__icon {
		width: 80px;
    	height: 80px;
		margin-bottom: 15px;
    	background-size: cover;
    	background-position: center center;
		background-repeat: no-repeat;
	}

	&__block-text {
    	font-family: 'Roboto',sans-serif;
    	font-weight: 400;
    	line-height: 1.4;
    	font-size: 14px;
    	margin-top: 20px;
    	color: black;

	}

	&__link {
		color: #56a789;
    	font-size: 14px;
    	font-family: 'MuseoSansCyrl-700',sans-serif;
    	margin-bottom: 6px;
		text-decoration: none;
	}
}

.about-block-popup {
	background: white;
    padding: 30px 40px;
    box-sizing: border-box;
    width: 900px;

	&__list {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;

	}

	&__item {
		width: 233px;
	}

	&__block-link {
		margin-top: 16px;
	}

	&__block-text-item {
		width: 90%;
	}

}

.profile-top {
	&__logout {
		left: 25px;
		display: inline-block;
		vertical-align: middle;
		width: 40px;
		height: 40px;
		background: #bc76a3;
		border-radius: 50%;
		transition: background 0.3s ease;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			background-image: url(../img/logout.svg);
			width: 13px;
			height: 16px;
			margin-top: -7px;
			margin-left: -7px;
			z-index: 10;
		}
	}
}

@media screen and (max-width: 415px) {
	.profile-top {
		&__logout {
			left: 15px;
		}
	}
}

.header {
	padding-top: 10px;
    padding-bottom: 12px;

	&__logo {
		width: 160px;
		background-size: contain;
		background-position: center 0;
		margin-right: 32px;
	}
	&__slogan-block {
		text-align: center;
		vertical-align: middle;
	}
	&__slogan {
		position: relative;
		top: 28px;
		text-align: center;
    	color: #56a789;
    	font-family: 'Conv_MuseoSansCyrl-500',sans-serif;
    	font-size: 9px;
	}
	&__lang-wrap {
		vertical-align: inherit;
		margin-right: 16px;
	}
	&__lang {
		text-transform: uppercase;
		&:after {
			background-image: url(../img/search-dropdown.svg);
			background-position: inherit;
			width: 6px;
			height: 4px;
			right: 8px;
		}
	}
	&__lang-dropdown {
		left: -20px;
		top: 43px;

	}
	&__lang-dropdown-item {
		padding-left: 20px;
    	padding-right: 22px;
		width: 330px;
	}
	&__lang-dropdown-item-lang {
		font-size: 12px;
		text-transform: uppercase;
	}

	&__lang-dropdown-item-text-wrap {
		padding-left: 12px;
	}

	&__about {
		background: url(../img/about.svg) no-repeat 0 0;
		width: 28px;
		height: 28px;
		display: inline-block;
		vertical-align: middle;
		background-size: cover;
	}
	&__search-block {
		margin-right: 0;
	}

}

.mobile-menu {
	position: fixed;
	&__icon-about {
		background: url(../img/about.svg) no-repeat;
		background-position: ~"calc(100% - 16px)" center;
		border-top: 1px solid #e7e7e7;
		padding-top: 20px;

	}
	&__item-about-link {
    	margin-top: 0;
		color: #56a789;
		text-decoration: none;
		.medium();
		font-size: 14px;
	}
}

@media screen and (max-width: 1100px) {
	.header {
		&__search-block {
			margin-right: -50px;
		}
		&__search-input {
			width: 90px;
		}
	}
}

@media screen and (max-width: 1030px) {
	.header {
		&__about {
			display: none;
		}
	}
}




@media screen and (max-width: 450px) {
  .header {
    &__logo {
		width: 120px;
		background-size: contain;
		background-position: center 5px;
	}
	&__slogan {
		font-size: 7px;
		width: 122px;
	}
  }
}

.route-popup {
	&__distance-holder {
		&:after {
			content: attr(data-distance-name);
		}
	}
}

@media screen and (max-width: 1050px) {
  .header {
	  &__search-block {
		 margin-right: -20px;
	  }
  }
}

@media screen and (max-width: 850px) {
  .about-block-popup {
	  width: 700px;
  }
}


.ardcrd-radio{
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	&:checked + label,
	&:not(:checked) + label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
	}
	&:checked + label:before,
	&:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 2px;
		top: 2px;
		width: 16px;
		height: 16px;
		border: 1px solid #CCCCCC;
		border-radius: 100%;
		background: #fff;
	}
	&:checked + label:after,
	&:not(:checked) + label:after {
		content: '';
		width: 10px;
		height: 10px;
		background: #56a789;
		position: absolute;
		top: 6px;
		left: 6px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	&:not(:checked) + label:after {
		opacity: 0;
    	transform: scale(0);
	}
	&:checked + label:after {
		opacity: 1;
    	transform: scale(1);
	}
}



.ardcrd-checkbox {
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	&:checked + label,
	&:not(:checked) + label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
	}
	&:checked + label:before,
	&:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 2px;
		top: 2px;
		width: 14px;
		height: 14px;
		border: 1px solid #CCCCCC;
		border-radius: 2px;
	}
	&:checked + label:after,
	&:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		color: #56a789;
		position: absolute;
		top: 4px;
		left: 4px;
		transition: all 0.2s ease;
		display: block;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(../img/check.svg);
	}
	&:not(:checked) + label:after {
		opacity: 0;
    	transform: scale(0);
	}
	&:checked + label:after {
		opacity: 1;
    	transform: scale(1);
	}
}

.travel-stat__column-top-item:last-child:after {
	display: none;
}

.travel-stat__column-top__centered {
	display: flex;
	justify-content: center;

	.travel-stat__column-top-item {
		width: auto;
	}
}

.place-nav {
	box-shadow: none;
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
}

.auth-mobile-menu__list {
	padding-bottom: 0px;
	max-height: ~"calc(100% - 112px)";
	overflow-y: scroll;
}

.auth-mobile-menu,
.unauth-mobile-menu {
	top: 65px;	
	max-height: 100vh;
}

.auth-mobile-menu__sign-out-wrap {
	position: absolute;
	bottom: 62px;
	left: 0px;
	width: 100%;
	padding: 0;
	height: auto;
}

.auth-mobile-menu__sign-out {
	display: block;
	padding: 20px;
}

.card-item__info-line {
	display: flex;
	justify-content: space-between;
}

.card-item__author {
	display: flex;
	align-items: center;
}

.card-item__said-thanks {
	white-space: nowrap;
}

.card-item__author-photo {
	margin-right: 4px;
	min-width: 24px;
	min-height: 24px;
}

.card-item__author-photo-img {
	object-fit: cover;
	border-radius: 50%;
}

.card-item__img {
	margin-bottom: 0px;
}

.card-item__cards-title {
	max-height: 100px;
	overflow: hidden;
	margin-bottom: 0px;
	height: 100px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.card-item__country {
	height: 35px;
	max-height: 35px;
	overflow: hidden;
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profile-top__followers {
	text-decoration: none;
}

.profile-top__followers:after,
.profile-top__places:after {
	top: 6px;
	border-radius: 50%;
}

.profile-top__serv-bottom-text {
	border-top: none;
}

@media (max-width: 1030px) {
	.tabs__map-nav {
		top: 51px;
	}
}

.mobile-page .tabs__cards-search-block {
	display: flex;
}

.blocks-wrapper {
	padding-top: 64px;
	height: auto;
}

.auth-mobile-menu__item,
.unauth-mobile-menu__item {
	border-bottom: none;
	border-top: 1px solid #e7e7e7;
}

.auth-mobile-menu__item-link,
.unauth-mobile-menu__item-link {
	.medium();
}

.header__search-link {
	margin-left: 24px;
}

.blocks-wrapper__scroll-area {
	overflow-y: initial;
}

.mobile-menu__item-link {
	.medium();
	font-size: 16px;
	display: block;
	padding: 20px;
}

.mobile-menu__item-about-link {
	padding: 20px;
	display: block;
}

.mobile-menu__lang-item-count {
	.regular();
	font-size: 12px
}

.mobile-menu__lang-item-text {
	.regular();
	font-size: 12px
}

.mobile-menu__lang-item {
	min-height: 67px;
	height: auto;
}

.mobile-menu__lang-item-info {
	line-height: 14px;
}

.mobile-menu__item {
	height: auto;
	padding: 0px;
}

.places-list__place-person-block {
	display: flex;
	align-items: center;
}

.text-center {
	text-align: center;
}

.menu-opened {
	overflow: hidden;
}

.card-on-map__close {
	border: none;
}

.community-list__follow-block {
	align-items: center;
}

.info-aside__visitors {
	display: flex;
	align-items: center;
}

.info-aside__visitors-text-wrapper {
	margin-left: 4px;
}

.profile-top__person-info {
	line-height: 24px;
}

.travel-stat__column-top > .mobile-page__title {
	margin-top: 20px;
}

.auth-mobile-menu, 
.unauth-mobile-menu {
	position: fixed;
}

.auth-mobile-menu__item,
.unauth-mobile-menu__item {
	padding: 0;
	height: auto;
}

.auth-mobile-menu__item-link, 
.unauth-mobile-menu__item-link {
	padding: 20px;
	display: block;
}

.auth-mobile-menu__item-add-new {
	margin-top: 12px;
	margin-right: 12px;
}

.mobile-page__list {
	margin-top: 18px;
}

.content-wrap_with-vertical-line {
	border-right: 1px solid #EBEBEB;
	box-shadow: none;
}

.comments {
	border-top: 1px solid #EBEBEB;
	box-shadow: none;
}

.tabs__cards-top-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 32px;
}

.tabs__cards-search-block {
	float: none;
	margin-top: 0px;
	margin-left: 0px;
	width: auto;
	align-items: center;
}

.top-line {
	display: flex;
	justify-content: space-between;
}

.top-line__quantity {
	float: none;
}

.top-line__sort-block-wrap {
	float: none;
}

.comments__thanks-icon,
.discussion-inner__answers-item-thanks-icon {
	vertical-align: -5px;
	margin-right: 3px;
}

.comments__thanks-text,
.discussion-inner__answers-item-thanks-text {
	margin-left: 3px;
}

.tabs__collection-top-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.route__list-top-line {
	padding-top: 0px;
}

.tabs__cards-top-line-container {
	display: flex;
	width: 100%;
	padding: 0;
	align-items: center;
	justify-content: space-between;
}

.tabs__cards-sort-block-wrap {
	margin-top: 0px;
}

.tabs__collection-search-block {
	float: none;
	width: auto;
	margin-top: 0px;
}

.tabs__collection-top-line-container {
	width: 100%;
	display: flex;
    align-items: center;
    justify-content: space-between;
}