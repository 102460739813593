.reg-popup {
	background: white;
	padding: 30px 40px;
	width: 500px;
	.box-sizing(border-box);

	&__title {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid @border-color;
	}
	&__reg-with {
		display: block;
		margin-bottom: 20px;
	}
	&__reg-with-title {
		color: black;
		.museo();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
	}
	&__reg-with-list {
		display: inline-block;
		vertical-align: middle;
	}
	&__reg-with-item {
		display: inline-block;
		vertical-align: middle;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		position: relative;
		.transition(background .3s ease);
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_1 {
			background: #446aa5;
			&:after {
				.sprite(@icon-reg-1);
				margin-top: -@icon-reg-1-height / 2;
				margin-left: -@icon-reg-1-width / 2;
			}
			&:hover {
				background: darken(#446aa5, 15%);
			}
		}
		&_2 {
			background: #55acee;
			&:after {
				.sprite(@icon-reg-2);
				margin-top: -@icon-reg-2-height / 2;
				margin-left: -@icon-reg-2-width / 2;
			}
			&:hover {
				background: darken(#55acee, 15%);
			}
		}
		&_3 {
			background: #dc4e41;
			&:after {
				.sprite(@icon-reg-3);
				margin-top: -@icon-reg-3-height / 2;
				margin-left: -@icon-reg-3-width / 2;
			}
			&:hover {
				background: darken(#dc4e41, 15%);
			}
		}
	}

	&__main {

	}
	&__main-title {
		color: black;
		.museo();
		font-size: 14px;
		margin-bottom: 20px;
	}
	&__main-section {

		&_to-hide {
			display: none;
		}
	}
	&__link-wrap {
		margin-bottom: 10px;
	}
	&__link {
		float: right;
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&__button-block {

	}
	&__button {
		display: block;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}