.header__dropdown-wrapper {
    position: relative;

    .header__dropdown-outer {
        position: absolute;
        top: 37px;
        right: -20px;
        padding: 16px;
        padding-right: 0px;
    }

    .header__dropdown {
        background: #FFFFFF;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.06);
        padding: 16px;

        .header__dropdown__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        a,
        a:focus {
            .medium();
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            line-height: 20px;
            display: block;
            text-decoration: none;
            margin-bottom: 8px;
            white-space: nowrap;
            transition: color .3s ease;

            &.header__dropdown__add {
                .medium();
                font-size: 12px;
                color: #56A789;
                letter-spacing: 0;
                text-align: right;
                margin-left: 24px;
            }

            &.no-bottom {
                margin-bottom: 0px;
            }

            &:hover {
                color: #56A789;
            }
        }

        .divider {
            border-top: 1px solid #EBEBEB;
            width: 100%;
            margin-top: 16px;
            margin-bottom: 12px;
        }
    }

    .header__dropdown {
        display: none;
    }

    &:hover {
        .header__dropdown {
            display: block;
        }
    }
}

@media (max-width: 1032px) {
    .header__dropdown {
        display: none !important;
    }
}

.header__add-card-button {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    
    &:after {
        content: '';
        height: 6px;
        width: 4px;
        margin-left: 10px;
        .svg-icon(icon-add-dropdown, 6px, 4px);
        margin-bottom: 4px;
    }

    &.active:after {
        transform: rotate(180deg);
    }
}

.header__add-card {
    position: relative;
}

.add-dropdown {
    background: #FFFFFF;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.06);
    padding: 16px;
    display: none;

    a {
        font-family: MuseoSansCyrl-700;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 20px;
        display: block;
        text-decoration: none;
        margin-bottom: 8px;
        white-space: nowrap;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    a:hover,
    a:focus {
        color: #56A789;
    }
}

.add-dropdown-outer {
    position: absolute;
    top: 37px;
    left: 0px;

    &.active {
        .add-dropdown {
            display: block;
        }
    }
}