.hashtag-block {
	display: inline-block;
	vertical-align: top;
	margin-right: -4px;
	width: 301px;
	.box-sizing(border-box);
	height: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 26px;

	&_bpl {
		padding-left: 40px;
	}

	&__title-wrap {
		margin-bottom: 13px;
	}
	&__title {
		float: left;
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		//margin-bottom: 13px;
		text-align: left;
	}
	&__show-all {
		text-decoration: none;
		float: right;
		color: @green-color;
		.museo();
		//margin-top: -2px;
		font-size: 14px;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__list {
		padding-top: 25px;
		border-top: 1px solid @border-color;
	}
	&__item {
		margin-bottom: 34px;
	}
	&__item-title {
		color: rgba(0, 0, 0, 0.4);
		.museobold();
		font-size: 12px;
		letter-spacing: 2px;
		margin-bottom: 15px;
	}
	&__sub-list {

	}
	&__sub-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;
	}
	&__sub-item-link-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__sub-item-link-hash {
		color: black;
		.museo();
		font-size: 14px;
	}

	&__sub-item-link-text {
		color: black;
		.museo();
		font-size: 14px;
		text-decoration: none;
		.transition(all .3s ease);
		margin-right: 3px;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__sub-item-quantity {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 9px;
		margin-top: -2px;
	}
}

@media screen and (max-width: 815px) {
	.hashtag-block {
		display: none;
	}
}