.top-image-block {
	//height: 522px;
	height: 560px;
	width: 100%;
	margin-top: -95px;
	//padding-top: 130px;
	padding-top: 100px;
	padding-bottom: 100px;
	.box-sizing(border-box);

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		//background: rgba(255, 255, 255, 0.26);
		background: rgba(0, 0, 0, 0.35);
		z-index: 10;
	}


	&__wrap {

	}
	&__title-wrapper {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		max-width: 900px;
		margin-bottom: 20px;
		position: relative;
		z-index: 20;
	}
	&__title-line {
		display: block;

		&:first-child {
			font-family: "Times New Roman", serif;
			font-size: 70px;
			font-style: italic;
			margin-bottom: 8px;
		}
		&:last-child {
			color: white;
			.museo();
			font-size: 56px;
			line-height: 1;

		}
	}
	&__button-wrapper {
		text-align: center;
		//margin-bottom: 47px;
		position: relative;
		z-index: 20;
	}

}

@media screen and (max-width: 1030px) {
	.top-image-block {
		height: 385px;
		margin-top: -65px;
		padding-top: 135px;

		&__title-wrapper {
			max-width: 800px;
			margin-bottom: 40px;
		}
		&__title-line {
			display: block;

			&:first-child {
				font-size: 35px;
			}
			&:last-child {
				font-size: 28px;
			}
		}

	}
}

@media screen and (max-width: 350px) {
	.top-image-block {

		&__title-line {
			display: block;

			&:first-child {
				font-size: 30px;
			}
			&:last-child {
				font-size: 20px;
			}
		}

	}
}