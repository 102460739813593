.forgot-password {
	background: white;
	padding: 30px 40px;
	width: 500px;
	.box-sizing(border-box);

	&__title {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid @border-color;
	}
}