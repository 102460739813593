.blocks-wrapper {
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: hidden;
	position: relative;
	height: 100%;
	.box-sizing(border-box);
	padding-top: 57px;

	&__scroll-area {
		overflow-y: scroll;
		position: relative;
		height: 100%;
		-webkit-overflow-scrolling: touch;
		.menu-opened & {
			overflow: hidden;
		}
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 100;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		.transition(all .2s ease);
		.menu-opened & {
			opacity: 1;
			visibility: visible;
		}
	}
}


@media screen and (max-width: 1030px) {
	.blocks-wrapper {
		padding-top: 57px;
	}

}