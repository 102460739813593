.not-dropdown {
	position: absolute;
	right: 0;
	top: 53px;
	//background-color: white;
	background: white;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.11);
	max-height: 584px;
	z-index: 100;
	width: 390px;
	overflow: hidden;
	display: none;
	&_mobile-page {
		margin-top: 20px;
		display: block;
		position: relative;
		max-height: none;
		width: 100%;
		top: auto;
		right: auto;
		box-shadow: none;
		background: transparent;
	}

	&__content {

	}

	&__list {
		margin-bottom: 20px;
	}

	&__item {
		display: block;
		border-bottom: 1px solid @border-color;
		padding: 10px 20px 10px 10px;
		.not-dropdown_mobile-page & {
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	&__item-person-photo {
		float: left;
		width: 24px;
		height: 24px;
		overflow: hidden;
		border-radius: 50%;
	}
	&__item-person-photo-img {
		max-width: 100%;
	}
	&__item-text-wrap {
		margin-left: 24px + 10px;
	}
	&__item-text {
		margin-bottom: 10px;
	}
	&__item-badge {
		.regular();
		font-size: 14px;
		color: @green-color;
		display: inline;
		line-height: 1.3;
	}
	&__item-person-name {
		.regular();
		font-size: 14px;
		color: @green-color;
		display: inline;
		line-height: 1.3;
	}
	&__item-person-action {
		.regular();
		font-size: 14px;
		color: black;
		display: inline;
		line-height: 1.3;

	}
	&__item-card-name {
		.regular();
		font-size: 14px;
		color: @green-color;
		display: inline;
		line-height: 1.3;

	}

	&__item-time {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
	}
}