#container {
	position: absolute;
	//right: -400px;
	left: -30px !important;
	top: 41px !important;
	z-index: 100;
	width: 560px !important;
}

#search-results-container {
	position: absolute;
	width: 100% !important;
	left: 0 !important;
	right: 0 !important;
	top: 62px !important;
	z-index: 100;
}

.form__input-autocomplete-results {
	position: absolute;
	width: 100% !important;
	left: 0 !important;
	right: 0 !important;
	top: 63px !important;
	z-index: 100;
	&_special {
		top: 40px !important;
	}
}

.tabs__input-autocomplete-results {
	position: absolute;
	width: 100% !important;
	left: 0 !important;
	right: 0 !important;
	top: 28px !important;
	z-index: 100;
	border-radius: 0;
}

.search {

	// Suggestions
	&__suggestions {
		background-color: white;
		padding-top: 10px !important;
		padding-bottom: 20px !important;
		.box-sizing(border-box);
		max-width: 100%;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
		text-align: left;
		top: 0 !important;
		left: 0 !important;

		#search-results-container & {
			padding-right: 18px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		}
	}
	&__suggestion {
		padding: 5px 22px 5px 15px !important;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;

		&_category {
			display: none;
		}

		&:hover {
			background: #fbfbfb;
		}
		&.ui-state-focus {
			margin: 0 !important;
			background: #fbfbfb;
		}

		&.ui-state-active {
			margin: 0 !important;
		}

		&:last-of-type {
			margin-bottom: 20px !important;

			.form__input-autocomplete-results & {
				margin-bottom: 5px !important;
			}
		}
	}
	&__suggestion-link {
		color: black;
		.regular();
		font-size: 14px;
		text-decoration: none;
		&.ui-state-active {
			margin: 0 !important;
		}
	}
	&__suggestion-highlight {
		//color: #2ca1c7;
		//.bold();
		.regular();
		font-size: 14px;
		text-decoration: none;
		background: #fbfbfb;
	}

	// Product
	&__suggestion-icon {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		min-width: 20px;
		height: 20px;
		position: relative;
		margin-right: 16px;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_card {
			&:after {
				.sprite(@icon-menu-cards);
				margin-top: -@icon-menu-cards-height / 2;
				margin-left: -@icon-menu-cards-width / 2;
			}
		}
		&_collections {
			&:after {
				.svg-icon(icon-menu-collections, 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
			}
		}
		&_places {
			&:after {
				.svg-icon(icon-menu-places, 12px, 16px);
				margin-top: -8px;
				margin-left: -6px;
			}
		}
		&_hashtag {
			&:after {
				//.svg-icon(icon-hashtag, 16px, 16px);
				content: '#';
				.museobold();
				color: #b2b2b2;
				margin-top: -6px;
				margin-left: -6px;
			}
		}
		&.ui-state-active {
			margin: 0 !important;
			margin-right: 16px !important;
		}
	}

	&__suggestion-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;

		.form__input-autocomplete-results & {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		&.ui-state-active {
			margin: 0 !important;
		}
	}
	&__suggestion-text {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		color: black;
		.medium();
		font-size: 14px;
		margin-right: 10px;
		&.ui-state-active {
			margin: 0 !important;
			margin-right: 10px !important;
		}
	}

	&__suggestion-name {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		color: black;
		.medium();
		font-size: 14px;
		text-decoration: none;
		&.ui-state-active {
			margin: 0 !important;
			margin-right: 10px !important;
		}
		.form__input-autocomplete-results & {
			white-space: pre-wrap;
		}
		.tabs__input-autocomplete-results & {
			white-space: pre-wrap;
		}
	}

	&__suggestion-country {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		text-decoration: none;
		&.ui-state-active {
			margin: 0 !important;
		}
	}

	&__all-results-wrap {
		display: none;
		padding-left: 50px;
	}
	&__all-results {
		display: inline-block;
		font-size: 14px;
		.medium();
		color: @green-color;
		.transition(all .3s ease);
		text-decoration: none;

		&:hover {
			color: @green-color-hover;
		}
	}

}

@media screen and (max-width: 1325px) {
	.search {


		&__suggestion-text {
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			color: black;
			.medium();
			font-size: 14px;
			margin-right: 10px;
		}

		&__suggestion-name {
			display: block;
		}

		&__suggestion-country {
			display: block;
			margin-left: 20px + 16px;

			.form__input-autocomplete-results & {
				//margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.search {

		&__suggestion {
			padding: 5px !important;
		}
		&__suggestion-icon {
			vertical-align: top;
			margin-right: 6px;
			width: 16px;
			height: 16px;
		}

		&__suggestion-wrap {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
		&__suggestion-text {
			vertical-align: top;
			line-height: 1.43;
			white-space: normal;
		}
		&__suggestion-name {
			.form__input-autocomplete-results & {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal;
			}
		}
		&__suggestion-country {
			margin-left: 0;
		}
	}
}
