.choose-location {
	width: 700px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	background: #ffffff;
	.box-sizing(border-box);
	//padding: 30px 40px 17px 30px;
	padding-top: 40px;
	padding-bottom: 17px;
	&_mobile-page {
		box-shadow: none;
		border-radius: 0;
		width: auto;
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 18px;
		padding-right: 18px;
	}

	&__top {
		padding-left: 30px;
		padding-right: 40px;
		.choose-location_mobile-page & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__return-link {
		margin-bottom: 20px;
		width: 91px;
		.box-sizing(border-box);
		display: flex;
		flex-direction: row;
		align-items: center;

		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
	}
	&__return-link-icon {
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		color: black;
		.museo();
		font-size: 14px;
	}

	&__title {
		color: #000000;
		font-size: 30px;
		.museobold();
		margin-bottom: 20px;

		.choose-location_mobile-page & {
			font-size: 24px;
		}
	}
	&__input-holder {
		width: 100%;
		position: relative;
		margin-bottom: 30px;
	}
	&__input-holder-icon {
		position: absolute;
		left: 14px;
		top: 12px;
		.svg-icon(icon-search, 14px, 14px);
	}
	&__input {
		border-radius: 2px;
		height: 40px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		background: #ffffff;
		width: 100%;
		line-height: normal;
		.box-sizing(border-box);
		padding-left: 44px;
		padding-right: 10px;
	}

	&__subtitle-wrap {
		margin-bottom: 14px;
	}

	&__subtitle {
		color: #000000;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1px;
		.museobold();
		margin-bottom: 14px;
	}
	&__subtitle-link {
		color: #000000;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1px;
		.museobold();
		.transition(all .3s ease);
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__list {
		position: relative;
		margin-bottom: 12px;
		height: 337px;
		border-top: 1px solid #ebebeb;
		padding-left: 30px;
		padding-right: 10px;

		.choose-location_mobile-page & {
			height: auto;
			padding-left: 0;
			padding-right: 0;
		}
		#choose-places & {
			height: 70vh;
		}
	}

	&__item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ebebeb;
		padding: 20px 0;

		.choose-location_mobile-page & {
			flex-direction: column;
			padding: 18px 0;
		}
	}
	&__item-left-part {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 60%;

		.choose-location_mobile-page & {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__item-photo {
		width: 75px;
		height: 75px;
		border-radius: 50%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		overflow: hidden;
		margin-right: 30px;

		.choose-location_mobile-page & {
			margin-right: 18px;
		}
	}
	&__item-info {
		width: calc(~"100% - 75px - 30px");

		.choose-location_mobile-page & {
			width: calc(~"100% - 75px - 18px");
		}
	}
	&__item-country {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		.museo();
		margin-bottom: 10px;
	}
	&__item-name-wrap {
		margin-bottom: 15px;
	}
	&__item-name {
		color: #000000;
		font-size: 24px;
		.museobold();
		margin-bottom: 15px;

		.choose-location__item-name-wrap & {
			margin-bottom: 0;
			text-decoration: none;
			.transition(all .3s ease);

			&:hover {
				color: @green-color-hover;
			}
		}
		.choose-location_mobile-page & {
			font-size: 18px;
		}
	}
	&__item-info-block {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__item-been-here {
		color: black;
		.museo();
		font-size: 13px;
		position: relative;
		margin-right: 23px;
		white-space: nowrap;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -12px;
			top: 6px;
		}
		.choose-location_mobile-page & {
			font-size: 12px;
		}
	}
	&__item-want-to-visit {
		color: black;
		.museo();
		font-size: 13px;
		white-space: nowrap;
		.choose-location_mobile-page & {
			font-size: 12px;
		}
	}
	&__item-distance {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		font-style: italic;
		.regular();
		margin-top: 14px;
	}

	&__item-right-part {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 40%;
		.choose-location_mobile-page & {
			width: 100%;
			justify-content: center;
		}
	}
	&__item-right-part-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__item-button {
		&:first-child {
			margin-right: 10px;
		}
	}
	&__bottom {
		display: flex;
		justify-content: flex-end;
		padding-left: 30px;
		padding-right: 40px;

		.choose-location_mobile-page & {
			padding-left: 18px;
			padding-right: 18px;
			justify-content: center;
		}
	}
	&__add-new-place {
		color: @green-color;
		font-size: 14px;
		.museobold();
		text-decoration: none;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}
	&__item-right-part-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.regular();
		margin-top: 14px;
	}
}