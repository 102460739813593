.random-card {
	margin-bottom: 30px;

	&__title-wrap {
		padding-bottom: 18px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 20px;
	}
	&__title {
		float: left;
		.museobold();
		color: black;
		font-size: 14px;
		letter-spacing: 1px;
	}
	&__change-place {
		float: right;
		border: 0;
		padding: 0;
		cursor: pointer;
		.svg-icon(icon-change-place, 16px, 14px);
	}
	&__card {

	}
	&__image {
		width: 244px;
		height: 190px;
		border-radius: 2px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 16px;

		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&__country {
		font-size: 12px;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		margin-bottom: 15px;
		text-align: center;
	}
	&__name {
		color: black;
		font-size: 20px;
		line-height: 1.3;
		.museobold();
		text-align: center;
		margin-bottom: 20px;
	}
	&__info {

	}
	&__author {
		float: left;
		text-decoration: none;
	}
	&__author-photo {
		display: inline-block;
		vertical-align: middle;
		border-radius: 50%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		.box-sizing(border-box);
		margin-right: 8px;
		text-decoration: none;
	}
	&__author-name {
		font-size: 12px;
		color: @green-color;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		.museo();
	}
	&__thanks {
		float: right;
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
		margin-top: 6px;
	}

}