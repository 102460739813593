// BUTTONS
.btn {
	.transition(all .3s ease);
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border-radius: 2px;
	border: none;
	white-space: nowrap;

	text-transform: uppercase;
	text-align: center;
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;
	height: 34px;
	letter-spacing: 1px;
	line-height: 34px;
	padding-left: 14px;
	padding-right: 14px;
	.museobold();
	outline: none;

	&_block {
		padding: 0;
		display: block;
		width: 100%;
		.box-sizing(border-box);
	}
	&_been-here {
		background: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		color: black;
		font-size: 12px;
		.museobold();
		letter-spacing: 0.6px;
		&:hover {
			background: #fbfbfb;
		}
		&.active {
			background: @green-color;
			color: white;
			padding-left: 36px;
			&:after {
				content: '';
				position: absolute;
				.svg-icon(icon-button-check, 12px, 9px);
				left: 14px;
				top: 12px;
			}
			&:hover {
				background: #509b7f;
			}
		}
		&.card-on-map-button {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			box-shadow: none;
			border-radius: 0;
			padding-left: 0;
			padding-right: 0;
			&.active {
				&:after {
					display: none;
				}
			}
		}
	}
	&_want-to-visit {
		background: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		color: black;
		font-size: 12px;
		.museobold();
		letter-spacing: 0.6px;
		position: relative;
		&:hover {
			background: #fbfbfb;
		}
		&.active {
			background: @violet-color;
			color: white;
			padding-left: 36px;
			&:after {
				content: '';
				position: absolute;
				.svg-icon(icon-button-check, 12px, 9px);
				left: 14px;
				top: 12px;
			}
		}
		&.card-on-map-button {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			box-shadow: none;
			border-radius: 0;
			padding-left: 0;
			padding-right: 0;
			&.active {
				&:after {
					display: none;
				}
			}
		}
	}
	&_follow {
		background-color: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		color: black;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.6px;
		&.active {
			background: @green-color;
			color: white;
			padding-left: 36px;
			&:after {
				content: '';
				position: absolute;
				.svg-icon(icon-button-check, 12px, 9px);
				left: 14px;
				top: 12px;
			}
			&:hover {
				background: #509b7f;
			}
		}
		&.card-on-map-button {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			box-shadow: none;
			border-radius: 0;
		}
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&_share-button {
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 14px 0 38px;

		color: black;
		.museobold();
		font-size: 12px;
		letter-spacing: 0.6px;
		&:before {
			content: '';
			position: absolute;
			left: 14px;
			top: 9px;
			.svg-icon(icon-share, 14px, 15px);
			z-index: 20;
		}
		&:hover {
			background: #fbfbfb;
		}
		&.active {
			background: #fbfbfb;
		}
	}
	&_say-thanks {
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 14px 0 38px;

		color: black;
		.museobold();
		font-size: 12px;
		letter-spacing: 0.6px;
		&:before {
			content: '';
			position: absolute;
			left: 14px;
			top: 7px;
			.svg-icon(icon-say-thanks, 15px, 16px);
			z-index: 20;
		}
		&:hover {
			background: #fbfbfb;
		}
		&.active {
			background: @green-color;
			color: white;
			&:before {
				content: '';
				position: absolute;
				left: 14px;
				top: 7px;
				.svg-icon(icon-said-thanks, 15px, 16px);
				z-index: 20;
			}
			&:hover {
				background: #509b7f;
			}
		}
	}
	&_white {
		padding-left: 39px;
		padding-right: 39px;
		height: 40px;
		background-color: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;

		font-size: 12px;
		color: black;
		.transition(background .3s ease);
		&:hover {
			background: #fbfbfb;
		}
		.route-popup_mobile-page & {
			width: 100%;
			.box-sizing(border-box);
		}
	}
	&_simple-white {
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: white;
		color: #000000;
		.transition(background .3s ease);
		&:hover {
			background: #fbfbfb;
		}
		&.active {
			background: @green-color;
			color: white;
		}
		.choose-location_mobile-page & {
			font-size: 12px;
		}

	}
	&_white-long {
		padding-left: 110px;
		padding-right: 110px;
		height: 40px;
		background-color: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;

		font-size: 12px;
		color: black;
		.museo();
	}
	&_green {
		background: @green-color;
		color: white;
		&:hover {
			background: #509b7f;
		}
		.header_transparent & {
			background: rgba(255, 255, 255, 0.1);
		}
	}
	&_green-big {
		background: @green-color;
		color: white;
		padding: 0 40px;
		height: 40px;
		line-height: 40px;
		&:hover {
			background: #509b7f;
		}
		.route-popup_mobile-page & {
			width: 100%;
			.box-sizing(border-box);
		}
	}
	&_big {
		padding-left: 38px;
		padding-right: 38px;
	}
	&_yellow {
		background: #fed54e;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.11);
		padding-left: 40px;
		padding-right: 40px;
		height: 40px;
		line-height: 40px;

		color: black;
		font-size: 14px;
		&:hover {
			background: #f5cd46;
		}
	}
	&_save-collection {
		background-color: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 14px 0 38px;
		color: black;
		.museobold();
		font-size: 12px;
		letter-spacing: 0.6px;
		&:before {
			content: '';
			position: absolute;
			left: 14px;
			top: 9px;
			.svg-icon(icon-save-collection, 12px, 12px);
			z-index: 20;
		}
		&:hover {
			background: #fbfbfb;
		}
	}
}

