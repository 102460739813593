.header-new {
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #FFFFFF;
    position: fixed;
    box-sizing: border-box;

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    .header-center {
        display: flex;
        align-items: center;
    }

    .header-right {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .header-burger {
        background: none;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        background: url(../img/burger.svg) center center no-repeat transparent;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        border: none;
    }

    .header-logo-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;

        .header-logo {
            object-fit: contain;
            width: 160px;

            @media (max-width: 350px) {
                width: 140px;
            }
        }

        .header-logo-subtitle {
            margin-top: 6px;
            font-family: Roboto;
            font-weight: 500;
            font-size: 12px;
            color: #56a789;
            letter-spacing: 0.22px;
            text-align: center;
        }
    }

    .header-links {
        margin-left: 50px;
        display: flex;

        @media (max-width: 600px) {
            display: none;
        }

        .header-link {
            display: block;
            opacity: 0.9;
            font-family: Roboto;
            font-weight: 500;
            font-size: 16px;
            color: #141414;
            letter-spacing: 0;
            line-height: 20px;
            margin-right: 24px;
            padding-top: 18px;
            padding-bottom: 23px;
            border-top: 3px solid transparent;
            display: flex;
            align-content: center;
            transition: color .3s ease;

            &:hover {
                color: #509b7f;
            }

            &.active {
                border-top: 3px solid #56A789;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.mobile-menu__list {
    .padded {
        padding-left: 40px;
    }

    @media (min-width: 600px) {
        display: none;
    }
}

.mobile-menu__lang-dropdown {
    display: block !important;
}

.mobile-menu__lang-item {
    &:after {
        display: none;
    }
}

.mobile-menu__lang-item-name {
    display: block;
    text-decoration: none;
    .medium();
    font-size: 14px;
}

@media (max-width: 400px) {
    .header-new .header-logo-block {
        margin-left: 10px;
    }
}