.route-top-image {
	height: 278px;

	&__bg {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		height: 100%;
		width: 100%;
	}
}