.btn-login {
    background: #FFFFFF;
    border: 2px solid rgba(86,167,137,0.18);
    border-radius: 2px;
    .museobold();
    font-size: 14px;
    color: #56A789;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    padding: 5px 20px;
    cursor: pointer;
    outline: none;
    flex-shrink: 0;

    @media (max-width: 500px) {
        display: none;
    }
}

.auth-popup-another {
    opacity: 0.9;
    .museo();
    font-size: 14px;
    color: #141414;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px;

    a,
    a:hover,
    a:focus {
        .museobold();
        font-size: 14px;
        color: #56A789;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        text-decoration: none;
    }
}

@media (max-width: 500px) {
    .header__unauth-mobile-link {
        display: block;
    }
}
