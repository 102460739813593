.iradio_flat-yellow {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: white;
    border: 6px solid white;
    border-radius: 12px;
    .transition(all .2s ease);

    &.checked {
        background-color: #fdca15;
    }
}

.map-chackbox {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 14px;
    height: 14px;
    .box-sizing(border-box);
    border: 1px solid @border-color;
    background-color: white;
    border-radius: 4px;

    &.checked {
        background-color: #3b99fc;
    }
}