.card-slider {
	margin-bottom: 11px;
	position: relative;
	margin-top: 31px;


	&__maximize {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 40px;
		height: 40px;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 1px;
		.transition(all .3s ease);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.svg-icon(icon-maximize, 18px, 18px);
			margin-top: -9px;
			margin-left: -9px;
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.7);
		}
	}
	&__item {
		position: relative;
		overflow: hidden;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}

	&__item-image {
		//max-width: 100%;
		position: relative;
		height: auto;
		width: 100%;
	}
	&__item-image-inner {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__item-image-inner img{
		width: 100%;
	}

	&__arrow-prev {
		width: 40px;
		height: 40px;
		background-color: white;
		border-radius: 50%;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		position: absolute;
		top: 50%;
		margin-top: -20px;
		border: 0;
		left: 7%;
		cursor: pointer;
		z-index: 20;
		outline: none;
		text-decoration: none;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.sprite(@icon-slider-arrow);
			margin-top: -@icon-slider-arrow-height / 2;
			margin-left: -@icon-slider-arrow-width / 2;
			z-index: 20;
		}
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&__arrow-next {
		text-decoration: none;
		width: 40px;
		height: 40px;
		background-color: white;
		border-radius: 50%;
		box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.11);
		position: absolute;
		top: 50%;
		margin-top: -20px;
		border: 0;
		right: 7%;
		cursor: pointer;
		z-index: 20;
		outline: none;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.sprite(@icon-slider-arrow);
			margin-top: -@icon-slider-arrow-height / 2;
			margin-left: -@icon-slider-arrow-width / 2;
			.transform(rotate(180deg));
		}
		&:hover {
			background-color: #fbfbfb;
		}
	}
}

@media screen and (max-width: 1360px) {
	.card-slider {
		margin-top: 0;

		&__item {
			max-width: 700px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.card-slider {

		&__item {
			max-width: 600px;
		}
	}
}

@media screen and (max-width: 1090px) {
	.card-slider {

		&__item {
			max-width: 500px;
		}
		&__arrow-prev {
			left: 3%;
		}
		&__arrow-next {
			right: 3%;
		}

	}
}

@media screen and (max-width: 960px) {
	.card-slider {

		&__item {
			max-width: 420px;
			//margin-left: 0;
		}
		&__arrow-prev {
			display: none;
		}
		&__arrow-next {
			display: none;
		}
	}
}

@media screen and (max-width: 815px) {
	.card-slider {

		&__item {
			max-width: none;
		}
		&__maximize {
			display: none;
		}
		&__arrow-prev {
			display: block;
		}
		&__arrow-next {
			display: block;
		}
	}
}

@media screen and (max-width: 415px) {
	.card-slider {

		&__arrow-prev {
			display: none;
		}
		&__arrow-next {
			display: none;
		}
	}
}
