/* @import must be at top of file, otherwise CSS will not work */
//@import url("http://hello.myfonts.net/count/327346");


@font-face {font-family: 'MuseoSansCyrl-700';src: url('../fonts/327346_0_0.eot');src: url('../fonts/327346_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/327346_0_0.woff2') format('woff2'),url('../fonts/327346_0_0.woff') format('woff'),url('../fonts/327346_0_0.ttf') format('truetype');}


@font-face {
	font-family: 'Conv_MuseoSansCyrl-500';
	src: url('../fonts/MuseoSansCyrl-500.eot');
	src: local('☺'), url('../fonts/MuseoSansCyrl-500.woff') format('woff'), url('../fonts/MuseoSansCyrl-500.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-500.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Futura PT Demi';
	src: url('../fonts/Futura PT Demi.eot');
	src: local('☺'), url('../fonts/Futura PT Demi.woff') format('woff'), url('../fonts/Futura PT Demi.ttf') format('truetype'), url('../fonts/Futura PT Demi.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');


.futura() {
	font-family: 'Conv_Futura PT Demi', sans-serif;
}

.museo() {
	font-family: 'Conv_MuseoSansCyrl-500', sans-serif;
}

.museobold() {
	font-family: 'MuseoSansCyrl-700', sans-serif;
}


.regular() {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

.medium() {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}