.conv-wrap {
	margin-bottom: 40px - 30px;

	&_tab-discussion {
		margin-top: 50px;
	}

	&__title {
		margin-bottom: 40px;
		color: black;
		.museobold();
		font-size: 32px;
		text-align: center;
	}
	&__list {

	}
	&__item {
		display: inline-block;
		vertical-align: top;
		width: calc(~"(100% - 30px * 3) / 4");
		background-color: white;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.11);
		margin-right: 30px - 4px;
		.box-sizing(border-box);
		padding: 30px;
		margin-bottom: 30px;
		&:nth-child(4n) {
			margin-right: -4px;
		}
		&:last-child {
			margin-right: -4px;
		}
		.mobile-page & {
			width: 100% !important;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	&__item-title-wrap {
		margin-bottom: 23px;
		display: block;
	}
	&__item-title {
		color: black;
		.museobold();
		font-size: 20px;
		line-height: 1.3;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__item-person {
		text-decoration: none;
		margin-bottom: 16px;
		display: block;
	}
	&__item-person-photo {
		width: 24px;
		height: 24px;
		border: 2px solid white;
		.box-sizing(border-box);
		border-radius: 50%;
		overflow: hidden;
		margin-right: 6px;
		display: inline-block;
		vertical-align: middle;
	}
	&__item-person-photo-img {
		max-width: 100%;
	}
	&__item-person-name {
		color: @green-color;
		font-size: 14px;
		.museo();
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
	}

	&__item-question {
		color: black;
		.regular();
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 10px;
	}
	&__item-post-date {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 14px;
	}
	&__item-bottom {
		border-top: 1px solid @border-color;
		padding-top: 18px;
	}
	&__item-place-photo {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	&__item-place-photo-img {
		max-width: 100%;
	}
	&__item-place-desc {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 40px - 10px");
		margin-right: -4px;
	}
	&__item-place-country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 6px;
	}
	&__item-place-name-wrap {

	}
	&__item-place-name {
		color: black;
		.museobold();
		font-size: 16px;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
}

@media screen and (max-width: 1300px) {
	.conv-wrap {

		&__item {
			width: calc(~"(100% - 30px * 2) / 3");
			&:nth-child(4n) {
				margin-right: 30px - 4px;
			}
			&:nth-child(3n) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.conv-wrap {

		&__item {
			width: calc(~"(100% - 30px) / 2");
			&:nth-child(3n) {
				margin-right: 30px - 4px;
			}
			&:nth-child(2n) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.conv-wrap {

		&__item {
			width: calc(~"(100% - 30px) / 2");
			&:nth-child(3n) {
				margin-right: 30px - 4px;
			}
			&:nth-child(2n) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 570px) {
	.conv-wrap {
		background-color: #fbfbfb;
		margin-bottom: 0;
		padding-bottom: 40px - 30px;

		&__title {
			text-align: center;
		}

		&__item {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}
