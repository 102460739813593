.search-block {
	padding-right: 20px;
	padding-left: 20px;
	margin-top: 20px;
	margin-bottom: 10px;

	&__input-holder {
		width: 300px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	&__input-holder-icon {
		position: absolute;
		.svg-icon(icon-search, 14px, 14px);
		left: 14px;
		top: 13px;
	}
	&__input {
		border-radius: 2px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		height: 41px;
		width: 100%;
		padding: 0 10px 0 38px;
		font-size: 14px;
		.museo();
		.box-sizing(border-box);

		&:focus {
			border-color: @yellow-color;
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
	}

	&__tags-list {
		display: inline-block;
		vertical-align: middle;
	}
	&__tags-item {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		line-height: 36px;
		height: 36px;
		background: white;
		border-radius: 2px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		padding: 0 24px 0 10px;
		text-decoration: none;
		position: relative;
		&:hover {
			background: #fbfbfb;
		}
	}
	&__tags-item-text {
		font-size: 14px;
		.regular();
		color: black;
	}

	&__tags-item-icon {
		width: 8px;
		height: 8px;
		position: absolute;
		right: 8px;
		top: 14px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(45deg));
			left: 0;
			top: 4px;
			.search-block__tags-item:hover & {
				background-color: #000;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(-45deg));
			left: 0;
			top: 4px;
			.search-block__tags-item:hover & {
				background-color: #000;
			}
		}

	}
}

@media screen and (max-width: 1030px) {
	.search-block {
		&__input-holder {
			display: none;
		}
	}
}

@media screen and (max-width: 510px) {
	.search-block {
		padding-right: 0;
		padding-left: 0;
		margin-top: 0;

		&__tags-list {
			display: block;
		}

		&__tags-item {
			line-height: 36px;
			height: 36px;
		}
		&__tags-item-icon {
			top: 15px;
		}
	}
}

@media screen and (max-width: 415px) {
	.search-block {
		&__tags-list {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
}