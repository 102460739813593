.add-new {

	&__title {
		.museobold();
		font-size: 42px;
		color: black;
		margin-top: 60px;
		text-align: center;
		padding-bottom: 50px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 40px;
	}

	//Common styles
	&__block {
		display: block;
		&:first-child {
			margin-bottom: 50px;
		}
		&:last-child {
			margin-bottom: 58px;
		}
	}
	&__block-top-line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 40px;
	}
	&__block-title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__block-title {
		color: black;
		font-size: 30px;
		text-align: left;
		.museobold();
		margin-right: 25px;
	}
	&__block-link {
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		//margin-top: 15px;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}
	&__change-link {
		font-size: 14px;
		.museobold();
		color: rgba(0, 0, 0, 0.4);
		text-decoration: none;
		.transition(all .3s ease);
		margin-top: 6px;
		&:hover {
			color: @green-color-hover;
		}
		.add-new__block_open & {
			display: none;
		}
		.add-new_edit & {
			color: @green-color;
		}
	}

	//Place block

	&__place-block-wrap {
		margin-bottom: 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	&__place-photo-wrap {
		display: flex;
		justify-content: flex-end;
		width: 300px;
		min-width: 300px;
		margin-right: 120px;
	}
	&__place-photo {
		width: 150px;
		height: 150px;
		min-width: 150px;
		background-color: #f2f2f2;
		border-radius: 50%;

		display: flex;
		justify-content: center;
		align-items: center;

		text-decoration: none;
		.transition(all .3s ease);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		&:hover {
			background-color: darken(#f2f2f2, 10%);
		}
	}
	&__place-photo-text {
		font-size: 12px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
		line-height: 1.5;
		text-align: center;
		width: 80%;

		.add-new__cards-photo & {
			width: 55%;
		}

		.add-new__place-photo_loader & {
			display: none;
		}
		.add-new__cards-photo_loader & {
			display: none;
		}
	}
	&__place-photo-link {
		font-size: 12px;
		.regular();
		color: @green-color;
		line-height: 1.5;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}
	&__place-info {
		width: calc(~"100% - 300px - 120px");
	}

	&__place-preview {
		text-decoration: none;
		display: block;
		.add-new_edit & {
			display: block;
		}
		.add-new__block_open & {
			display: none;
		}
	}
	&__place-preview-photo {
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		border-radius: 50%;

		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		margin-right: 17px;
	}
	&__place-preview-text {
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		text-decoration: none;
	}
	&__place-preview-country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 8px;
	}
	&__place-preview-name {
		color: black;
		font-size: 16px;
		.museobold();
	}

	&__block-container {
		display: none;
		.add-new_edit & {
			display: none;
		}
		.add-new__block_open & {
			display: block;
		}
	}


	//Map block
	&__map-block {
		width: 100%;
		//height: 175px;
		height: 220px;
		position: relative;
		margin-bottom: 50px;
		//.transition(all .3s ease);
		&_open {
			height: 400px;
		}
		&_response {
			display: none;
		}
	}
	&__map-block-resize {
		position: absolute;
		bottom: -20px;
		width: 16px;
		height: 10px;
		left: 50%;
		margin-left: 8px;
	}
	&__map-block-resize-line {
		left: 0;
		right: 0;
		height: 2px;
		position: absolute;
		background: @green-color;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 4px;
		}
		&:last-child {
			bottom: 0;
		}
	}
	&__map {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__map-itself {
		width: 100%;
		height: 100%;
	}

	//Cards block
	&__cards-block-top-line {
		display: block;
		margin-bottom: 40px;
	}
	&__cards-block-title-wrap {
		float: left;
	}

	&__cards-block-wrap {
		display: flex;
		flex-direction: row;
	}
	&__cards-block-optional {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-top: 10px;
	}
	&__cards-block-link {
		float: right;
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		margin-top: 15px;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}
	&__cards-photo-block {
		width: 400px;
		margin-right: 70px;
	}
	&__cards-photo {
		display: block;
		width: 100%;
		//height: 300px;
		height: 0;
		padding-bottom: 75%;
		position: relative;
		background-color: #f2f2f2;
		border-radius: 2px;
		margin-bottom: 10px;
		text-decoration: none;
		.transition(all .3s ease);

		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		&:hover {
			background-color: darken(#f2f2f2, 10%);
		}
	}
	&__cards-photo-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
	&__cards-photo-text {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		line-height: 1.6;
		//padding-left: 50px;
		//padding-right: 50px;
		text-align: left;
		margin-bottom: 10px;
	}
	&__cards-photo-copyright {
		text-decoration: none;
		color: @green-color;
		.museo();
		font-size: 13px;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__cards-info {
		width: calc(~"100% - 400px - 70px");
	}
	&__cards-info-text {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		line-height: 1.6;
	}
	&__textarea {
		display: block;
		width: 100%;
		.box-sizing(border-box);
		height: 40px;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		padding: 10px;
		line-height: normal !important;
		color: black;
		.regular();
		font-size: 14px;

		-webkit-appearance: none;
		&:focus {
			border-color: @yellow-color;
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
			word-wrap: break-word !important;

		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
			word-wrap: break-word !important;

		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
			word-wrap: break-word !important;

		}

		.form__input-holder_alert & {
			border: 1px solid #e7455d;
		}
	}
	&__tags-list {
		margin-bottom: 10px;
		text-align: left;
	}
	&__tags-item {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		text-decoration: none;
		margin-bottom: 10px;

		padding: 0 24px 0 10px;
		height: 36px;
		line-height: 36px;
		background-color: white;
		border-radius: 2px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.11);
		position: relative;
		.transition(all .3s ease);
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&__tags-item-text {
		color: black;
		font-size: 14px;
		.regular();
	}
	&__tags-item-close {
		width: 8px;
		height: 8px;
		position: absolute;
		right: 8px;
		top: 8px + 6px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(45deg));
			left: 0;
			top: 4px;
			.add-new__tags-item:hover & {
				background-color: black;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(-45deg));
			left: 0;
			top: 4px;
			.add-new__tags-item:hover & {
				background-color: black;
			}
		}

	}
	&__textarea-feedback {
		position: absolute;
		right: 0;
		top: -20px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
	}
	&__add-another-card {
		margin-top: 17px;
	}
	&__add-another-card-link {
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		display: inline-block;

		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}

	//Publish line
	&__publish-line {
		border-top: 1px solid #ebebeb;
		padding-top: 16px;
		padding-bottom: 16px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	&__publish-right-part {
		text-align: left;
		width: 50%;

		.add-new_edit & {
			text-align: right;
		}
	}
	&__publish-button {
		display: inline-block;
		vertical-align: middle;
		&:first-child {
			margin-right: 10px;
		}
		&_mobile {
			display: none;
		}
	}
	&__publish-button-cancel {
		display: inline-block;
		border-radius: 2px;
		height: 40px;
		line-height: 40px;
		background: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		text-decoration: none;
		color: black;
		.museobold();
		font-size: 12px;
		letter-spacing: 0.6px;
		padding: 0 39px;
		text-align: center;
		.transition(all .3s ease);
		&:hover {
			background: #fbfbfb;
		}
	}
	&__publish-button-save {
		border: 0;
		height: 40px;
		background: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 81px;
		line-height: 40px;

		color: white;
		.museobold();
		letter-spacing: 1px;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;
		.transition(all .3s ease);
		&:hover {
			background: @green-color-hover;
		}
	}


	&__loader {
		background: url(../img/ajax-loader.gif) no-repeat 0 0;
		background-size: contain;
		display: none;
		width: 25px;
		height: 25px;
		.add-new__place-photo_loader & {
			display: block;
		}
		.add-new__cards-photo_loader & {
			display: block;
		}
	}
}

@media screen and (max-width: 1000px) {
	.add-new {

		&__place-block-wrap {
			flex-direction: column-reverse;
			justify-content: center;
			align-items: center;
		}

		&__place-info {
			width: 100%;
			margin-bottom: 20px;
		}
		&__place-photo-wrap {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-width: 100%;
			margin-right: 0;
		}
		&__place-photo {
			width: 300px;
			height: 300px;
			min-width: 300px;
			background-color: #f2f2f2;
		}

		&__map-block {
			display: none;
			&_response {
				display: block;
			}
		}

		&__cards-block-top-line {
			display: block;
			margin-bottom: 40px;
		}
		&__cards-block-title-wrap {
			float: left;
		}
		&__cards-block-optional {
			color: rgba(0, 0, 0, 0.4);
			.museo();
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			margin-top: 10px;
		}
		&__cards-block-wrap {
			flex-direction: column-reverse;
		}

		&__cards-photo-block {
			margin-left: auto;
			margin-right: auto;
		}

		&__cards-info {
			width: 100%;
			margin-bottom: 20px;
		}
		&__publish-right-part {
			width: auto;
		}
	}
}

@media screen and (max-width: 690px) {
	.add-new {

		&__block-top-line {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		&__block-title-wrap {
			margin-bottom: 10px;
		}
		&__change-link {
			margin-top: 0;
		}

		&__add-another-card {
			margin-top: 17px;
		}
		&__add-another-card-link {
			color: #56a789;
			.museobold();
			font-size: 14px;
			text-decoration: none;
			display: inline-block;

			.transition(all .3s ease);
			&:hover {
				color: @green-color-hover;
			}
		}
		&__publish-line {
			padding-top: 12px;
			padding-bottom: 20px;
			justify-content: center;
		}
		&__publish-right-part {
			text-align: center;
		}
		&__publish-button {
			display: block;
			&:first-child {
				display: none;
			}
			&_mobile {
				display: block;
				margin-top: 20px;
			}
			.add-new_edit & {
				display: inline-block;
				&:first-child {
					display: inline-block;
				}
				&_mobile {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.add-new {

		&__title {
			font-size: 24px;
			margin-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}
		&__block-title {
			font-size: 14px;
			margin-right: 19px;
		}
		&__place-photo {
			width: 284px;
			height: 284px;
		}

		&__cards-block-title-wrap {
			margin-bottom: 10px;
		}

		&__cards-block-optional {
			margin-top: 0;
		}
		&__cards-photo-block {
			width: 100%;
		}
		&__cards-photo {
			width: 100%;
			//height: 240px;
		}
		&__textarea {
			height: 60px;
			line-height: 1.2 !important;
		}
	}
}

@media screen and (max-width: 415px) {
	.add-new {

		&__block-title-wrap {
			padding-left: 18px;
			padding-right: 18px;
			margin-bottom: 21px;
		}
		&__block-title {
			margin-right: 15px;
		}
		&__block-link {
			padding-left: 18px;
			padding-right: 18px;
		}

		&__place-preview {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__place-info {
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
		}
		&__cards-info {
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
		}
		&__cards-block-top-line {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__cards-photo-text {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__cards-photo-copyright {
			padding-left: 18px;
			padding-right: 18px;
		}

		&__add-another-card {
			padding-left: 18px;
		}

		&__publish-right-part {
			.add-new_edit & {
				text-align: center;
				padding-left: 18px;
				padding-right: 18px;
			}
		}
		&__publish-button {
			.add-new_edit & {
				display: block;
				&:first-child {
					display: none;
				}
				&_mobile {
					display: block;
				}
			}
		}
		&__publish-button-cancel {
			padding: 0;
			width: 100%;
		}
		&__publish-button-save {
			padding: 0;
			width: 100%;
		}
	}
}