.route-popup {
	padding: 30px 40px 40px 40px;
	background: white;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	&_mobile-page {
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 18px;
		padding-right: 18px;
		box-shadow: none;
	}

	&__return-link {
		margin-bottom: 20px;
		width: 91px;
		.box-sizing(border-box);
		display: flex;
		flex-direction: row;
		align-items: center;

		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
	}
	&__return-link-icon {
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		color: black;
		.museo();
		font-size: 14px;
	}

	&__title {
		color: #000000;
		font-size: 30px;
		.museobold();
		margin-bottom: 20px;

		.route-popup_mobile-page & {
			font-size: 24px;
		}
	}

	&__serv-text-wrap {
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.route-popup_mobile-page & {

			&_wrap {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
	&__serv-text {
		color: #000000;
		font-size: 14px;
		.museobold();
		margin-right: 10px;
		.route-popup__serv-text-wrap_wrap & {
			.route-popup_mobile-page & {
				margin-right: 0;
				margin-bottom: 2px;
			}
		}
	}
	&__serv-type-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
	}

	&__line {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
		.route-popup_mobile-page & {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__name-holder {
		width: 620px;
		margin-bottom: 20px;
		position: relative;
		&_count {
			&:after {
				content: attr(data-symbols);
				position: absolute;
				right: 0;
				top: -20px;
				color: rgba(0, 0, 0, 0.4);
				.museo();
				font-size: 12px;
			}
		}
		.route-popup_mobile-page & {
			width: 100%;
		}
	}
	&__transport-holder {
		margin-bottom: 20px;
		width: 488px;
		position: relative;
		&_count {
			&:after {
				content: attr(data-symbols);
				position: absolute;
				right: 0;
				top: -20px;
				color: rgba(0, 0, 0, 0.4);
				.museo();
				font-size: 12px;
			}
		}
		.route-popup_mobile-page & {
			width: 100%;
		}
	}
	&__distance-holder {
		margin-right: 20px;
		width: 106px;
		position: relative;

		&:after {
			content: 'km';
			position: absolute;
			color: rgba(0, 0, 0, 0.4);
			font-size: 14px;
			.regular();
			right: 12px;
			top: 13px;
			z-index: 20;
		}
		.route-popup_mobile-page & {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	&__date-holder {
		width: 230px;
		position: relative;
		&:first-child {
			margin-right: 30px;
			&:after {
				content: '-';
				position: absolute;
				right: -18px;
				top: 13px;
				font-size: 14px;
				.museobold();
				color: rgba(0, 0, 0, 0.4);
			}
		}
		&:before {
			content: '';
			position: absolute;
			left: 14px;
			top: 50%;
			margin-top: -6px;
			.svg-icon-param('icon-calendar', 12px, 12px, '[fill]: #c6c6c6');
		}
		.route-popup_mobile-page & {
			width: 100%;
			&:first-child {
				margin-right: 0;
				margin-bottom: 10px;
				&:after {
					display: none;
				}
			}
		}
	}
	&__time-holder {
		position: absolute;
		right: 1px;
		top: 1px;
		height: 38px;
		width: 56px;
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			left: 0;
			height: 22px;
			top: 50%;
			margin-top: -11px;
			background: #cccccc;
		}
	}
	&__time-input {
		width: 100%;
		height: 38px;
		border: 0;
		padding: 0;
		text-align: center;
		//.box-sizing(border-box);
		//padding-right: 5px;
	}
	&__date-remove {
		font-size: 14px;
		.museobold();
		color: @green-color;
		.transition(all .3s ease);
		text-decoration: none;
		margin-left: 17px;
		&:hover {
			color: @green-color-hover;
		}
		.route-popup_mobile-page & {
			margin-top: 10px;
			margin-left: 0;
		}
	}

	&__transport-choose-wrap {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 57px;
		box-shadow: 1px 0 0 #cccccc;
	}
	&__add-locations-link {
		font-size: 14px;
		.museobold();
		color: @green-color;
		.transition(all .3s ease);
		text-decoration: none;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}

	&__input {
		width: 100%;
		border-radius: 2px;
		height: 40px;
		line-height: normal;
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.2);
		.box-sizing(border-box);
		padding: 9px 37px 11px 14px;

		&:focus {
			border-color: @yellow-color;
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&.wpcf7-not-valid {
			border-color: #d21e1d;
		}
		.route-popup__date-holder & {
			padding: 9px 56px 11px 40px;
		}
		.route-popup__transport-holder & {
			padding: 9px 10px 11px 72px;
		}
	}
	&__textarea-holder {
		width: 620px;
		margin-bottom: 19px;
		position: relative;

		&_count {
			&:after {
				content: attr(data-symbols);
				position: absolute;
				right: 0;
				top: -20px;
				color: rgba(0, 0, 0, 0.4);
				.museo();
				font-size: 12px;
			}
		}
		.route-popup_mobile-page & {
			width: 100%;
		}
	}
	&__textarea {
		width: 100%;
		height: 142px;
		border-radius: 2px;
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.2);

		font-size: 14px;
		.regular();
		line-height: 1.43;
		color: #000000;
		padding: 14px 19px 14px 16px;
		.box-sizing(border-box);

		&:focus {
			border-color: @yellow-color;
		}

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&.wpcf7-not-valid {
			border-color: #d21e1d;
		}
	}

	&__button-block {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		&_space-between {
			justify-content: space-between;
		}
		.route-popup_mobile-page & {
			flex-direction: column-reverse;
		}
	}
	&__button-wrap {
		display: flex;
		flex-direction: row;

		.route-popup_mobile-page & {
			flex-direction: column-reverse;
			margin-bottom: 24px;
			width: 100%;
		}
	}
	&__delete-collection {
		font-size: 14px;
		.museobold();
		color: @green-color;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__button {

		a {
			font-size: 14px !important;
		}
		&:first-child {
			margin-right: 10px;
		}
		.route-popup_mobile-page & {
			width: 100%;
			&:first-child {
				margin-right: 0;
			}
			&:nth-child(2) {
				margin-bottom: 20px;
			}
		}
	}

	&__select-icon {
		display: block;
		width: 20px;
		height: 20px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_car {
			&:after {
				.svg-icon(icon-transport-car, 14px, 12px);
				margin-top: -6px;
				margin-left: -7px;
			}
		}
		&_bike {
			&:after {
				.svg-icon(icon-transport-bike, 22px, 17px);
				margin-top: -8px;
				margin-left: -11px;
			}
		}
		&_bus {
			&:after {
				.svg-icon(icon-transport-bus, 12px, 15px);
				margin-top: -7px;
				margin-left: -6px;
			}
		}
		&_by-foot {
			&:after {
				.svg-icon(icon-transport-by-foot, 13px, 19px);
				margin-top: -9px;
				margin-left: -7px;
			}
		}
		&_by-see {
			&:after {
				.svg-icon(icon-transport-by-see, 16px, 16px);
				margin-top: -8px;
				margin-left: -8px;
			}
		}
		&_plane {
			&:after {
				.svg-icon(icon-transport-plane, 20px, 8px);
				margin-top: -4px;
				margin-left: -10px;
			}
		}
		&_train {
			&:after {
				.svg-icon(icon-transport-train, 12px, 17px);
				margin-top: -9px;
				margin-left: -6px;
			}
		}
	}
}