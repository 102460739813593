.place-nav {
	height: 50px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
	//padding-left: 20px;
	//padding-right: 20px;


	&__container {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		//.place-nav_profile & {
		//	max-width: 690px;
		//}

	}
	&__list {
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;


		/*.swiper-slide {
			width: 150px;
			//&:nth-child(1) {
			//	//width: 20% !important;
			//	width: 103px !important;
			//}
			//&:nth-child(2) {
			//	//width: 22% !important;
			//	width: 113px !important;
			//}
			//&:nth-child(3) {
			//	//width: 29% !important;
			//	width: 149px !important;
			//}
			//&:nth-child(4) {
			//	//width: 29% !important;
			//	width: 154px !important;
			//}
			//&:nth-child(5) {
			//	//width: 29% !important;
			//	width: 154px !important;
			//}
		}*/
	}

	&__item {
		height: 100%;
		line-height: 50px;
		border: 0;
		background: white;
		border-left: 1px solid #f2f2f2;
		outline: none;
		color: black;
		.museobold();
		font-size: 14px;
		margin-right: -4px;
		cursor: pointer;
		.transition(all .2s ease);
		white-space: nowrap;
		padding: 0 20px;

		display: flex;
		justify-content: center;
		align-items: center;

		&:last-child {
			border-right: 1px solid #f2f2f2;
			margin-right: 0;
		}
		&_active {
			background: @violet-color;
			color: white;
		}

	}
	&__item-text {
		color: black;
		.museobold();
		font-size: 14px;
		.place-nav__item_active & {
			color: white;
		}
	}
}


@media screen and (max-width: 750px) {
	.place-nav {

		&_my-profile {
			display: none;
		}

		&__list {
			justify-content: flex-start;
			overflow-x: scroll;
		}
	}
}

@media screen and (max-width: 480px) {
	.place-nav {
		//&__container {
		//	max-width: none;
		//	margin-left: auto;
		//	margin-right: auto;
		//	text-align: center;
		//	width: 100%;
		//}
	}
}

