// TABLE OF CONTENTS

// Colors

@orange-color: #f25f22;
@yellow-color: #fdca15;
@text-color: #22201e;

@green-color: #56a789;
@green-color-hover: #509b7f;

@violet-color: #a6648e;

@border-color: #e7e7e7;


.svg-icon(@icon, @width, @height) {
	background: svg('@{icon}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}

.svg-icon-param(@icon, @width, @height, @param) {
	background: svg('@{icon}', '@{param}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}


.js-text-to-show {
	height: 65px;
	overflow: hidden;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 60px;
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

	}
	&_open {
		height: auto;
		&:after {
			display: none;
		}
	}
}

.js-text-link {
	font-size: 14px;
	.regular();
	line-height: 1.43;
	color: @green-color;
	text-decoration: none;
	.transition(all .3s ease);
	&:hover {
		color: @green-color-hover;
	}
}