.user-stats {
    display: flex;
    margin-right: 20px;

    @media (max-width: 500px) {
        display: none;
    }

    .user-stats-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 90px;
        border-right: 1px solid #EBEBEB;
        background: #FFFFFF;
        cursor: pointer;
        position: relative;
        margin: 13px 0;

        .user-stats-dropdown {
            display: block;
            background: #FFFFFF;
            box-shadow: 0 4px 20px 0 rgba(0,0,0,0.06);
            padding: 16px;
            position: absolute;
            top: 100%;
            right: -1px;
            min-width: 154px;
            opacity: 0;
            pointer-events: none;
            cursor: initial;
            margin-top: 13px;

            &.visible {
                opacity: 1;
                pointer-events: initial;
            }

            .user-stats-placeholder {
                .regular();
                font-size: 12px;
                color: rgba(0, 0, 0, 0.4);
                letter-spacing: 0;
                text-align: center;
                line-height: 16px;
                margin-top: 14px;
                margin-bottom: 24px;
            }

            .user-stats-link {
                display: block;
                .museobold();
                font-size: 12px;
                color: #FFFFFF;
                letter-spacing: 1px;
                text-align: center;
                background: #56A789;
                box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.11);
                border-radius: 2px;
                padding: 15px 20px;
                text-transform: uppercase;
                white-space: nowrap;
                margin-top: 16px;
            }

            .user-stats-dropdown-divider {
                .museo();
                font-size: 12px;
                color: rgba(0,0,0,0.40);
                letter-spacing: 0;
                line-height: 18px;
                display: flex;
                padding-bottom: 8px;
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #EBEBEB;
            }

            .user-stats-locations {
                display: flex;
                flex-direction: column;

                .user-stats-location {
                    display: block;
                    .medium();
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    letter-spacing: 0;
                    line-height: 20px;
                    margin-bottom: 8px;
                    transition: color .3s ease;

                    &:hover {
                        color: #509b7f;
                    }
                }
            }

            .user-stats-see-all {
                .regular();
                font-size: 14px;
                color: #56A789;
                letter-spacing: 0;
                line-height: 18px;
                display: block;
                text-decoration: underline;
            }

            .user-stats-places {
                .user-stats-place {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    &:hover {
                        .user-stats-place-info {
                            .user-stats-place-name {
                                color: #509b7f;
                            }
                        }
                    }

                    .user-stats-place-image {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        flex-shrink: 0;
                        margin-right: 10px;
                        object-fit: cover;
                    }

                    .user-stats-place-info {
                        .user-stats-place-country {
                            .regular();
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.4);
                            letter-spacing: 0;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                            max-width: 200px;
                            padding-bottom: 2px;
                        }
    
                        .user-stats-place-name {
                            .medium();
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.9);
                            letter-spacing: 0;
                            line-height: 20px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                            max-width: 200px;
                            padding-bottom: 2px;
                            transition: color .3s ease;
                        }
                    }
                }
            }

            .user-stats-routes {
                .user-stats-route {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    &:hover {
                        .user-stats-route-info {
                            .user-stats-route-name {
                                color: #509b7f;
                            }
                        }
                    }

                    .user-stats-route-image {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        flex-shrink: 0;
                        margin-right: 10px;
                        object-fit: cover;
                    }

                    .user-stats-route-info {
                        .user-stats-route-dates {
                            .regular();
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.4);
                            letter-spacing: 0;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                            max-width: 200px;
                            padding-bottom: 2px;
                        }
    
                        .user-stats-route-name {
                            .medium();
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.9);
                            letter-spacing: 0;
                            line-height: 20px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                            max-width: 200px;
                            padding-bottom: 2px;
                            transition: color .3s ease;
                        }
                    }
                }
            }
        }

        .icon-countries {
            .svg-icon(icon-countries, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
        }

        .icon-cities {
            .svg-icon(icon-cities, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
        }

        .icon-places {
            .svg-icon(icon-places, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
        }

        .icon-routes {
            .svg-icon(icon-routes, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
        }

        & > * {
            flex-shrink: 0;
        }

        .user-stats-counter {
            .medium();
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            align-items: center;
        }

        .user-stats-name {
            margin-top: 4px;
            .regular();
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
            letter-spacing: 0;
        }

        &.active {
            .user-stats-counter {
                color: #000000;
            }

            & .icon-countries {
                .svg-icon(icon-countries-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-cities {
                .svg-icon(icon-cities-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-places {
                .svg-icon(icon-places-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-routes {
                .svg-icon(icon-routes-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }
        }
    }
}

.menu-divider {
    .regular();
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0;
    padding: 13px 20px;
    border-top: 1px solid #F2F2F2;

    &:first-child {
        border-top: none;
    }
}

.menu-stats-block {
    display: flex;

    a {
        text-decoration: none;
        width: 100%;
    }

    .menu-stats-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 16px;
        border-top: 1px solid #F2F2F2;

        p {
            .medium();
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            align-items: center;
        }

        .user-stats-counter {
            .medium();
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            letter-spacing: 0;
            line-height: 20px;
        }

        .icon-countries {
            .svg-icon(icon-countries, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
            margin-right: 10px;
        }

        .icon-cities {
            .svg-icon(icon-cities, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
            margin-right: 10px;
        }

        .icon-places {
            .svg-icon(icon-places, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
            margin-right: 10px;
        }

        .icon-routes {
            .svg-icon(icon-routes, 24px, 24px);
            display: block;
            background-size: auto;
            background-position: center;
            margin-right: 10px;
        }

        &.active {
            .user-stats-counter {
                color: #56A789;
            }

            & .icon-countries {
                .svg-icon(icon-countries-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-cities {
                .svg-icon(icon-cities-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-places {
                .svg-icon(icon-places-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }

            & .icon-routes {
                .svg-icon(icon-routes-active, 24px, 24px);
                display: block;
                background-size: auto;
                background-position: center;
            }
        }
    }
}