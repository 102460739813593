.recent-comments {
	margin-top: 30px;
	display: inline-block;
	vertical-align: top;
	margin-right: -4px;
	width: 301px;
	.box-sizing(border-box);

	&__title {
		color: black;
		.museobold();
		font-size: 14px;
		padding-bottom: 14px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 14px;
		letter-spacing: 1px;
	}
	&__list {

	}
	&__item {
		padding-bottom: 14px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 14px;
	}
	&__person-block {
		margin-bottom: 14px;
	}
	&__person-photo {
		width: 24px;
		height: 24px;
		border: 2px solid white;
		.box-sizing(border-box);
		display: inline-block;
		vertical-align: middle;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 10px;
	}
	&__person-photo-img {
		max-width: 100%;
	}
	&__person-name {
		display: inline-block;
		vertical-align: middle;
		color: @green-color;
		.museo();
		font-size: 14px;
	}
	&__comment-text {
		color: black;
		.regular();
		font-size: 14px;
		line-height: 1.25;
		margin-bottom: 10px;
	}
	&__post-time {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
	}
}

@media screen and (max-width: 815px) {
	.recent-comments {
		display: none;
	}
}