.bottom-line {

	&_with-top-line {
		border-top: 1px solid rgba(0, 0, 0, 0.08);
		padding-top: 14px;
		margin-bottom: 16px;
	}
	&_with-padding {
		padding-left: 18px;
		padding-right: 18px;
	}

	&__quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
	}
	&__pagination {
		float: right;
	}
	&__pagination-item {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		color: black;
		.museobold();
		font-size: 14px;
		margin-right: 18px;
		&:last-child {
			margin-right: 0;
		}
		&_active {
			color: rgba(0, 0, 0, 0.2);
		}
		&:hover {
			color: rgba(0, 0, 0, 0.2);
		}
	}
}

@media screen and (max-width: 500px) {
	.bottom-line {
		&_hide-on-mobile {
			display: none;
		}
		&_with-top-line {
			border-top: 0;
		}

		&__quantity {
			float: none;
			text-align: center;
		}
		&__pagination {
			display: none;
		}
		&__quantity-span {
			display: none;
		}
	}
}