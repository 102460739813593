.cards-list {
	margin-bottom: 20px;
	margin-top: 17px;

	&__title {
		.museobold();
		font-size: 24px;
		margin-top: 20px;
		text-align: center;
		margin-bottom: 20px;
		display: none;
	}
	&__top-line {
		display: block;
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__cards-quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
	&__sort-block {
		float: right;
		min-width: 165px;
	}
	&__sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__sort-block-select {
		width: 100%;
	}

	&__list {
		margin-bottom: 11px;
	}
	&__list-item {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		margin-right: -4px;
		padding-right: 4px;
		.box-sizing(border-box);
		margin-bottom: 1px;
	}

	&__button {
		text-align: center;
		margin-bottom: 20px;
		display: none;
	}
}

@media screen and (max-width: 1380px) {
	.cards-list {

		&__list-item {
			width: 33.3333%;
		}
	}
}

@media screen and (max-width: 1140px) {
	.cards-list {

		&__list-item {
			width: 50%;
		}
	}
}

@media screen and (max-width: 850px) {
	.cards-list {

		&__list-item {
			width: 100%;
		}
	}
}

@media screen and (max-width: 815px) {
	.cards-list {

		&__title {
			display: block;
		}
		&__list-item {
			width: 50%;
		}
	}
}

@media screen and (max-width: 550px) {
	.cards-list {

		&__list-item {
			width: 100%;
			margin-right: 0;
		}
	}
}

@media screen and (max-width: 500px) {
	.cards-list {

		&__list {
			margin-bottom: 20px;
			padding-left: 18px;
			padding-right: 18px;
		}
		&__button {
			display: block;
		}
	}
}