// RESET CSS
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {margin: 0;padding: 0;	border: 0; font-size: 16px;	font: inherit;}

input, textarea {outline: none;}
textarea {resize: none; overflow: hidden;}

 // HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {display: block;}
body {line-height: 1;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: ''; content: none;}
table {border-collapse: collapse; border-spacing: 0;}

html {
	height: 100%;
}

body {
	min-width: 320px;
	color: #222;
	text-align: left;
	opacity: 0;
	height: 100%;
	&.visible {
		opacity: 1;
	}
}

.container {
	max-width: 1440px + 60px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-right: 30px;
	.box-sizing(border-box);

	&_with-no-padding-left {
		padding-left: 0;
	}
	&_with-no-padding {
		max-width: 1440px;
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 1030px) {
	.container {
		padding-left: 18px;
		padding-right: 18px;

		&_with-no-padding {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media screen and (max-width: 415px) {
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.container-min {
	max-width: 990px + 60px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-right: 30px;
	.box-sizing(border-box);
}

@media screen and (max-width: 415px) {
	.container-min {
		padding-left: 0;
		padding-right: 0;
	}
}

.container-middle {
	max-width: 1245px + 60px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-right: 30px;
	.box-sizing(border-box);
}




.clearfix:before, .clearfix:after {content: ""; display: table;}
.clearfix:after {clear: both;}
.clearfix {zoom: 1;}

.hidden-element {display: none;}