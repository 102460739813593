.whats-card {
	max-width: 415px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 18px;
	padding-right: 18px;
	margin-bottom: 25px;

	&__title {
		.museobold();
		font-size: 24px;
		margin-bottom: 22px;
		margin-top: 7px;
	}
	&__photo-block {
		width: 180px;
		height: 180px;
		border-radius: 2px;
		margin-bottom: 25px;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	&__text-block {

	}
	&__text-block-title {
		color: black;
		font-size: 14px;
		.museo();
		margin-bottom: 17px;
	}
	&__text-block-text {
		.regular();
		line-height: 1.4;
		font-size: 14px;
		color: black;
	}
}