.form {
	&__input-holder {
		margin-bottom: 20px;
		text-align: left;
		width: 100%;
		position: relative;
		.form__input-holder & {
			display: inline-block;
			vertical-align: top;
			width: calc(~"(100% - 20px - 105px) / 2");
			margin-right: 20px - 4px;
			margin-bottom: 0;
			&:last-child {
				margin-right: -4px;
			}
			.add-new-place & {
				width: calc(~"(100% - 20px) / 2");
			}
			.add-new & {
				width: calc(~"(100% - 20px) / 2");
			}
		}
		&_count {
			&:after {
				content: attr(data-symbols);
				position: absolute;
				right: 0;
				top: -20px;
				color: rgba(0, 0, 0, 0.4);
				.museo();
				font-size: 12px;
			}
		}
		.add-new__place-block-wrap & {
			width: calc(~"100% - 105px");
			white-space: nowrap !important;
		}
	}
	&__input-holder-label {
		color: black;
		.museobold();
		font-size: 14px;
		margin-bottom: 10px;
		&_float {
			margin-bottom: 0;
			float: left;
		}
	}
	&__input {
		display: block;
		width: 100% !important;
		.box-sizing(border-box);
		height: 40px;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		padding: 0 10px;
		line-height: normal !important;
		color: black;
		.regular();
		font-size: 14px;

		-webkit-appearance: none;
		word-wrap: break-word !important;
		white-space: normal!important;

		&:focus {
			border-color: @yellow-color;
		}
		&:disabled {
			background-color: #f2f2f2;
			color: rgba(0, 0, 0, 0.4);
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}

		.form__input-holder_alert & {
			border: 1px solid #e7455d;
		}

	}

	&__alert-text {
		//display: block;
		color: #e7455d;
		font-size: 12px;
		.regular();
		margin-bottom: 10px;
		margin-top: 10px;
		margin-left: 2px;
		display: none;
		.form__input-holder_alert & {
			display: block;
		}
	}
	&__textarea {
		display: block;
		width: 100%;
		.box-sizing(border-box);
		height: 128px;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		padding: 11px 26px 11px 14px !important;
		color: #101010;
		.regular();
		font-size: 14px;
		-webkit-appearance: none;
		line-height: 1.43;
		resize: vertical;
		&:focus {
			border-color: @yellow-color;
		}

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
			font-style: italic;
		}
		&.wpcf7-not-valid {
			border-color: #d21e1d;
		}

		&_small {
			height: 40px;
			padding: 10px 11px 10px 13px;
		}
	}
	&__submit {
		border: 0;
		height: 40px;
		background: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 107px;
		line-height: 40px;

		color: white;
		.museobold();
		letter-spacing: 1px;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;
		&_block {
			width: 100%;
			.box-sizing(border-box);
		}
		&:hover {
			background: @green-color-hover;
		}
		.add-new_edit & {
			padding: 0 81px;
		}
	}

	select {
		width: 100%;
	}

	.chosen-container {
		width: 100% !important;
	}

	&__select {
		width: 100%;
		border-radius: 0;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		padding: 0 24px;
	}
	&__submit-holder {

	}
}

@media screen and (max-width: 1000px) {
	.form {
		&__input-holder {
			.add-new & {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.form {
		&__input-holder {
			.form__input-holder & {
				.add-new & {
					white-space: nowrap;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.form {
		&__input-holder {
			.form__input-holder & {
				width: 100%;
				margin-right: 0;
				margin-bottom: 10px;
				&:last-child {
					margin-right: 0;
				}
				.add-new & {
					margin-right: 10px - 4px;
					width: calc(~"(100% - 10px) / 2");
					&:last-child {
						margin-right: -4px;
					}
				}
			}
		}
		&__input-holder-label {
			padding-right: 30px;
			line-height: 1.43;
		}
	}
}

@media screen and (max-width: 360px) {
	.form {
		&__input {
			.add-new & {
				padding: 0 5px;
				font-size: 12px;
			}
		}
	}
}