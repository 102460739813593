::-ms-reveal,
::-ms-clear {
  display: none !important;
}

.hideShowPassword-wrapper {
	width: 100% !important;
}

.hideShowPassword-toggle {
	right: 8px !important;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0;
  max-height: 100%;
  padding: 0;
  overflow: 'hidden';
  text-indent: -999em;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  background-color: transparent;
	border: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    //.svg-icon(icon-wink-open, 18px, 11px);
	  .sprite(@icon-wink-open);
	  margin-top: -@icon-wink-open-height / 2;
	  margin-left: -@icon-wink-open-width / 2;
    z-index: 30;
  }
}

.hideShowPassword-toggle-hide {
  //background-position: -44px center;
  //.svg-icon(icon-wink-closed, 19px, 17px);

	&:after {
		.sprite(@icon-wink-closed);
		margin-top: -@icon-wink-closed-height / 2;
		margin-left: -@icon-wink-closed-width / 2;
	}
}
