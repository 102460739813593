.info-aside {
	//float: right;
	display: inline-block;
	vertical-align: top;
	margin-right: -4px;
	width: 301px;
	.box-sizing(border-box);
	//border-left: 1px solid @border-color;
	height: 100%;
	&_mobile {
		display: none;
		width: auto;
		margin-right: 0;
	}
	&_fixed {
		position: fixed;
		height: calc(~"100% - 62px");
		width: 305px;
	}
	&_resize {
		position: fixed;
		height: calc(~"100vh - 62px");
		//right: calc(~"50% - 1440px / 2");
		left: 50%;
		width: calc(~"50% + 10px");
		max-width: 720px;
	}
	&_scroll-to-footer {
		height: calc(~"100vh - 62px - 48px");
	}

	&__map-wrapper {
		margin-bottom: 30px;
		height: 302px;
		width: 100%;
		position: relative;
		&_long {
			height: 615px;
			.info-aside_fixed & {
				height: calc(~"100vh - 62px");
			}
			.info-aside_resize & {
				height: calc(~"100vh - 62px");
			}
			.info-aside_scroll-to-footer & {
				height: calc(~"100vh - 62px - 48px");
			}
		}
	}
	&__map-resize {
		position: absolute;
		bottom: 20px;
		left: 10px;
		z-index: 1000;
		width: 40px;
		height: 40px;
		background: white;
		.transition(all .3s ease);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.svg-icon(icon-open-map, 18px, 18px);
			margin-top: -9px;
			margin-left: -9px;
		}
		.info-aside_resize & {
			&:after {
				.svg-icon(icon-close-map, 18px, 18px);
			}
		}
		&:hover {
			background: #fbfbfb;
		}
	}
	&__map {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&__map-itself {
		height: 100%;
		width: 100%;
	}
	&__place-block {
		padding-left: 15px;
		padding-right: 15px;
	}
	&__photo {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		margin-bottom: 16px;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;

		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&__photo-img {
		max-width: 100%;
	}
	&__text-wrapper {
		text-align: center;
	}
	&__country {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		margin-bottom: 20px;
		.museo();
	}
	&__place-name-wrap {
		margin-bottom: 14px;
	}
	&__place-name {
		color: black;
		font-size: 20px;
		.museobold();
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__people-count {
		margin-bottom: 20px;
	}
	&__been-here-block {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin-right: 23px;
		text-decoration: none;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 9px;
		}
	}
	&__been-here-count {
		color: black;
		.museo();
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		.transition(all .3s ease);
		.info-aside__been-here-block:hover & {
			color: @green-color-hover;
		}
	}
	&__been-here-text {
		color: black;
		.museo();
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		.transition(all .3s ease);
		.info-aside__been-here-block:hover & {
			color: @green-color-hover;
		}
	}
	&__want-visit-block {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
	}
	&__want-visit-count {
		color: black;
		.museo();
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		.transition(all .3s ease);
		.info-aside__want-visit-block:hover & {
			color: @green-color-hover;
		}
	}
	&__want-visit-text {
		color: black;
		.museo();
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		.transition(all .3s ease);
		.info-aside__want-visit-block:hover & {
			color: @green-color-hover;
		}
	}
	&__button-block {
		margin-bottom: 26px;
	}
	&__button {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}
	}
	&__visitors {

	}
	&__visitors-photo {
		float: left;
		width: 50px;
	}
	&__visitors-photo-item {
		width: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		border: 2px solid white;
		border-radius: 50%;
		&:nth-child(2) {
			margin-left: -12px;
		}
	}
	&__visitors-photo-img {
		max-width: 100%;
	}
	&__visitors-text-wrapper {
		margin-left: 50px + 10px;
		text-align: left;
	}
	&__visitors-name {
		display: inline;
		.museo();
		font-size: 12px;
		color: @green-color;
		text-decoration: none;
	}
	&__visitors-text {
		display: inline;
		.museo();
		font-size: 12px;
		color: black;
		text-decoration: none;
		cursor: pointer;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}
}

@media screen and (max-width: 815px) {
	.info-aside {
		display: none;
		&_mobile {
			display: block;
			margin-bottom: 20px;
		}

		&__visitors {
			display: inline-block;
		}
	}
}

@media screen and (max-width: 415px) {
	.info-aside {
		&__map-wrapper {
			height: 100px;
			margin-bottom: 20px;
		}
	}
}