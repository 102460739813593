.discussion-inner {
	margin-top: 40px;
	margin-bottom: 10px;

	&__wrap {
		//width: calc(~"100% - 300px");
		padding-left: 82px;
		.box-sizing(border-box);
		max-width: 1050px + 85px;
		position: relative;
	}
	&__return-link {
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
		display: inline-block;
		margin-bottom: 30px;

		&:hover {
			background: #fbfbfb;
		}
	}
	&__return-link-icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-slider-arrow);
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
	}

	&__content {
		margin-left: 0;
		&_answers {
			max-width: 710px;
		}
	}

	&__all-disc-link {
		display: none;
		text-decoration: none;
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		line-height: 30px;
		margin-bottom: 32px;
		&:hover {
			background: #fbfbfb;
		}
	}
	&__all-disc-icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-slider-arrow);
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__all-disc-text {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
	}

	&__question-wrap {
		padding-bottom: 30px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 30px;
	}
	&__question {
		font-size: 20px;
		.museobold();
		line-height: 1.5;
		margin-bottom: 20px;
		color: black;
	}
	&__question-desc {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 10px;
	}
	&__photo-block {
		margin-bottom: 20px;
		position: relative;
	}
	&__photo-item {
		//display: inline-block;
		//vertical-align: top;
		//margin-right: 20px - 4px;
		display: block;
		//width: 190px;
		height: 150px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 1.4px;
		position: relative;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		&:last-child {
			margin-right: -4px;
		}
		&:nth-child(5n) {
			margin-right: -4px;
		}
	}
	&__photo-item-view {
		position: absolute;
		.svg-icon(icon-view, 18px, 11px);
		top: 50%;
		left: 50%;
		margin-top: -5px;
		margin-left: -9px;
		visibility: hidden;
		opacity: 0;
		z-index: 10;
		.transition(all .3s ease);
		.discussion-inner__photo-item:hover & {
			opacity: 1;
			visibility: visible;
		}
	}
	&__video-block {
		margin-bottom: 20px;
	}
	&__video {
		display: block;
		width: 241px;
		height: 136px;
		position: relative;
		background: #000;
	}
	&__video-link {
		text-decoration: none;
		.svg-icon(icon-video-link, 30px, 30px);
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -15px;
		margin-top: -15px;
		.discussion-inner__video:hover & {
			.svg-icon(icon-video-link-hover, 30px, 30px);
		}
	}

	&__question-info {

	}
	&__question-answers-count {
		display: inline-block;
		vertical-align: middle;
		color: @green-color;
		.regular();
		font-size: 14px;
		text-decoration: none;
		margin-right: 14px;
	}
	&__question-theme {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		margin-bottom: 10px;
	}
	&__question-last-modification {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
	}

	&__answers-wrap {
		border-bottom: 1px solid @border-color;
		margin-bottom: 30px;
	}
	&__answers-title {
		color: black;
		.museo();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}
	&__answers-item {
		display: block;
		margin-bottom: 20px;
	}
	&__person-photo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px - 4px;
		.discussion-inner__write-an-answer-block & {
			vertical-align: top;
		}
	}
	&__person-photo-img {
		max-width: 100%;
	}
	&__person-name-wrap {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 24px - 10px");
		margin-right: -4px;
		.discussion-inner__write-an-answer-block & {
			vertical-align: top;
		}
	}
	&__write-an-answer-block {
		margin-bottom: 20px;
	}
	&__write-an-answer-wrap {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 24px - 10px");
		margin-right: -4px;
		position: relative;
	}
	&__write-an-answer {
		height: 40px;
		background-color: white;
		border: 1px solid #e2e2e2;
		border-radius: 2px;
		width: 100%;

		color: black;
		.regular();
		font-size: 14px;
		line-height: normal;
		.box-sizing(border-box);
		padding: 9px 75px 9px 12px;
		&:focus {
			border-color: @green-color;
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
	}
	&__write-an-answer-submit {
		font-size: 14px;
		.museo();
		position: absolute;
		right: 10px;
		bottom: 10px;
		border: 0;
		background: transparent;
		padding: 0;
		color: @green-color;
		transition: all .2s ease;
		cursor: pointer;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__answers-item-top-line {
		margin-bottom: 6px;
	}
	&__person-name {
		color: #56a789;
		.museo();
		font-size: 14px;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
	}

	&__answers-item-text {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.5;
		margin-left: 34px;
		margin-bottom: 10px;
	}
	&__answers-item-serv-line {
		margin-left: 34px;
	}
	&__answers-item-time {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 14px;
	}
	&__answers-item-link {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 14px;
		text-decoration: none;
	}
	&__answers-item-answer-link {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		margin-right: 20px;
	}

	&__answer-for-answer {
		margin-left: 34px;
		margin-top: 20px;
	}

	&__answer-to {
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
		margin-left: 8px;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
	}
	&__answers-item-answer-edit {
		color: #56a789;
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		text-decoration: none;
	}
	&__answers-item-answer-delete {
		color: #bc76a3;
		.regular();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
	}
	&__answers-item-thanks {
		display: inline-block;
		vertical-align: 0;
		margin-right: 20px;
		text-decoration: none;
	}
	&__answers-item-thanks-icon {
		display: inline-block;
		vertical-align: -2px;
		.svg-icon(icon-say-thanks-grey, 15px, 16px);
		.discussion-inner__answers-item-thanks_active & {
			.svg-icon(icon-say-thanks-green, 15px, 16px);
		}
	}
	&__answers-item-thanks-count {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		.discussion-inner__answers-item-thanks_active & {
			color: @green-color;
		}
	}
	&__answers-item-thanks-text {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		text-decoration: none;
		.discussion-inner__answers-item-thanks_active & {
			color: @green-color;
		}
	}
}

@media screen and (max-width: 1030px) {
	.discussion-inner {

		&__wrap {
			padding-left: 0;
		}
	}
}

@media screen and (max-width: 1000px) {
	.discussion-inner {

		.tabs__item & {
			display: none;
		}

		&__wrap {
			width: 100%;
		}
		&__content {
			max-width: none;
			margin-left: auto;
		}

	}
}

@media screen and (max-width: 640px) {
	.discussion-inner {
		&__wrap {
			padding-left: 0;
		}
		&__answers-item-answer-delete {
			display: block;
			margin-top: 10px;
		}
	}
}

@media screen and (max-width: 540px) {
	.discussion-inner {
		&__answers-item-answer-edit {
			display: block;
			margin-top: 10px;
		}
		&__answers-item-time {
			margin-right: 5px;
		}
		&__answers-item-link {
			margin-right: 5px;
		}
		&__answers-item-answer-link {
			margin-right: 0;
		}
	}
}

@media screen and (max-width: 415px) {
	.discussion-inner {
		padding-left: 18px;
		padding-right: 18px;

		&__all-disc-link {
			display: inline-block;
		}
	}
}

@media screen and (max-width: 350px) {
	.discussion-inner {
		&__person-photo {
			vertical-align: top;
		}
		&__person-name-wrap {
			vertical-align: top;
			margin-top: 3px;
		}
		&__person-name {
			line-height: 1.4;
		}
	}
}