.discussion {

	&__ask-a-question-wrap {
		margin-top: 30px;
		margin-bottom: 30px;
		text-align: center;
		display: none;
	}
	&__ask-a-question {
		display: inline-block;
		padding: 0 78px;
		.museo();
		font-size: 14px;
		text-transform: uppercase;
		height: 40px;
		line-height: 40px;
		text-decoration: none;
		color: white;
		background: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
	}

	&__right-part {
		//float: right;
		display: inline-block;
		vertical-align: top;
		width: 300px;
		margin-top: 32px;
		margin-right: -4px;
	}

	&__users {
		margin-bottom: 50px;
		&_mobile {
			display: none;
		}
	}
	&__users-title {
		display: block;
		color: black;
		font-size: 14px;
		letter-spacing: 1px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		padding-bottom: 20px;
		margin-bottom: 20px;
		.museobold();
	}
	&__users-list {

	}
	&__users-item {
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__users-item-number {
		display: inline-block;
		vertical-align: middle;
		margin-right: 18px;

		width: 36px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		border-radius: 50%;
		background-color: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		color: black;
		font-size: 14px;
		.museobold();
	}

	&__users-item-info {
		display: inline-block;
		vertical-align: middle;
	}
	&__users-item-photo {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin-right: 10px;
	}
	&__users-item-text {
		display: inline-block;
		vertical-align: middle;
	}
	&__users-item-name-wrap {
		margin-bottom: 5px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 170px;
		color: @green-color;
	}
	&__users-item-name {
		font-size: 14px;
		.museobold();
		color: @green-color;
		text-decoration: none;

		&:hover {
			color: @green-color-hover;
		}
	}
	&__users-item-thanks {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.regular();
	}

	&__themes {


	}
	&__themes-title {
		display: block;
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		padding-bottom: 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 32px;
	}
	&__themes-list {

	}
	&__themes-item {
		display: block;
		margin-bottom: 14px;
	}
	&__themes-item-link {
		display: inline-block;
		vertical-align: bottom;
		.museo();
		font-size: 14px;
		color: black;
		text-decoration: none;
		.tabs__discussion-themes-item:first-child & {
			color: rgba(0, 0, 0, 0.4);
		}
	}
	&__themes-item-count {
		display: inline-block;
		vertical-align: top;

		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 9px;
		margin-top: -4px;
	}

	&__main {
		display: inline-block;
		vertical-align: top;
		//margin-right: 300px + 60px;
		width: calc(~"100% - 300px - 60px");
		margin-right: 60px - 4px;
		padding-right: 20px;
		padding-left: 82px;
		margin-top: 50px;
		.box-sizing(border-box);
	}
	&__ask-question-block {
		max-width: 800px;
		margin-bottom: 45px;
	}

	&__attach-block {
		margin-bottom: 20px;
	}
	&__attach-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-attach-green, 10px, 13px);
		.discussion__attach-block_active & {
			.svg-icon(icon-attach-grey, 10px, 13px);
		}
	}
	&__attach-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		.medium();
		color: @green-color;
		text-decoration: none;
		.discussion__attach-block_active & {
			color: rgba(0, 0, 0, 0.4);
		}
		&:hover {
			  color: rgba(0, 0, 0, 0.4);
		 }
	}
	&__attach-desc {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
	}
	&__photo-block {
		margin-bottom: 30px;
	}
	&__photo-item {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px - 4px;
		width: calc(~"(100% - 20px * 4) / 5");
		overflow: hidden;
		&:last-child {
			margin-right: -4px;
		}
	}
	&__photo-item-img {
		border-radius: 1.4px;
		height: 100px;
		width: 100%;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		margin-bottom: 10px;
		position: relative;
		cursor: pointer;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__photo-item-img-delete {
		position: absolute;
		top: 50%;
		left: 50%;
		.svg-icon(icon-delete, 18px, 21px);
		margin-left: -9px;
		margin-top: -10px;
		opacity: 0;
		visibility: hidden;
		z-index: 10;
		.transition(all .3s ease);
		.discussion__photo-item-img:hover & {
			opacity: 1;
			visibility: visible;
		}
	}
	&__photo-item-name {
		color: black;
		font-size: 14px;
		.medium();
		text-align: center;
	}
	&__submit {
		height: 40px;
		width: 200px;
		border: 0;
		line-height: 40px;
		padding: 0;
		background: @green-color;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);

		color: white;
		font-size: 14px;
		letter-spacing: 1px;
		.museobold();
		cursor: pointer;
		text-transform: uppercase;
		text-align: center;
		text-decoration: none;
		&:hover {
			background: @green-color-hover;
		}
	}

	&__other-questions {

	}
	&__other-questions-list {
		max-width: 800px;
	}
	&__question {
		margin-bottom: 40px;
	}
	&__question-text-wrap {
		display: block;
		margin-bottom: 10px;
	}
	&__question-text {
		.museobold();
		font-size: 20px;
		line-height: 1.5;
		color: #161616;
		text-decoration: none;
	}
	&__question-info {

	}
	&__question-answers-count {
		display: inline-block;
		vertical-align: middle;
		color: @green-color;
		.regular();
		font-size: 14px;
		text-decoration: none;
		margin-right: 14px;
	}
	&__question-theme {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		margin-right: 14px;
	}
	&__question-last-modification {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
	}

	&__main-question-wrap {
		padding-bottom: 30px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 30px;
	}
	&__main-question {
		font-size: 20px;
		.museo();
		line-height: 1.5;
		margin-bottom: 20px;
		color: black;
	}
	&__main-question-desc {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 10px;
	}
	&__answers-wrap {

	}
	&__answers-title {
		color: black;
		.museo();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}
	&__answers-line {
		display: block;
		margin-bottom: 20px;
	}
	&__person-photo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px - 4px;
	}
	&__person-photo-img {
		max-width: 100%;
	}
	&__write-an-answer-wrap {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 24px - 10px");
		margin-right: -4px;
	}
	&__write-an-answer {
		height: 40px;
		background-color: white;
		border: 1px solid #e2e2e2;
		border-radius: 2px;
		width: 100%;

		color: rgba(0, 0, 0, 0.2);
		.regular();
		font-size: 14px;
		line-height: normal;
		.box-sizing(border-box);
		padding: 9px 12px 9px 12px;
		&:focus {
			border-color: @green-color;
		}
		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
	}

	&__show-more {
		text-align: center;
		display: none;
	}
}

@media screen and (max-width: 1200px) {
	.discussion {
		&__photo-item {
			width: calc(~"(100% - 20px * 3) / 4");
			margin-bottom: 18px;
			&:nth-child(4n) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 1000px) {
	.discussion {

		&__right-part {
			display: none;
		}

		&__main {
			width: 100%;
			margin-right: 0;
			padding-right: 0;
			margin-top: 50px;
			padding-left: 0;
		}
		&__users {
			margin-top: 50px;
			&_mobile {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 665px) {
	.discussion {
		&__photo-item {
			width: calc(~"(100% - 20px * 2) / 3");
			margin-bottom: 18px;
			&:nth-child(4n) {
				margin-right: 20px - 4px;
			}
			&:nth-child(3n) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.discussion {

		&__ask-question-block {

			.discussion__main & {
				display: none;
			}
		}

		&__ask-a-question-wrap {
			display: block;
		}

		&__show-more {
			display: block;
		}
	}
}

@media screen and (max-width: 415px) {
	.discussion {

		&__main {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
}