.error-page {
	padding-top: 102px;
	padding-bottom: 102px;

	&__container {

	}

	&__title {
		font-size: 50px;
		.museobold();
		color: black;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
	}

	&__text {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		.regular();
		font-size: 14px;
		line-height: 1.43;
		color: black;
		text-align: center;
		margin-bottom: 40px;
	}

	&__tags-list {
		width: 60%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		margin-bottom: 50px;
	}
	&__tags-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 9px;
		margin-bottom: 5px;

	}
	&__tags-item-hash {
		color: rgba(0,0,0,0.4);
		font-size: 14px;
		.regular();
	}
	&__tags-item-link {
		text-decoration: none;
		color: rgba(0,0,0,0.4);
		font-size: 14px;
		.regular();
		transition: all .3s ease;
		&:hover {
			text-decoration: underline;
		}
	}
}

@media screen and (max-width: 600px) {
	.error-page {
		padding-top: 70px;
		padding-bottom: 70px;

		&__title {
			font-size: 40px;
		}

		&__text {
			width: 90%;
		}

		&__tags-list {
			width: 80%;
		}
	}
}