.share-list {
	position: absolute;
	left: 0;
	top: 34px;
	z-index: 30;

	&__item {
		width: 126px;
		height: 34px;
		margin-bottom: 2px;
		text-decoration: none;
		cursor: pointer;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		.box-sizing(border-box);
		padding: 0 7px;
		.transition(all .3s ease);
		&:last-child {
			margin-bottom: 0;
		}
		&_1 {
			background: #446aa5;
			&:hover {
				background: rgba(68, 106, 165, 0.90);
			}
		}
		&_2 {
			background: #55acee;
			&:hover {
				background: rgba(85, 172, 238, 0.9);
			}
		}
		&_3 {
			background: #336297;
			&:hover {
				background: rgba(51, 98, 151, 0.9);
			}
		}
		&_4 {
			background: #dc4e41;
			&:hover {
				background: rgba(220, 78, 65, 0.9);
			}
		}
	}
	&__item-icon {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		position: relative;
		margin-right: 9px;
		margin-top: 7px;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		.share-list__item_1 & {
			&:after {
				.svg-icon(icon-share-fb, 7px, 14px);
				margin-top: -7px;
				margin-left: -3px;
			}
		}
		.share-list__item_2 & {
			&:after {
				.svg-icon(icon-share-tw, 14px, 12px);
				margin-top: -6px;
				margin-left: -7px;
			}
		}
		.share-list__item_3 & {
			&:after {
				.svg-icon(icon-share-vk, 14px, 8px);
				margin-top: -4px;
				margin-left: -7px;
			}
		}
		.share-list__item_4 & {
			&:after {
				.svg-icon(icon-share-g, 17px, 12px);
				margin-top: -6px;
				margin-left: -8px;
			}
		}
	}
	&__item-text {
		color: white;
		font-size: 14px;
		.museobold();
		margin-top: 10px;

		display: inline-block;
		vertical-align: middle;
	}
}