.footer {
	//border-top: 1px solid @border-color;
	box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
	//background-color: white;
	padding: 9px 20px;
	&_main {
		background: #fbfbfb;
		margin-top: -7px;
	}
	&_for-box-shadow {
		//margin-top: -4px;
		background: white;
		position: relative;
		z-index: 20;
	}

	&__wrapper {

	}

	&__left-part {
		float: left;
	}

	&__logo {
		width: 32px;
		height: 32px;
		display: inline-block;
		vertical-align: middle;
		background: url(../img/footer-logo.png) no-repeat center center / contain;
		margin-right: 14px;
	}
	&__text-block {
		display: inline-block;
		vertical-align: middle;
	}
	&__around-card {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		margin-right: 22px;
		.museo();
		&_mobile {
			display: none;
			margin-left: -2px;
		}
	}
	&__about-us {
		display: inline-block;
		vertical-align: middle;
		color: #56a789;
		font-size: 14px;
		text-decoration: none;
		.museo();
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}
	&__right-part {
		float: right;
	}
	&__icon {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		position: relative;
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		margin-right: 10px;
		.transition(all .3s ease);
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_insta {
			&:after {
				.svg-icon(icon-insta, 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
				z-index: 20;
			}
			&:hover {
				background: url(../img/insta-hover.jpg) no-repeat center center;
				background-size: cover;
				position: relative;
				z-index: 10;
			}
		}
		&_mail {
			&:after {
				.svg-icon(icon-mail, 16px, 16px);
				margin-top: -8px;
				margin-left: -8px;
			}
			&:hover {
				background-color: #56a789;
			}
		}
		&_fb {
			&:after {
				.svg-icon(icon-fb, 6px, 14px);
				margin-top: -7px;
				margin-left: -3px;
			}
			&:hover {
				background-color: #446aa5;
			}
		}
		&_vk {
			&:after {
				.svg-icon(icon-vk, 17px, 10px);
				margin-top: -5px;
				margin-left: -8px;
			}
			&:hover {
				background-color: #4c6c91;
			}
		}
		&_twitter {
			&:after {
				.svg-icon(icon-twitter, 16px, 14px);
				margin-top: -7px;
				margin-left: -8px;
			}
			&:hover {
				background-color: #55acee;
			}
		}
		&_telegram {
			&:after {
				.svg-icon(icon-telegram, 22px, 22px);
				margin-top: -11px;
				margin-left: -11px;
			}
			&:hover {
				background-color: #0088cc;
			}
		}
	}
}

@media screen and (max-width: 615px) {
	.footer {
		padding: 23px 0 26px 0;
		&__around-card {
			display: none;
			&_mobile {
				display: block;
			}
		}
		&__about-us {
			display: block;
			margin-bottom: 5px;
		}
		&__right-part {
			float: right;
		}
		&__icon {
			margin-right: 6px;
		}
	}
}

@media screen and (max-width: 450px) {
	.footer {
		padding: 23px 18px 26px 18px;

		&__left-part {
			float: none;
			margin-bottom: 20px;
			text-align: center;
		}
		&__about-us {
			text-align: left;
		}
		&__right-part {
			float: none;
			text-align: center;
		}
	}
}