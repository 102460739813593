.button-up {
	background-color: white;
	box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
	width: 100%;
	text-align: center;
	padding-top: 9px;
	padding-bottom: 11px;
	//display: block;
	text-decoration: none;
	display: none;

	&__text {
		display: inline-block;
		vertical-align: middle;
		color: @green-color;
		.museo();
		font-size: 14px;
		text-decoration: none;
		margin-right: 13px;
	}
	&__icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-up);
		margin-top: -2px;
		.svg-icon(icon-up, 9px, 10px);
	}
}

@media screen and (max-width: 420px) {
	.button-up {
		display: block;
	}
}