.map-block {
	margin-top: 12px;

	&__title {
		.museobold();
		font-size: 24px;
		margin-bottom: 20px;
		display: none;
		text-align: center;
		&_visible {
			text-align: left;
			display: block;
			padding-bottom: 51px;
			border-bottom: 1px solid @border-color;
			font-size: 42px;
			margin-top: 60px;
		}
	}

	&__map-wrap {
		position: relative;
		width: 100%;
		//height: 772px;
		height: calc(~"100vh - 108px");
	}
	&__map-section {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__map-itself {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 815px) {
	.map-block {
		margin-top: 20px;

		&__title {
			display: block;
		}

	}
}

@media screen and (max-width: 500px) {
	.map-block {
		&__map-wrap {
			height: calc(~"100vh - 152px");
		}
	}
}