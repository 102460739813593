.search-results-line {
	padding-top: 14px;
	padding-bottom: 10px;
	border-top: 1px solid @border-color;

	&_no-border {
		border-top: 0;
	}
	&_no-padding {
		padding-top: 0;
		margin-top: -5px;
	}

	&__container {

	}
	&__wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__text {
		color: #b2b2b2;
		.museo();
		font-size: 14px;
		margin-right: 10px;
	}
	&__result {
		display: flex;
		flex-direction: row;
		text-decoration: none;
		align-items: center;
		margin-right: 10px;

		padding: 0 24px 0 10px;
		height: 36px;
		line-height: 36px;
		background-color: white;
		border-radius: 2px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.11);
		position: relative;
		.transition(all .3s ease);
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&__result-text {
		color: black;
		font-size: 14px;
		.regular();
	}
	&__result-close {
		width: 8px;
		height: 8px;
		position: absolute;
		right: 8px;
		top: 8px + 6px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(45deg));
			left: 0;
			top: 4px;
			.add-new__tags-item:hover & {
				background-color: black;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 1.5px;
			.transform(rotate(-45deg));
			left: 0;
			top: 4px;
			.add-new__tags-item:hover & {
				background-color: black;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.search-results-line {
		padding-left: 15px;
		padding-right: 15px;
	}
}