.edit-profile {
	margin-top: 68px;
	margin-bottom: 63px;

	&__title {
		color: black;
		font-size: 42px;
		.museobold();
		text-align: center;
		padding-bottom: 50px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 45px;
	}
	&__content {
		max-width: 940px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__nav {
		float: left;
		width: 300px;
	}
	&__nav-item {
		display: block;
		box-shadow: 0 1px 0 #f2f2f2;
		height: 40px;
		line-height: 40px;
		padding-left: 30px;

		font-size: 14px;
		.museobold();
		color: @green-color;
		text-decoration: none;
		cursor: pointer;
		//border-bottom: 1px solid #f2f2f2;

		&_active {
			color: black;
		}
	}

	&__main-wrap {
		margin-left: 300px + 40px;
	}
	&__main {
		display: none;
		&_active {
			display: block;
		}
	}
	&__form-text {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		margin-bottom: 20px;
		margin-top: -6px;
		line-height: 1.33;
	}
	&__input-container {
		width: 300px;
		margin-bottom: 20px;
	}
	&__submit {
		height: 40px;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		background: @green-color;
		cursor: pointer;
		padding: 0;
		width: 200px;
		text-align: center;
		line-height: 42px;
		border: 0;
		outline: none;
		color: white;
		.museobold();
		text-transform: uppercase;
		.transition(all .3s ease);
		margin-top: 20px;
		&:hover {
			background: @green-color-hover;
		}
		&_long {
			width: 250px;
		}
	}
}

@media screen and (max-width: 750px) {
	.edit-profile {
		margin-top: 10px;
		margin-bottom: 21px;

		&__title {
			display: none;
		}
		&__content {
			padding-left: 0;
			padding-right: 0;
		}
		&__nav {
			float: none;
			width: 100%;
			display: block;
			margin-bottom: 20px;
		}

		&__main-wrap {
			margin-left: 0;
			padding-left: 18px;
			padding-right: 18px;
		}
	}
}

@media screen and (max-width: 500px) {
	.edit-profile {
		&__input-container {
			width: 100%;
			.box-sizing(border-box;)
		}
		&__submit {
			width: 100%;
		}
	}
}