.col-map {
	margin-top: 25px;
	max-width: 815px;
	margin-left: auto;
	margin-right: auto;

	&__top {

	}
	&__title {
		.museobold();
		font-size: 24px;
		text-align: center;
		margin-bottom: 20px;
	}
	&__show-list {
		display: block;
		font-size: 14px;
		color: @green-color;
		text-decoration: none;
		.museo();
		border-top: 1px solid #e7e7e7;
		padding-top: 12px;
		padding-bottom: 16px;
		text-align: center;
	}
	&__map-block {
		height: 400px;
		position: relative;
	}
	&__map-wrap {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__map-itself {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 430px) {
	.col-map {

		&__title {
			padding-left: 18px;
			padding-right: 18px;
			line-height: 1.3;
		}
	}
}