.card-on-map {
	position: absolute;
	left: 50%;
	margin-left: -80px;
	top: 147px;
	width: 164px;
	background-color: white;
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.11);
	padding-top: 20px;

	&__close {
		position: absolute;
		//.sprite(@icon-close);
		.svg-icon(icon-close, 15px, 15px);
		right: 12px;
		top: 12px;
		cursor: pointer;
		text-decoration: none;
	}
	&__image-wrap {
		display: block;
		width: 93px;
		height: 93px;
		border-radius: 50%;
		overflow: hidden;
		margin-bottom: 14px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		transition: all .3s ease;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );
			border-radius: 50%;
			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		&:hover {
			&:after {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&__text-wrap {
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
	}
	&__country {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
		margin-bottom: 6px;
	}
	&__place-name {
		color: black;
		.museobold();
		font-size: 14px;
		line-height: 1.25;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__button-block {
		width: 100%;
		border-top: 1px solid @border-color;
		background: white;
	}
	&__button {
		position: relative;
		&_left {
			float: left;
			width: 50%;
			border-right: 1px solid @border-color;
			.box-sizing(border-box);
		}
		&_right {
			float: right;
			width: 50%;
		}
	}
}

@media screen and (max-width: 500px) {
	.card-on-map {
		width: 123px;
		padding-top: 10px;

		&__close {
			//display: none;
			top: 9px;
			right: 9px;
		}
		&__image-wrap {
			width: 75px;
			height: 75px;
			margin-bottom: 10px;
		}
		&__text-wrap {
			margin-bottom: 12px;
			padding-left: 10px;
			padding-right: 10px;
		}
		&__country {
			font-size: 10px;
		}
		&__place-name {
			font-size: 12px;
		}
	}
}