.content-wrap {
	//margin-right: 300px;
	display: inline-block;
	vertical-align: top;
	width: calc(~"100% - 301px");
	margin-right: -4px;
	overflow: hidden;
	&_with-margin {
		margin-right: 40px - 4px;
		width: calc(~"100% - 301px - 40px");
	}
	&_with-vertical-line {
		//border-right: 1px solid #f6f6f6;
		box-shadow: 1px 0 3px -1px rgba(0, 0, 0, 0.11);
		.box-sizing(border-box);
		position: relative;
	}
	&_resize {
		width: 50%;
	}

	&__container {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (max-width: 1360px) {
	.content-wrap {

		&__container {
			max-width: 700px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.content-wrap {

		&__container {
			max-width: 600px;
		}
	}
}

@media screen and (max-width: 1090px) {
	.content-wrap {

		&__container {
			max-width: 500px;
		}
	}
}

@media screen and (max-width: 815px) {
	.content-wrap {
		width: 100%;

		&_with-margin {
			margin-right: 0;
			width: 100%;
		}
		&_with-vertical-line {
			box-shadow: none;
		}

		&__container {
			max-width: none;
		}
	}
}