.tags-list {
	margin-bottom: 60px - 14px;

	&__title {
		margin-top: 60px;
		margin-bottom: 50px;
		color: black;
		.museobold();
		font-size: 42px;
		text-align: center;
	}
	&__top-line {
		border-top: 1px solid rgba(0, 0, 0, 0.08);
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		padding-top: 5px;
		//padding-bottom: 9px;
		margin-bottom: 30px;
	}
	&__top-line-wrap {

	}
	&__left-part {
		float: left;
	}
	&__top-line-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
	}
	&__sort-category {
		display: inline-block;
		vertical-align: middle;
		max-width: 50px;
	}
	&__sort-category-select {
		width: 100%;
	}
	&__right-part {
		float: right;
	}

	&__sort-block-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__sort-block-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
	&__sort-block {

	}
	&__sort-block-select {
		width: 100%;
	}

	&__list {

	}
	&__list-column {
		display: inline-block;
		vertical-align: top;
		margin-right: 80px - 4px;
		width: calc(~"(100% - 80px * 2) / 3");
		&:nth-child(3) {
			margin-right: -4px;
		}
	}
	&__list-group {
		margin-bottom: 31px;
	}
	&__list-group-title {
		color: rgba(0, 0, 0, 0.4);
		.museobold();
		font-size: 12px;
		letter-spacing: 2px;
		margin-bottom: 15px;
	}
	&__list-group-list {
		//margin-top: 15px;
	}
	&__item {
		//display: block;
		margin-bottom: 13px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
	&__item-tag-wrap {

	}
	&__item-tag {
		.museo();
		font-size: 14px;
		color: black;
		text-decoration: none;
		line-height: 1.4;
		//display: inline;
		//display: inline-block;
		//vertical-align: middle;
		transition: all .2s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__item-tag-count {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 9px;
		//display: inline-block;
		//vertical-align: middle;
		margin-top: -3px;
	}
}

@media screen and (max-width: 1060px) {
	.tags-list {
		&__list-column {
			margin-right: 30px - 4px;
			width: calc(~"(100% - 30px * 2) / 3");
		}
	}
}

@media screen and (max-width: 850px) {
	.tags-list {

		&__list {
			//text-align: center;
		}
		//&__list-column {
		//	margin-right: 0;
		//	width: 100%;
		//	&:nth-child(3) {
		//		margin-right: 0;
		//	}
		//}
		&__list-column {
			width: calc(~"(100% - 80px) / 2");
			&:nth-child(2) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.tags-list {
		margin-bottom: 10px;

		&__title {
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 24px;
		}

		&__list-column {
			margin-right: 0;
			width: 100%;
			&:nth-child(3) {
				margin-right: 0;
			}
			&:nth-child(2) {
				margin-right: 0;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.tags-list {

		&__top-line {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__top-line-text-span {
			display: none;
		}
		&__list {
			text-align: left;
		}
		&__list-column {
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
		}
	}
}