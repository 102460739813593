.top-lists-block {
	text-align: center;
	margin-top: -100px;
	position: relative;
	z-index: 20;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	&__item {
		//display: inline-block;
		//vertical-align: top;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		width: 33.333%;
		//margin-right: -4px;
		//height: 200px;
		min-height: 200px;
		flex-grow: 1;
		background-color: white;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.11);
		padding-left: 30px;
		padding-right: 30px;
		.box-sizing(border-box);
		text-align: center;
		position: relative;
		padding-top: 25px;
		padding-bottom: 25px;
	}
	&__item-category {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		letter-spacing: 2px;
		.museo();
		//margin-bottom: 16px;
		text-transform: uppercase;
	}
	&__item-title-wrap {
		min-height: 115px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		//margin-bottom: 16px;
	}
	&__item-title {
		color: black;
		.museobold();
		font-size: 22px;
		line-height: 1.3;
		text-decoration: none;
		.transition(all .3s ease);

		&:hover {
			color: @green-color-hover;
		}
	}
	&__item-link {
		//position: absolute;
		//bottom: 24px;
		//left: 0;
		//right: 0;

		color: @green-color;
		.museo();
		letter-spacing: 2px;
		font-size: 12px;
		text-decoration: none;
	}
}

@media screen and (max-width: 1030px) {
	.top-lists-block {
		margin-top: -40px;
	}
}

@media screen and (max-width: 1000px) {
	.top-lists-block {

		&__item-title {
			font-size: 17px;
			line-height: 1.2;
		}
		//margin-top: 0;

		//&__item {
		//	display: block;
		//	margin-left: auto;
		//	margin-right: auto;
		//	width: 320px;
		//}
	}
}

@media screen and (max-width: 760px) {
	.top-lists-block {
		margin-top: 0;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__item-title {
			font-size: 20px;
			line-height: 1.3;
		}

		&__item {
			//display: block;
			//margin-left: auto;
			//margin-right: auto;
			width: 320px;
		}
	}
}

@media screen and (max-width: 360px) {
	.top-lists-block {
		margin-bottom: 0;

		&__item {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			box-shadow: none;
			border-bottom: 1px solid @border-color;
			&:last-child {
				border-bottom: 0;
			}
		}
		&__item-title {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}