.random-place {
	margin-bottom: 60px;

	&__title-wrap {
		padding-bottom: 13px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 29px;
	}
	&__title {
		float: left;
		.museobold();
		color: black;
		font-size: 14px;
		letter-spacing: 1px;
	}
	&__change-place {
		float: right;
		border: 0;
		padding: 0;
		cursor: pointer;
		.svg-icon(icon-change-place, 16px, 14px);
	}
	&__place-card {

	}
	&__image {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 16px;

		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&__country {
		font-size: 12px;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		margin-bottom: 15px;
		text-align: center;
	}
	&__name {
		color: black;
		font-size: 20px;
		line-height: 1.3;
		.museobold();
		text-align: center;
		margin-bottom: 20px;
	}
	&__info {
		margin-bottom: 20px;
		text-align: center;
	}
	&__been-here {
		display: inline-block;
		vertical-align: middle;
		font-size: 13px;
		.museo();
		color: black;
		margin-right: 23px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0,0,0,0.2);
			right: -14px;
			top: 6px;
		}
	}
	&__want-to-visit {
		display: inline-block;
		vertical-align: middle;
		font-size: 13px;
		.museo();
		color: black;
	}

	&__button-block {
		text-align: center;
		white-space: nowrap;
	}
	&__button {
		display: inline-block;
		vertical-align: middle;
		&:first-child {
			margin-right: 8px;
		}
	}
}