.header {
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
	padding-top: 11px;
	padding-bottom: 11px;
	//position: relative;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 140;
	background: white;

	&_transparent {
		background: transparent;
	}
	&_menu-opened {
		position: absolute !important;
	}

	&__container {
		position: relative;
		z-index: 20;
	}

	&__left-part {
		float: left;
	}

	&__burger {
		display: none;
		width: 16px;
		height: 12px;
		position: relative;
		margin-right: 45px;
	}
	&__burger-line {
		width: 100%;
		position: absolute;
		height: 2px;
		background: #000;
		border-radius: 1px;
		&:nth-child(1) {
			top: 0;
			left: 0;
		}
		&:nth-child(2) {
			top: 5px;
			left: 0;
		}
		&:last-child {
			top: 10px;
			left: 0;
		}

		.header_transparent & {
			background: white;
		}
	}
	&__logo {
		width: 162px;
		height: 41px;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		background-size: 162px 41px;
		margin-right: 6px;
		&_mobile {
			display: none;
		}
		&_green {
			background: url(../img/aroundcard-green.svg) no-repeat 0 0;
		}
		&_white {
			background: url(../img/aroundcard-white.svg) no-repeat 0 0;
		}
		&_black {
			background: url(../img/aroundcard.svg) no-repeat 0 0;
		}
	}
	&__logo-img {
		max-width: 100%;
		max-height: 100%;
		&_green {
			display: inline;
			.header_transparent & {
				display: none;
			}
		}
		&_white {
			display: none;
			.header_transparent & {
				display: inline;
			}
		}

		&_retina-green {
			display: none;
		}
		&_white-green {
			display: none;
		}

	}
	&__lang-wrap {
		display: inline-block;
		vertical-align: top;
		width: 34px;
		height: 18px;
		margin-right: 41px;
		position: relative;
		cursor: pointer;
	}
	&__lang {
		color: @green-color;
		.museo();
		font-size: 12px;
		position: relative;

		width: 100%;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 5px;
			.sprite(@icon-caret-green);
			.transition(all .3s ease);
		}
		&_active {
			&:after {
				.transform(rotate(180deg));
			}
		}
		.header_transparent & {
			color: white;
			&:after {
				.sprite(@icon-caret-white);
			}
		}
	}

	&__lang-dropdown {
		position: absolute;
		top: 19px;
		left: 0;
		text-decoration: none;
		z-index: 100;
		.transition(all .3s ease);
	}
	&__lang-dropdown-item {
		display: block;
		padding-left: 20px;
		padding-right: 27px;
		background: white;
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
		width: 320px;
		padding-top: 14px;
		padding-bottom: 16px;
		text-decoration: none;
		.box-sizing(border-box);
		border-bottom: 1px solid @border-color;
		&:last-child {
			border-bottom: 0;
		}
		&:hover {
			background: #fbfbfb;
		}
	}
	&__lang-dropdown-item-lang {
		color: @green-color;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	&__lang-dropdown-item-text-wrap {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
	}
	&__lang-dropdown-item-count {
		display: inline-block;
		vertical-align: middle;
		.museo();
		font-size: 14px;
		color: black;
		text-decoration: none;
	}
	&__lang-dropdown-item-text {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.4);
		text-decoration: none;
		.museo();
		font-size: 14px;
	}

	&__nav {
		display: inline-block;
		vertical-align: middle;
		margin-right: 45px;
	}
	&__nav-item {
		color: black;
		font-size: 16px;
		.museobold();
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		.transition(all .3s ease);
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			color: @green-color;
		}
		.header_transparent & {
			color: white;
		}
	}
	&__community-link {
		color: black;
		font-size: 16px;
		.museobold();
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		.transition(all .3s ease);
		margin-top: 2px;
		&:hover {
			color: @green-color;
		}
		.header_transparent & {
			color: white;
		}
	}

	&__center {
		text-align: center;
		margin-left: 49%;
		margin-right: 20%;
		margin-top: 11px;
	}

	&__right-part {
		float: right;
	}
	&__search-block {
		display: inline-block;
		vertical-align: middle;
		margin-right: 40px;
	}
	&__search-block-icon {
		display: inline-block;
		margin-right: 20px;
		vertical-align: middle;
		.svg-icon(icon-search, 14px, 14px);
		.header_transparent & {
			.svg-icon(icon-search-white, 14px, 14px);
		}
	}
	&__search-input-holder {
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}
	&__search-input {
		border: 0;
		color: black;
		.museo();
		font-size: 14px;

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}

		.header_transparent & {
			color: white;
			background: transparent;
		}
	}

	&__search-link {
		vertical-align: middle;
		margin-right: 24px;
		.svg-icon(icon-search, 14px, 14px);
		text-decoration: none;
		display: none;
	}
	&__add-card {
		display: inline-block;
		vertical-align: middle;
		margin-right: 40px;
	}
	&__add-card-button {
		display: block;
		text-decoration: none;
		height: 34px;
		background: #56a789;
		border-radius: 3px;
		padding: 0 14px;
		line-height: 34px;
		color: white;
		.museobold();
		font-size: 12px;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		.transition(all .3s ease);
		&:hover {
			background: @green-color-hover;
		}
	}

	&__register-block {
		vertical-align: middle;
		display: none;
		.header_not-auth & {
			display: inline-block;
		}
	}
	&__register {
		display: inline-block;
		vertical-align: middle;
		.museo();
		font-size: 16px;
		color: @green-color;
		margin-right: 24px;
		position: relative;
		text-decoration: none;
		.transition(all .3s ease);
		&:after {
			content: '/';
			position: absolute;
			.museo();
			font-size: 16px;
			color: rgba(0, 0, 0, 0.2);
			right: -17px;
			top: 0;
		}
		&:hover {
			color: @green-color-hover;

		}
		.header_transparent & {
			color: white;
		}
	}
	&__login {
		display: inline-block;
		vertical-align: middle;
		.museo();
		font-size: 16px;
		color: @green-color;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
		.header_transparent & {
			color: white;
		}
	}
	&__unauth-mobile-link {
		//width: 40px;
		//height: 40px;
		//background: url(../img/src/icon-unauth-mobile.svg) no-repeat 0 0;
		//background-size: 40px 40px;
		.svg-icon(icon-unauth-mobile, 40px, 40px);
		display: none;
	}

	&__person-block {
		vertical-align: middle;
		display: none;
		.header_auth & {
			display: inline-block;
		}
		.header_auth-with-no-photo & {
			display: inline-block;
		}
	}
	&__notifications-wrap {
		display: inline-block;
		vertical-align: middle;
		padding-bottom: 17px;
		margin-bottom: -17px;
	}
	&__notifications {
		width: 30px;
		height: 30px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
		position: relative;
		.sprite(@icon-notification);
		//.transition(all .3s ease);
		&:after {
			content: '';
			background: @violet-color;
			border: 1px solid white;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			position: absolute;
			bottom: 0;
			right: 0;
			display: none;
			.box-sizing(border-box);
		}
		&:hover {
			.sprite(@icon-notification-hover);
		}
		&_active {
			&:after {
				display: block;
			}
		}
		.header_transparent & {
			.sprite(@icon-notification-transp);
			&:after {
				border: 0;
			}
		}
	}
	&__profile {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		.header_auth-with-no-photo & {
			.svg-icon(icon-unauth-mobile, 40px, 40px) !important;
			z-index: 30;
		}
	}
	&__profile-img {
		max-width: 100%;
		.header_auth-with-no-photo & {
			display: none;
		}
	}

	&__search-line {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 22px 18px;
		display: none;
		background: white;
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
		z-index: 150;
	}
	&__search-line-container {

	}
	&__search-line-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-search, 14px, 14px);
		margin-right: 11px;
	}
	&__search-line-input-holder {
		display: inline-block;
		vertical-align: middle;
	}
	&__search-line-input {
		border: 0;
		color: black;
		font-size: 14px;
		.regular();

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.4);
		}
	}

	&__search-line-close {
		.svg-icon(icon-close-black, 8px, 8px);
		text-decoration: none;
		position: absolute;
		right: 18px;
		top: 27px;
	}
}

@media screen and (max-width: 1325px) {
	.header {

		&__nav {
			margin-right: 30px;
		}
		&__search-block-icon {
			margin-right: 7px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.header {

		&__logo {
			width: 142px;
			height: 35px;
			background-size: 142px 35px;
		}
		&__lang-wrap {
			margin-right: 25px;
		}
		&__nav {
			margin-right:20px;
		}
		&__nav-item {
			font-size: 14px;
			margin-right: 10px;
			.transition(all .3s ease);
		}
		&__community-link {
			font-size: 14px;
		}

		&__search-block {
			margin-right: 20px;
		}
		&__add-card {
			margin-right: 20px;
		}
		&__register {
			margin-right: 15px;
		}

		&__notifications {
			margin-right: 15px;
		}
	}
}

@media screen and (max-width: 1030px) {
	.header {
		//padding-bottom: 0;
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 300;
		.box-sizing(border-box);

		&__container {
			text-align: center;
		}
		&__left-part {
			//margin-top: 12px;
		}
		&__burger {
			display: inline-block;
			vertical-align: -7px;
		}
		&__logo {
			//width: 142px;
			display: none;
			&_mobile {
				display: inline-block;
				vertical-align: middle;
				width: 142px;
				height: 35px;
				background-size: 142px 35px;
			}
		}

		&__lang-wrap {
			display: none;
		}

		&__lang-dropdown {
			display: none;
		}

		&__nav {
			display: none;
		}
		&__community-link {
			display: none;
		}

		&__search-block {
			display: none;
		}

		&__search-link {
			display: inline-block;
		}

		&__add-card {
			display: none;
		}

		&__register {
			display: none;
		}
		&__login {
			display: none;
		}
		&__unauth-mobile-link {
			.header_not-auth & {
				display: block;
			}
		}

		&__person-block {
			vertical-align: middle;
			display: none;
			.header_auth & {
				display: inline-block;
			}
			.header_auth-with-no-photo & {
				display: inline-block;
			}
		}
		&__notifications-wrap {
			display: none;
		}
		&__profile {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			display: inline-block;
			vertical-align: middle;
		}
		&__profile-img {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: 415px) {
	.header {
		padding-left: 18px;
		padding-right: 18px;
		&__container {
			text-align: left;
		}

		&__burger{
			margin-right: 18px;
		}
	}
}