.add-new-place {
	width: 700px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	background: #ffffff;
	.box-sizing(border-box);
	padding-top: 30px;
	padding-bottom: 40px;
	&_mobile {
		width: auto;
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 18px;
		padding-right: 18px;
		box-shadow: none;
	}

	&__top {
		padding-left: 40px;
		padding-right: 40px;
		.add-new-place_mobile & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__return-link {
		margin-bottom: 20px;
		width: 91px;
		.box-sizing(border-box);
		display: flex;
		flex-direction: row;
		align-items: center;

		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
	}
	&__return-link-icon {
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		color: black;
		.museo();
		font-size: 14px;
	}

	&__title {
		color: #000000;
		font-size: 30px;
		.museobold();
		margin-bottom: 20px;
		.add-new-place_mobile & {
			font-size: 24px;
			text-align: center;
		}
	}
	&__content {
		display: flex;
		flex-direction: row;
		.add-new-place_mobile & {
			flex-direction: column;
		}
	}
	&__photo {
		width: 200px;
		min-width: 200px;
		height: 200px;
		border-radius: 50%;
		margin-right: 30px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		.add-new-place_empty & {
			background: #f2f2f2;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			padding-right: 20px;
			.box-sizing(border-box);
		}
		.add-new-place_mobile & {
			width: 284px;
			height: 284px;
			margin-right: 0;
			margin-bottom: 20px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
	&__photo-text {
		font-size: 12px;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		text-align: center;
	}
	&__photo-link {
		font-size: 12px;
		line-height: 1.5;
		color: @green-color;
		.regular();
		transition: all .3s ease;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__info {
		width: calc(~"100% - 200px - 30px");
		.add-new-place_mobile & {
			width: 100%;
		}
	}

	&__map-block {
		width: 100%;
		height: 171px;
		position: relative;
		margin-bottom: 20px;
		&_open {
			height: 400px;
		}
	}
	&__map-block-resize {
		position: absolute;
		bottom: -20px;
		width: 16px;
		height: 10px;
		left: 50%;
		margin-left: 8px;
	}
	&__map-block-resize-line {
		left: 0;
		right: 0;
		height: 2px;
		position: absolute;
		background: @green-color;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 4px;
		}
		&:last-child {
			bottom: 0;
		}
	}
	&__map {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__map-itself {
		width: 100%;
		height: 100%;
	}

	&__button-wrap {
		display: flex;
		justify-content: flex-end;
		padding: 0 40px;
		.add-new-place_mobile & {
			padding: 0;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	&__button-add {
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: @green-color;
		text-align: center;
		text-decoration: none;
		border: 0;
		outline: none;

		color: #ffffff;
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: uppercase;
		.museobold();
		cursor: pointer;
		transition: all .3s ease;
		&:hover {
			background: @green-color-hover;
		}
		&.disabled {
			background: #ddf1e8;
			cursor: auto;
		}
		.add-new-place_mobile & {
			padding: 0;
			width: 100%;
			margin-bottom: 20px;
		}
	}

	&__button-cancel {
		display: block;
		height: 40px;
		line-height: 40px;
		padding: 0;
		width: 100%;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 2px;
		background: white;
		text-align: center;
		text-decoration: none;
		border: 0;
		outline: none;

		color: #000000;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		.museobold();
		cursor: pointer;
		transition: all .3s ease;
		&:hover {
			background: #fbfbfb;
		}
	}
}