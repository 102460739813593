.top-background {
	height: 278px;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;


	&_route {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 5px;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.6);
			opacity: 0;
			visibility: hidden;
			transition: all .3s ease;
			z-index: 10;
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}


	&__text {
		display: flex;
		flex-direction: column;
		align-items: center;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;
		position: relative;
		z-index: 20;
		.top-background:hover & {
			opacity: 1;
			visibility: visible;
		}
	}
	&__icon-delete {
		.svg-icon(icon-delete, 18px, 21px);
		margin-bottom: 16px;
	}
	&__delete-link {
		color: #ffffff;
		font-size: 14px;
		.museobold();
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
}

@media screen and (max-width: 415px) {
	.top-background {
		height: 139px;
	}
}