.cards-desc {
	margin-bottom: 40px;

	&__copyright-line {

	}
	&__copyright-icon {
		display: inline-block;
		vertical-align: middle;
		.sprite(@icon-copyright);
	}
	&__copyright-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
	}
	&__copyright-name {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		font-size: 14px;
		color: @green-color;
		.regular();
		&:hover {
			color: @green-color-hover;
		}
	}
	&__error-report {
		margin-bottom: 15px;
		text-align: right;
		&_mobile {
			display: none;
			text-align: left;
		}
	}
	&__error-report-text {
		display: inline-block;
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&__top-wrap {
		position: relative;
		margin-bottom: 14px;
	}
	&__title {
		.museobold();
		font-size: 30px;
		line-height: 1.3;
		margin-right: 40px;
		//display: inline;
	}
	&__change {
		//display: inline;
		//position: relative;
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		//background: #bc76a3;
		background: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border-radius: 50%;
		border: 0;
		cursor: pointer;
		.transition(all .3s ease);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			.sprite(@icon-edit-dark);
			margin-top: -@icon-edit-dark-height / 2;
			margin-left: -@icon-edit-dark-width / 2;
			//background: url(../img/src/icon-change.svg) no-repeat 50% 50%;
			//background-size: 14px 14px;
			//width: 14px;
			//height: 14px;
			//margin-top: -7px;
			//margin-left: -7px;
		}
		&:hover {
			//background: @violet-color;
			background: #fbfbfb;
		}
	}
	&__desc {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.3;
		margin-bottom: 20px;
	}

	&__hashtags {
		margin-bottom: 30px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&__hashtags-item {
		//display: inline-block;
		//vertical-align: middle;
		margin-right: 8px;
		margin-bottom: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		&:last-child {
			margin-right: 0;
		}
	}
	&__hashtags-item-hash {
		.medium();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		margin-top: 2px;
	}
	&__hashtags-item-text-wrap {

	}
	&__hashtags-item-text {
		.medium();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			text-decoration: underline;
		}
	}

	&__serv-line {
		margin-bottom: 28px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__buttons-block {
		//display: inline-block;
		//vertical-align: middle;
		margin-right: 20px;
		display: flex;
		flex-direction: row;
	}
	&__button {
		//display: inline-block;
		//vertical-align: middle;
		margin-right: 10px;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
	}
	&__said-thanks-block {
		//display: inline-block;
		//vertical-align: middle;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__said-thanks-person-photo {
		//display: inline-block;
		//vertical-align: middle;
		//margin-right: 6px - 4px;
		overflow: hidden;
		border-radius: 50%;
		border: 2px solid white;
		flex-basis: 24px;
		height: 24px;
		margin-right: 6px;
	}
	&__said-thanks-person-photo-img {
		max-width: 100%;
	}
	&__said-thanks-text-wrap {
		//display: inline-block;
		//vertical-align: middle;
		//width: calc(~"100% - 24px - 6px");
		//margin-right: -4px;
		flex-basis: calc(~"100% - 24px - 6px");
	}
	&__said-thanks-person-name {
		.museo();
		font-size: 14px;
		color: @green-color;
		display: inline;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&__said-thanks-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		display: inline;
		cursor: pointer;
		.transition(all .3s ease);
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}

	&__bottom-block {

	}
	&__author-block {
		//float: left;
	}
	&__author-photo {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		overflow: hidden;
		border-radius: 50%;
	}
	&__author-photo-img {
		max-width: 100%;
	}
	&__author-name {
		font-size: 14px;
		color: #56a789;
		.museo();
		display: inline;
		text-decoration: none;
	}
	&__post-date {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
		display: inline;
	}

}

@media screen and (max-width: 1090px) {
	.cards-desc {

		&__serv-line {
			margin-bottom: 10px;
			flex-direction: column;
			align-items: flex-start;
		}
		&__buttons-block {
			//display: block;
			margin-right: 0;
			margin-bottom: 10px;
		}
		&__said-thanks-block {
			//display: block;
		}
	}
}

@media screen and (max-width: 815px) {
	.cards-desc {
		padding-left: 18px;
		padding-right: 18px;

		&__copyright-line {
			margin-bottom: 25px;
		}
		&__error-report {
			display: none;

			&_mobile {
				display: block;
			}
		}
		&__bottom-block {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 500px) {
	.cards-desc {

		&__change {
			right: 10px;
			top: -55px;
			z-index: 100;
		}
		&__author-block {
			float: none;
			display: block;
			margin-bottom: 32px;
		}
	}
}

@media screen and (max-width: 415px) {
	.cards-desc {
		padding-left: 14px;
		padding-right: 14px;

		&__author-name {
			font-size: 12px;
		}
		&__post-date {
			font-size: 12px;
		}
	}
}