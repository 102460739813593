/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@icon-add-new-name: 'icon-add-new';
@icon-add-new-x: 0px;
@icon-add-new-y: 0px;
@icon-add-new-offset-x: 0px;
@icon-add-new-offset-y: 0px;
@icon-add-new-width: 30px;
@icon-add-new-height: 30px;
@icon-add-new-total-width: 278px;
@icon-add-new-total-height: 277px;
@icon-add-new-image: '../img/sprite.png';
@icon-add-new: 0px 0px 0px 0px 30px 30px 278px 277px '../img/sprite.png' 'icon-add-new';
@icon-calendar-arrow-name: 'icon-calendar-arrow';
@icon-calendar-arrow-x: 100px;
@icon-calendar-arrow-y: 0px;
@icon-calendar-arrow-offset-x: -100px;
@icon-calendar-arrow-offset-y: 0px;
@icon-calendar-arrow-width: 27px;
@icon-calendar-arrow-height: 25px;
@icon-calendar-arrow-total-width: 278px;
@icon-calendar-arrow-total-height: 277px;
@icon-calendar-arrow-image: '../img/sprite.png';
@icon-calendar-arrow: 100px 0px -100px 0px 27px 25px 278px 277px '../img/sprite.png' 'icon-calendar-arrow';
@icon-caret-black-name: 'icon-caret-black';
@icon-caret-black-x: 235px;
@icon-caret-black-y: 68px;
@icon-caret-black-offset-x: -235px;
@icon-caret-black-offset-y: -68px;
@icon-caret-black-width: 6px;
@icon-caret-black-height: 4px;
@icon-caret-black-total-width: 278px;
@icon-caret-black-total-height: 277px;
@icon-caret-black-image: '../img/sprite.png';
@icon-caret-black: 235px 68px -235px -68px 6px 4px 278px 277px '../img/sprite.png' 'icon-caret-black';
@icon-caret-green-name: 'icon-caret-green';
@icon-caret-green-x: 266px;
@icon-caret-green-y: 198px;
@icon-caret-green-offset-x: -266px;
@icon-caret-green-offset-y: -198px;
@icon-caret-green-width: 7px;
@icon-caret-green-height: 5px;
@icon-caret-green-total-width: 278px;
@icon-caret-green-total-height: 277px;
@icon-caret-green-image: '../img/sprite.png';
@icon-caret-green: 266px 198px -266px -198px 7px 5px 278px 277px '../img/sprite.png' 'icon-caret-green';
@icon-caret-white-name: 'icon-caret-white';
@icon-caret-white-x: 266px;
@icon-caret-white-y: 223px;
@icon-caret-white-offset-x: -266px;
@icon-caret-white-offset-y: -223px;
@icon-caret-white-width: 7px;
@icon-caret-white-height: 5px;
@icon-caret-white-total-width: 278px;
@icon-caret-white-total-height: 277px;
@icon-caret-white-image: '../img/sprite.png';
@icon-caret-white: 266px 223px -266px -223px 7px 5px 278px 277px '../img/sprite.png' 'icon-caret-white';
@icon-change-bg-dark-name: 'icon-change-bg-dark';
@icon-change-bg-dark-x: 0px;
@icon-change-bg-dark-y: 232px;
@icon-change-bg-dark-offset-x: 0px;
@icon-change-bg-dark-offset-y: -232px;
@icon-change-bg-dark-width: 14px;
@icon-change-bg-dark-height: 14px;
@icon-change-bg-dark-total-width: 278px;
@icon-change-bg-dark-total-height: 277px;
@icon-change-bg-dark-image: '../img/sprite.png';
@icon-change-bg-dark: 0px 232px 0px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-change-bg-dark';
@icon-change-bg-name: 'icon-change-bg';
@icon-change-bg-x: 34px;
@icon-change-bg-y: 232px;
@icon-change-bg-offset-x: -34px;
@icon-change-bg-offset-y: -232px;
@icon-change-bg-width: 14px;
@icon-change-bg-height: 14px;
@icon-change-bg-total-width: 278px;
@icon-change-bg-total-height: 277px;
@icon-change-bg-image: '../img/sprite.png';
@icon-change-bg: 34px 232px -34px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-change-bg';
@icon-checkbox-name: 'icon-checkbox';
@icon-checkbox-x: 204px;
@icon-checkbox-y: 232px;
@icon-checkbox-offset-x: -204px;
@icon-checkbox-offset-y: -232px;
@icon-checkbox-width: 14px;
@icon-checkbox-height: 14px;
@icon-checkbox-total-width: 278px;
@icon-checkbox-total-height: 277px;
@icon-checkbox-image: '../img/sprite.png';
@icon-checkbox: 204px 232px -204px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-checkbox';
@icon-close-name: 'icon-close';
@icon-close-x: 266px;
@icon-close-y: 170px;
@icon-close-offset-x: -266px;
@icon-close-offset-y: -170px;
@icon-close-width: 8px;
@icon-close-height: 8px;
@icon-close-total-width: 278px;
@icon-close-total-height: 277px;
@icon-close-image: '../img/sprite.png';
@icon-close: 266px 170px -266px -170px 8px 8px 278px 277px '../img/sprite.png' 'icon-close';
@icon-contacts-1-hover-name: 'icon-contacts-1-hover';
@icon-contacts-1-hover-x: 147px;
@icon-contacts-1-hover-y: 0px;
@icon-contacts-1-hover-offset-x: -147px;
@icon-contacts-1-hover-offset-y: 0px;
@icon-contacts-1-hover-width: 24px;
@icon-contacts-1-hover-height: 24px;
@icon-contacts-1-hover-total-width: 278px;
@icon-contacts-1-hover-total-height: 277px;
@icon-contacts-1-hover-image: '../img/sprite.png';
@icon-contacts-1-hover: 147px 0px -147px 0px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-1-hover';
@icon-contacts-1-name: 'icon-contacts-1';
@icon-contacts-1-x: 147px;
@icon-contacts-1-y: 44px;
@icon-contacts-1-offset-x: -147px;
@icon-contacts-1-offset-y: -44px;
@icon-contacts-1-width: 24px;
@icon-contacts-1-height: 24px;
@icon-contacts-1-total-width: 278px;
@icon-contacts-1-total-height: 277px;
@icon-contacts-1-image: '../img/sprite.png';
@icon-contacts-1: 147px 44px -147px -44px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-1';
@icon-contacts-2-hover-name: 'icon-contacts-2-hover';
@icon-contacts-2-hover-x: 147px;
@icon-contacts-2-hover-y: 88px;
@icon-contacts-2-hover-offset-x: -147px;
@icon-contacts-2-hover-offset-y: -88px;
@icon-contacts-2-hover-width: 24px;
@icon-contacts-2-hover-height: 24px;
@icon-contacts-2-hover-total-width: 278px;
@icon-contacts-2-hover-total-height: 277px;
@icon-contacts-2-hover-image: '../img/sprite.png';
@icon-contacts-2-hover: 147px 88px -147px -88px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-2-hover';
@icon-contacts-2-name: 'icon-contacts-2';
@icon-contacts-2-x: 0px;
@icon-contacts-2-y: 144px;
@icon-contacts-2-offset-x: 0px;
@icon-contacts-2-offset-y: -144px;
@icon-contacts-2-width: 24px;
@icon-contacts-2-height: 24px;
@icon-contacts-2-total-width: 278px;
@icon-contacts-2-total-height: 277px;
@icon-contacts-2-image: '../img/sprite.png';
@icon-contacts-2: 0px 144px 0px -144px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-2';
@icon-contacts-3-hover-name: 'icon-contacts-3-hover';
@icon-contacts-3-hover-x: 44px;
@icon-contacts-3-hover-y: 144px;
@icon-contacts-3-hover-offset-x: -44px;
@icon-contacts-3-hover-offset-y: -144px;
@icon-contacts-3-hover-width: 24px;
@icon-contacts-3-hover-height: 24px;
@icon-contacts-3-hover-total-width: 278px;
@icon-contacts-3-hover-total-height: 277px;
@icon-contacts-3-hover-image: '../img/sprite.png';
@icon-contacts-3-hover: 44px 144px -44px -144px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-3-hover';
@icon-contacts-3-name: 'icon-contacts-3';
@icon-contacts-3-x: 88px;
@icon-contacts-3-y: 144px;
@icon-contacts-3-offset-x: -88px;
@icon-contacts-3-offset-y: -144px;
@icon-contacts-3-width: 24px;
@icon-contacts-3-height: 24px;
@icon-contacts-3-total-width: 278px;
@icon-contacts-3-total-height: 277px;
@icon-contacts-3-image: '../img/sprite.png';
@icon-contacts-3: 88px 144px -88px -144px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-3';
@icon-contacts-4-hover-name: 'icon-contacts-4-hover';
@icon-contacts-4-hover-x: 132px;
@icon-contacts-4-hover-y: 144px;
@icon-contacts-4-hover-offset-x: -132px;
@icon-contacts-4-hover-offset-y: -144px;
@icon-contacts-4-hover-width: 24px;
@icon-contacts-4-hover-height: 24px;
@icon-contacts-4-hover-total-width: 278px;
@icon-contacts-4-hover-total-height: 277px;
@icon-contacts-4-hover-image: '../img/sprite.png';
@icon-contacts-4-hover: 132px 144px -132px -144px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-4-hover';
@icon-contacts-4-name: 'icon-contacts-4';
@icon-contacts-4-x: 191px;
@icon-contacts-4-y: 0px;
@icon-contacts-4-offset-x: -191px;
@icon-contacts-4-offset-y: 0px;
@icon-contacts-4-width: 24px;
@icon-contacts-4-height: 24px;
@icon-contacts-4-total-width: 278px;
@icon-contacts-4-total-height: 277px;
@icon-contacts-4-image: '../img/sprite.png';
@icon-contacts-4: 191px 0px -191px 0px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-4';
@icon-contacts-5-hover-name: 'icon-contacts-5-hover';
@icon-contacts-5-hover-x: 191px;
@icon-contacts-5-hover-y: 44px;
@icon-contacts-5-hover-offset-x: -191px;
@icon-contacts-5-hover-offset-y: -44px;
@icon-contacts-5-hover-width: 24px;
@icon-contacts-5-hover-height: 24px;
@icon-contacts-5-hover-total-width: 278px;
@icon-contacts-5-hover-total-height: 277px;
@icon-contacts-5-hover-image: '../img/sprite.png';
@icon-contacts-5-hover: 191px 44px -191px -44px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-5-hover';
@icon-contacts-5-name: 'icon-contacts-5';
@icon-contacts-5-x: 191px;
@icon-contacts-5-y: 88px;
@icon-contacts-5-offset-x: -191px;
@icon-contacts-5-offset-y: -88px;
@icon-contacts-5-width: 24px;
@icon-contacts-5-height: 24px;
@icon-contacts-5-total-width: 278px;
@icon-contacts-5-total-height: 277px;
@icon-contacts-5-image: '../img/sprite.png';
@icon-contacts-5: 191px 88px -191px -88px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-5';
@icon-contacts-6-hover-name: 'icon-contacts-6-hover';
@icon-contacts-6-hover-x: 191px;
@icon-contacts-6-hover-y: 132px;
@icon-contacts-6-hover-offset-x: -191px;
@icon-contacts-6-hover-offset-y: -132px;
@icon-contacts-6-hover-width: 24px;
@icon-contacts-6-hover-height: 24px;
@icon-contacts-6-hover-total-width: 278px;
@icon-contacts-6-hover-total-height: 277px;
@icon-contacts-6-hover-image: '../img/sprite.png';
@icon-contacts-6-hover: 191px 132px -191px -132px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-6-hover';
@icon-contacts-6-name: 'icon-contacts-6';
@icon-contacts-6-x: 0px;
@icon-contacts-6-y: 188px;
@icon-contacts-6-offset-x: 0px;
@icon-contacts-6-offset-y: -188px;
@icon-contacts-6-width: 24px;
@icon-contacts-6-height: 24px;
@icon-contacts-6-total-width: 278px;
@icon-contacts-6-total-height: 277px;
@icon-contacts-6-image: '../img/sprite.png';
@icon-contacts-6: 0px 188px 0px -188px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-6';
@icon-contacts-7-hover-name: 'icon-contacts-7-hover';
@icon-contacts-7-hover-x: 88px;
@icon-contacts-7-hover-y: 100px;
@icon-contacts-7-hover-offset-x: -88px;
@icon-contacts-7-hover-offset-y: -100px;
@icon-contacts-7-hover-width: 24px;
@icon-contacts-7-hover-height: 24px;
@icon-contacts-7-hover-total-width: 278px;
@icon-contacts-7-hover-total-height: 277px;
@icon-contacts-7-hover-image: '../img/sprite.png';
@icon-contacts-7-hover: 88px 100px -88px -100px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-7-hover';
@icon-contacts-7-name: 'icon-contacts-7';
@icon-contacts-7-x: 44px;
@icon-contacts-7-y: 188px;
@icon-contacts-7-offset-x: -44px;
@icon-contacts-7-offset-y: -188px;
@icon-contacts-7-width: 24px;
@icon-contacts-7-height: 24px;
@icon-contacts-7-total-width: 278px;
@icon-contacts-7-total-height: 277px;
@icon-contacts-7-image: '../img/sprite.png';
@icon-contacts-7: 44px 188px -44px -188px 24px 24px 278px 277px '../img/sprite.png' 'icon-contacts-7';
@icon-copyright-name: 'icon-copyright';
@icon-copyright-x: 68px;
@icon-copyright-y: 232px;
@icon-copyright-offset-x: -68px;
@icon-copyright-offset-y: -232px;
@icon-copyright-width: 14px;
@icon-copyright-height: 14px;
@icon-copyright-total-width: 278px;
@icon-copyright-total-height: 277px;
@icon-copyright-image: '../img/sprite.png';
@icon-copyright: 68px 232px -68px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-copyright';
@icon-edit-dark-name: 'icon-edit-dark';
@icon-edit-dark-x: 136px;
@icon-edit-dark-y: 232px;
@icon-edit-dark-offset-x: -136px;
@icon-edit-dark-offset-y: -232px;
@icon-edit-dark-width: 14px;
@icon-edit-dark-height: 14px;
@icon-edit-dark-total-width: 278px;
@icon-edit-dark-total-height: 277px;
@icon-edit-dark-image: '../img/sprite.png';
@icon-edit-dark: 136px 232px -136px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-edit-dark';
@icon-edit-name: 'icon-edit';
@icon-edit-x: 170px;
@icon-edit-y: 232px;
@icon-edit-offset-x: -170px;
@icon-edit-offset-y: -232px;
@icon-edit-width: 14px;
@icon-edit-height: 14px;
@icon-edit-total-width: 278px;
@icon-edit-total-height: 277px;
@icon-edit-image: '../img/sprite.png';
@icon-edit: 170px 232px -170px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-edit';
@icon-f-name: 'icon-f';
@icon-f-x: 266px;
@icon-f-y: 34px;
@icon-f-offset-x: -266px;
@icon-f-offset-y: -34px;
@icon-f-width: 8px;
@icon-f-height: 18px;
@icon-f-total-width: 278px;
@icon-f-total-height: 277px;
@icon-f-image: '../img/sprite.png';
@icon-f: 266px 34px -266px -34px 8px 18px 278px 277px '../img/sprite.png' 'icon-f';
@icon-footer-1-hover-name: 'icon-footer-1-hover';
@icon-footer-1-hover-x: 0px;
@icon-footer-1-hover-y: 100px;
@icon-footer-1-hover-offset-x: 0px;
@icon-footer-1-hover-offset-y: -100px;
@icon-footer-1-hover-width: 24px;
@icon-footer-1-hover-height: 24px;
@icon-footer-1-hover-total-width: 278px;
@icon-footer-1-hover-total-height: 277px;
@icon-footer-1-hover-image: '../img/sprite.png';
@icon-footer-1-hover: 0px 100px 0px -100px 24px 24px 278px 277px '../img/sprite.png' 'icon-footer-1-hover';
@icon-lang-mobile-caret-name: 'icon-lang-mobile-caret';
@icon-lang-mobile-caret-x: 235px;
@icon-lang-mobile-caret-y: 44px;
@icon-lang-mobile-caret-offset-x: -235px;
@icon-lang-mobile-caret-offset-y: -44px;
@icon-lang-mobile-caret-width: 7px;
@icon-lang-mobile-caret-height: 4px;
@icon-lang-mobile-caret-total-width: 278px;
@icon-lang-mobile-caret-total-height: 277px;
@icon-lang-mobile-caret-image: '../img/sprite.png';
@icon-lang-mobile-caret: 235px 44px -235px -44px 7px 4px 278px 277px '../img/sprite.png' 'icon-lang-mobile-caret';
@icon-menu-cards-name: 'icon-menu-cards';
@icon-menu-cards-x: 102px;
@icon-menu-cards-y: 232px;
@icon-menu-cards-offset-x: -102px;
@icon-menu-cards-offset-y: -232px;
@icon-menu-cards-width: 14px;
@icon-menu-cards-height: 14px;
@icon-menu-cards-total-width: 278px;
@icon-menu-cards-total-height: 277px;
@icon-menu-cards-image: '../img/sprite.png';
@icon-menu-cards: 102px 232px -102px -232px 14px 14px 278px 277px '../img/sprite.png' 'icon-menu-cards';
@icon-menu-close-black-name: 'icon-menu-close-black';
@icon-menu-close-black-x: 266px;
@icon-menu-close-black-y: 104px;
@icon-menu-close-black-offset-x: -266px;
@icon-menu-close-black-offset-y: -104px;
@icon-menu-close-black-width: 12px;
@icon-menu-close-black-height: 12px;
@icon-menu-close-black-total-width: 278px;
@icon-menu-close-black-total-height: 277px;
@icon-menu-close-black-image: '../img/sprite.png';
@icon-menu-close-black: 266px 104px -266px -104px 12px 12px 278px 277px '../img/sprite.png' 'icon-menu-close-black';
@icon-menu-close-name: 'icon-menu-close';
@icon-menu-close-x: 266px;
@icon-menu-close-y: 72px;
@icon-menu-close-offset-x: -266px;
@icon-menu-close-offset-y: -72px;
@icon-menu-close-width: 12px;
@icon-menu-close-height: 12px;
@icon-menu-close-total-width: 278px;
@icon-menu-close-total-height: 277px;
@icon-menu-close-image: '../img/sprite.png';
@icon-menu-close: 266px 72px -266px -72px 12px 12px 278px 277px '../img/sprite.png' 'icon-menu-close';
@icon-menu-community-name: 'icon-menu-community';
@icon-menu-community-x: 266px;
@icon-menu-community-y: 0px;
@icon-menu-community-offset-x: -266px;
@icon-menu-community-offset-y: 0px;
@icon-menu-community-width: 12px;
@icon-menu-community-height: 14px;
@icon-menu-community-total-width: 278px;
@icon-menu-community-total-height: 277px;
@icon-menu-community-image: '../img/sprite.png';
@icon-menu-community: 266px 0px -266px 0px 12px 14px 278px 277px '../img/sprite.png' 'icon-menu-community';
@icon-notification-hover-name: 'icon-notification-hover';
@icon-notification-hover-x: 50px;
@icon-notification-hover-y: 50px;
@icon-notification-hover-offset-x: -50px;
@icon-notification-hover-offset-y: -50px;
@icon-notification-hover-width: 30px;
@icon-notification-hover-height: 30px;
@icon-notification-hover-total-width: 278px;
@icon-notification-hover-total-height: 277px;
@icon-notification-hover-image: '../img/sprite.png';
@icon-notification-hover: 50px 50px -50px -50px 30px 30px 278px 277px '../img/sprite.png' 'icon-notification-hover';
@icon-notification-transp-name: 'icon-notification-transp';
@icon-notification-transp-x: 0px;
@icon-notification-transp-y: 50px;
@icon-notification-transp-offset-x: 0px;
@icon-notification-transp-offset-y: -50px;
@icon-notification-transp-width: 30px;
@icon-notification-transp-height: 30px;
@icon-notification-transp-total-width: 278px;
@icon-notification-transp-total-height: 277px;
@icon-notification-transp-image: '../img/sprite.png';
@icon-notification-transp: 0px 50px 0px -50px 30px 30px 278px 277px '../img/sprite.png' 'icon-notification-transp';
@icon-notification-name: 'icon-notification';
@icon-notification-x: 50px;
@icon-notification-y: 0px;
@icon-notification-offset-x: -50px;
@icon-notification-offset-y: 0px;
@icon-notification-width: 30px;
@icon-notification-height: 30px;
@icon-notification-total-width: 278px;
@icon-notification-total-height: 277px;
@icon-notification-image: '../img/sprite.png';
@icon-notification: 50px 0px -50px 0px 30px 30px 278px 277px '../img/sprite.png' 'icon-notification';
@icon-reg-1-name: 'icon-reg-1';
@icon-reg-1-x: 235px;
@icon-reg-1-y: 0px;
@icon-reg-1-offset-x: -235px;
@icon-reg-1-offset-y: 0px;
@icon-reg-1-width: 11px;
@icon-reg-1-height: 24px;
@icon-reg-1-total-width: 278px;
@icon-reg-1-total-height: 277px;
@icon-reg-1-image: '../img/sprite.png';
@icon-reg-1: 235px 0px -235px 0px 11px 24px 278px 277px '../img/sprite.png' 'icon-reg-1';
@icon-reg-2-name: 'icon-reg-2';
@icon-reg-2-x: 132px;
@icon-reg-2-y: 188px;
@icon-reg-2-offset-x: -132px;
@icon-reg-2-offset-y: -188px;
@icon-reg-2-width: 20px;
@icon-reg-2-height: 17px;
@icon-reg-2-total-width: 278px;
@icon-reg-2-total-height: 277px;
@icon-reg-2-image: '../img/sprite.png';
@icon-reg-2: 132px 188px -132px -188px 20px 17px 278px 277px '../img/sprite.png' 'icon-reg-2';
@icon-reg-3-name: 'icon-reg-3';
@icon-reg-3-x: 88px;
@icon-reg-3-y: 188px;
@icon-reg-3-offset-x: -88px;
@icon-reg-3-offset-y: -188px;
@icon-reg-3-width: 24px;
@icon-reg-3-height: 17px;
@icon-reg-3-total-width: 278px;
@icon-reg-3-total-height: 277px;
@icon-reg-3-image: '../img/sprite.png';
@icon-reg-3: 88px 188px -88px -188px 24px 17px 278px 277px '../img/sprite.png' 'icon-reg-3';
@icon-say-thanks-name: 'icon-say-thanks';
@icon-say-thanks-x: 172px;
@icon-say-thanks-y: 188px;
@icon-say-thanks-offset-x: -172px;
@icon-say-thanks-offset-y: -188px;
@icon-say-thanks-width: 15px;
@icon-say-thanks-height: 16px;
@icon-say-thanks-total-width: 278px;
@icon-say-thanks-total-height: 277px;
@icon-say-thanks-image: '../img/sprite.png';
@icon-say-thanks: 172px 188px -172px -188px 15px 16px 278px 277px '../img/sprite.png' 'icon-say-thanks';
@icon-slider-arrow-name: 'icon-slider-arrow';
@icon-slider-arrow-x: 33px;
@icon-slider-arrow-y: 266px;
@icon-slider-arrow-offset-x: -33px;
@icon-slider-arrow-offset-y: -266px;
@icon-slider-arrow-width: 14px;
@icon-slider-arrow-height: 9px;
@icon-slider-arrow-total-width: 278px;
@icon-slider-arrow-total-height: 277px;
@icon-slider-arrow-image: '../img/sprite.png';
@icon-slider-arrow: 33px 266px -33px -266px 14px 9px 278px 277px '../img/sprite.png' 'icon-slider-arrow';
@icon-t-name: 'icon-t';
@icon-t-x: 0px;
@icon-t-y: 266px;
@icon-t-offset-x: 0px;
@icon-t-offset-y: -266px;
@icon-t-width: 13px;
@icon-t-height: 11px;
@icon-t-total-width: 278px;
@icon-t-total-height: 277px;
@icon-t-image: '../img/sprite.png';
@icon-t: 0px 266px 0px -266px 13px 11px 278px 277px '../img/sprite.png' 'icon-t';
@icon-up-name: 'icon-up';
@icon-up-x: 266px;
@icon-up-y: 136px;
@icon-up-offset-x: -266px;
@icon-up-offset-y: -136px;
@icon-up-width: 9px;
@icon-up-height: 14px;
@icon-up-total-width: 278px;
@icon-up-total-height: 277px;
@icon-up-image: '../img/sprite.png';
@icon-up: 266px 136px -266px -136px 9px 14px 278px 277px '../img/sprite.png' 'icon-up';
@icon-wink-closed-name: 'icon-wink-closed';
@icon-wink-closed-x: 44px;
@icon-wink-closed-y: 100px;
@icon-wink-closed-offset-x: -44px;
@icon-wink-closed-offset-y: -100px;
@icon-wink-closed-width: 24px;
@icon-wink-closed-height: 24px;
@icon-wink-closed-total-width: 278px;
@icon-wink-closed-total-height: 277px;
@icon-wink-closed-image: '../img/sprite.png';
@icon-wink-closed: 44px 100px -44px -100px 24px 24px 278px 277px '../img/sprite.png' 'icon-wink-closed';
@icon-wink-open-name: 'icon-wink-open';
@icon-wink-open-x: 100px;
@icon-wink-open-y: 45px;
@icon-wink-open-offset-x: -100px;
@icon-wink-open-offset-y: -45px;
@icon-wink-open-width: 24px;
@icon-wink-open-height: 24px;
@icon-wink-open-total-width: 278px;
@icon-wink-open-total-height: 277px;
@icon-wink-open-image: '../img/sprite.png';
@icon-wink-open: 100px 45px -100px -45px 24px 24px 278px 277px '../img/sprite.png' 'icon-wink-open';
@spritesheet-width: 278px;
@spritesheet-height: 277px;
@spritesheet-image: '../img/sprite.png';
@spritesheet-sprites: @icon-add-new @icon-calendar-arrow @icon-caret-black @icon-caret-green @icon-caret-white @icon-change-bg-dark @icon-change-bg @icon-checkbox @icon-close @icon-contacts-1-hover @icon-contacts-1 @icon-contacts-2-hover @icon-contacts-2 @icon-contacts-3-hover @icon-contacts-3 @icon-contacts-4-hover @icon-contacts-4 @icon-contacts-5-hover @icon-contacts-5 @icon-contacts-6-hover @icon-contacts-6 @icon-contacts-7-hover @icon-contacts-7 @icon-copyright @icon-edit-dark @icon-edit @icon-f @icon-footer-1-hover @icon-lang-mobile-caret @icon-menu-cards @icon-menu-close-black @icon-menu-close @icon-menu-community @icon-notification-hover @icon-notification-transp @icon-notification @icon-reg-1 @icon-reg-2 @icon-reg-3 @icon-say-thanks @icon-slider-arrow @icon-t @icon-up @icon-wink-closed @icon-wink-open;
@spritesheet: 278px 277px '../img/sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
