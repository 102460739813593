.collections-list {
	margin-top: 18px;

	&__title {
		.museobold();
		font-size: 24px;
		margin-bottom: 20px;
		display: none;
		text-align: center;
		&_visible {
			text-align: left;
			display: block;
			padding-bottom: 51px;
			border-bottom: 1px solid @border-color;
			font-size: 42px;
			margin-top: 60px;
		}
	}
	&__top-line {
		display: block;
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: 1px solid  rgba(0, 0, 0, 0.08);
	}
	&__cards-quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		margin-top: 11px;
	}
	&__sort-block {
		float: right;
		min-width: 165px;
	}
	&__sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__sort-block-select {
		width: 100%;
	}

	&__list {
		margin-bottom: 40px - 30px;
	}
	&__item {
		display: inline-block;
		vertical-align: top;
		width: calc(~"(100% - 20px * 2) / 3");
		margin-right: 20px - 4px;
		margin-bottom: 30px;
		text-decoration: none;
		&:nth-child(3n) {
			margin-right: -4px;
		}
		&:last-child {
			margin-right: -4px;
		}
	}
	&__item-image {
		height: 200px;
		margin-bottom: 16px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		.collections-list__item:hover & {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__item-title {
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: black;
		.museobold();
		font-size: 20px;
		line-height: 1.3;
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 6px;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__progress {
		width: 44px;
		height: 44px;
		position: absolute;
		right: 16px;
		bottom: 16px;
		z-index: 20;

		canvas {
			width: 100%;
			height: 100%;
		}
		strong {
			font-size: 11px;
			.museo();
			color: white;

			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 15px;
			line-height: normal;
		}
	}
	&__item-progress-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.regular();
		text-align: center;
	}

	&__button {
		text-align: center;
		margin-bottom: 12px;
		display: none;
	}
	&__bottom-line {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 14px;
		padding-bottom: 16px;
		border-top: 1px solid #e7e7e7;
	}
	&__cards-count-on-page {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 14px;
	}
	&__pagination {
		float: right;
	}
	&__pagination-item {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		color: black;
		.museo();
		font-size: 14px;
		margin-right: 21px;
		&:last-child {
			margin-right: 0;
		}
		&_active {
			color: rgba(0, 0, 0, 0.2);
		}
		&:hover {
			color: rgba(0, 0, 0, 0.2);
		}
	}
}

@media screen and (max-width: 1000px) {
	.collections-list {

		&__item {
			width: calc(~"(100% - 20px) / 2");
			&:nth-child(3n) {
				margin-right: 20px - 4px;
			}
			&:nth-child(2n) {
				margin-right: -4px;
			}
		}

	}
}

@media screen and (max-width: 815px) {
	.collections-list {

		&__title {
			display: block;
		}
	}
}

@media screen and (max-width: 530px) {
	.collections-list {

		&__item {
			width: 100%;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}

		&__button {
			display: block;
		}
		&__bottom-line {
			padding-left: 20px;
			padding-right: 20px;
		}
		&__cards-count-on-page {
			float: none;
			display: block;
			text-align: center;
		}
		&__pagination {
			display: none;
		}

	}
}