.community-list {
	margin-bottom: 20px;
	margin-top: 19px;

	&__title {
		.museobold();
		font-size: 24px;
		margin-bottom: 20px;
		display: none;
		text-align: center;
		&_visible {
			text-align: left;
			display: block;
			padding-bottom: 51px;
			border-bottom: 1px solid @border-color;
			font-size: 42px;
			margin-top: 60px;
		}
	}
	&__top-line {
		display: block;
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__cards-quantity {
		float: left;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		margin-top: 11px;
	}
	&__sort-block {
		float: right;
		min-width: 165px;
	}
	&__sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__sort-block-select {
		width: 100%;
	}

	&__list {


	}
	&__item {
		padding-bottom: 30px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 30px;
		.community-list_collection & {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
	&__item-left-part {
		float: left;
		width: 50%;
		//padding-right: 80px;
		.box-sizing(border-box);
		padding-right: 30px;
	}
	&__person-image-wrap {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin-right: 30px - 4px;
		width: 150px;
		height: 150px;

	}
	&__person-image {
		display: inline-block;
		position: relative;
		width: 150px;
		height: 150px;
		border-radius: 50%;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
			z-index: 10;
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		&_no-image {
			.svg-icon(icon-unauth-mobile, 150px, 150px) !important;

		}
	}
	&__person-label {
		position: absolute;
		top: 124px;
		left: 50%;
		margin-left: -69px;
		text-align: center;
		background: url(../img/person-label-bg.png) no-repeat 50% 50%;
		background-size: contain;
		width: 137px;
		height: 26px;
		line-height: 26px;
		color: white;
		font-size: 14px;
		.museo();
		z-index: 20;
	}
	&__person-img {
		max-width: 100%;
		.community-list__person-image_no-image & {
			display: none;
		}
	}
	&__person-text {
		display: inline-block;
		vertical-align: top;
		width: calc(~"100% - 150px - 30px");
		margin-right: -4px;
	}
	&__person-country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 10px;
	}
	&__person-name-wrap {
		display: block;
		margin-bottom: 14px;
	}
	&__person-name {
		color: black;
		.museobold();
		font-size: 24px;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;

		}
	}

	&__person-info {
		margin-bottom: 20px;
	}
	&__followers {
		display: inline-block;
		vertical-align: middle;
		margin-right: 23px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 9px;
		}
	}
	&__followers-count {
		color: black;
		.museo();
		font-size: 13px;
	}
	&__followers-text {
		color: black;
		.museo();
		font-size: 13px;
	}
	&__cards {
		display: inline-block;
		vertical-align: middle;
		margin-right: 23px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 9px;
		}
	}
	&__cards-count {
		color: black;
		.museo();
		font-size: 13px;
	}
	&__cards-text {
		color: black;
		.museo();
		font-size: 13px;
	}
	&__thanks {
		display: inline-block;
		vertical-align: middle;
	}
	&__thanks-count {
		color: black;
		.museo();
		font-size: 13px;
	}
	&__thanks-text {
		color: black;
		.museo();
		font-size: 13px;
	}

	&__person-hashtags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 20px;
	}
	&__person-hashtags-item {
		color: rgba(0, 0, 0, 0.4);
		.medium();
		font-size: 12px;
		position: relative;
		line-height: 1.6;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 5px;
		&:after {
			content: ',';
			color: rgba(0, 0, 0, 0.4);
			.regular();
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	&__person-hashtags-item-hash {
		color: rgba(0, 0, 0, 0.4);
		.medium();
		font-size: 12px;
	}
	&__person-hashtags-item-tag {
		color: rgba(0, 0, 0, 0.4);
		.medium();
		font-size: 12px;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			text-decoration: underline;
		}
	}

	&__follow-block {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}
	&__follow-button {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
	}
	&__follower {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__follower-photo {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 5px;
	}
	&__follower-image-wrap {
		width: 24px;
		min-width: 24px;
		height: 24px;
		border: 2px solid white;
		overflow: hidden;
		border-radius: 50%;
		.box-sizing(border-box);
		&:nth-child(2) {
			margin-left: -5px;
		}
	}
	&__follower-img {
		max-width: 100%;
	}
	&__follower-text-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&__follower-name {
		text-decoration: none;
		font-size: 12px;
		.museo();
		color: @green-color;
		margin-right: 3px;
	}
	&__follower-text {
		font-size: 12px;
		.museo();
		color: black;
	}

	&__item-right-part {
		float: right;
		width: 50%;
		padding-right: 20px;
		.box-sizing(border-box);
		text-align: left;
	}
	&__card-item {
		width: calc(~"(100% - 17px * 2) / 3");
		margin-right: 17px - 4px;
		text-decoration: none;
		display: inline-block;
		vertical-align: top;
		&:last-child {
			margin-right: -4px;
		}
	}
	&__card-item-image {
		height: 117px;
		width: 150px;
		border-radius: 1.4px;
		margin-bottom: 14px;
		margin-left: auto;
		margin-right: auto;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		.community-list__card-item:hover & {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__card-item-country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		text-align: center;
		margin-bottom: 7px;
	}
	&__card-item-title {
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: black;
		.museobold();
		font-size: 14px;
		line-height: 1.45;
		text-align: center;
		padding-left: 11px;
		padding-right: 11px;
	}

	&__button {
		text-align: center;
		margin-bottom: 20px;
		display: none;
	}
}

@media screen and (max-width: 1345px) {
	.community-list {

		&__item-left-part {
			width: 60%;
		}
		&__item-right-part {
			width: 40%;
		}
		&__card-item {
			width: calc(~"(100% - 20px) / 2");
			&:nth-child(3) {
				display: none;
			}
			&:nth-child(2) {
				margin-right: -4px;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.community-list {

		&__item-left-part {
			width: 75%;
		}
		&__item-right-part {
			width: 25%;
		}
		&__card-item {
			width: 100%;
			margin-right: 0;
			&:nth-child(3) {
				display: none;
			}
			&:nth-child(2) {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 980px) {
	.community-list {

		&__item-left-part {
			width: 100%;
		}
		&__item-right-part {
			display: none;
		}
	}
}

@media screen and (max-width: 815px) {
	.community-list {
		&__title {
			display: block;
		}
	}
}

@media screen and (max-width: 530px) {
	.community-list {

		&__item {
			padding-bottom: 20px;
		}
		&__item-left-part {
			padding-right: 0;
		}
		&__person-image-wrap {
			width: 120px;
			height: 120px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		&__person-image {
			width: 120px;
			height: 120px;

			&_no-image {
				.svg-icon(icon-unauth-mobile, 120px, 120px) !important;

			}
		}

		&__person-label {
			top: 94px;
		}

		&__person-text {
			display: block;
			width: 100%;
			text-align: center;
		}
		&__person-name {
			padding-left: 20px;
			padding-right: 20px;
		}

	}
}

@media screen and (max-width: 500px) {
	.community-list {

		&__button {
			display: block;

		}
	}
}

@media screen and (max-width: 415px) {
	.community-list {

		&__item {
			padding-left: 15px;
			padding-right: 15px;
		}
		&__followers {
			margin-right: 10px;
			&:after {
				right: -8px;
			}
		}
		&__cards {
			margin-right: 10px;
			&:after {
				right: -8px;
			}
		}
		&__follow-button {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		&__follow-block {
			flex-direction: column;
			align-items: center;
		}
		&__follower {
			justify-content: center;
		}
		&__follower-text-wrap {
			width: 60%;
		}
	}
}