.card-item {
	height: 388px;
	background-color: white;
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.11);
	display: inline-block;
	vertical-align: top;
	width: 100%;
	.box-sizing(border-box);
	padding-top: 20px;
	text-decoration: none;
	text-align: center;
	position: relative;
	z-index: 10;

	&__img {
		display: block;
		width: 244px;
		height: 190px;
		border-radius: 2px;
		//width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 12px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: relative;
		z-index: 10;
		text-decoration: none;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		//.card-item:hover & {
		//	&:after {
		//		opacity: 1;
		//		visibility: visible;
		//	}
		//}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		.card-item_add-new & {
			margin-bottom: 40px;
		}
	}
	&__text-wrap {
		padding-left: 20px;
		padding-right: 20px;
	}
	&__text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		line-height: 1.5;
		text-align: center;
		.museo();
		margin-bottom: 14px;
		padding: 0 30px;
	}
	&__button-wrap {
		text-align: center;
		display: block;
	}
	&__country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 15px;
	}
	&__cards-title-wrap {

	}
	&__cards-title {
		color: black;
		.museobold();
		font-size: 20px;
		line-height: 1.3;
		margin-bottom: 45px;
		text-decoration: none;
		transition: all .3s ease;
		text-align: center;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__info-line {
		position: absolute;
		bottom: 16px;
		left: 18px;
		right: 18px;
	}
	&__author {
		float: left;
		max-width: 107px;
	}
	&__author-photo {
		display: inline-block;
		vertical-align: middle;
		margin-right: 2px;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		overflow: hidden;
		border-radius: 50%;
		text-decoration: none;
	}
	&__author-photo-img {
		max-width: 100%;
	}
	&__author-name {
		color: #56a789;
		.museo();
		font-size: 12px;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__said-thanks {
		float: right;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		text-align: right;
		margin-top: 8px;
	}
	&__button {
		display: inline-block;
		text-align: center;
		height: 34px;
		line-height: 34px;
		background: @green-color;
		border-radius: 3px;
		padding: 0 14px;
		border: 0;
		color: white;
		.museo();
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.6px;
		cursor: pointer;
		&:hover {
			background: @green-color-hover;
		}
		&_mobile {
			display: none;
			background-color: white;
			box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
			color: @green-color;
			&:hover {
				background: #fbfbfb;
			}
		}
	}
}

@media screen and (max-width: 550px) {
	.card-item {

		&_add-new {
			height: auto;
			padding-bottom: 20px;
		}
		&__img {
			.card-item_add-new & {
				display: none;
			}
		}
		&__text {
			margin-bottom: 0;
			padding: 0 10px;
		}
		&__button {
			display: none;
			&_mobile {
				display: inline-block;
				margin-bottom: 10px;
			}
		}
	}
}