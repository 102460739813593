.route-top {
	margin-top: 50px;
	margin-left: 20px;
	margin-right: 20px;
	padding-left: 20px;
	padding-bottom: 45px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);


	&__wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.route-top_resize & {
			flex-direction: column;
		}
	}
	&__left-part {
		width: 67%;

		.route-top_resize & {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__title-wrap {
		margin-bottom: 13px;
		display: flex;
		flex-direction: row;
	}
	&__progress {
		width: 44px;
		height: 44px;
		z-index: 20;
		position: relative;
		margin-right: 14px;

		canvas {
			width: 100%;
			height: 100%;
		}
		strong {
			font-size: 11px;
			.museo();
			color: #000000;

			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 15px;
			line-height: normal;
		}
	}
	&__title {
		font-size: 42px;
		.museobold();
		margin-right: 23px;
	}
	&__change {
		text-decoration: none;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		background: #ffffff;
		margin-right: 10px;
		position: relative;
		transition: all .3s ease;
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_link {
			&:after {
				.svg-icon('icon-edit-dark', 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
			}
		}
		&_add-bg {
			&:after {
				.svg-icon('icon-change-bg-dark', 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
			}
		}
		&:hover {
			background: #fbfbfb;
		}
	}

	&__tags-list {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&__tags-item {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		text-decoration: none;
		.transition(all .3s ease);
		margin-right: 5px;
		line-height: 1.43;

		&:hover {
			color: @green-color-hover;
		}
	}

	&__info {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
	}
	&__info-item {
		box-shadow: 1px 0 0 #ebebeb;
		padding: 10px 30px;
		text-align: center;

		&:nth-child(1) {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -10px;
				top: 0;
				bottom: 0;
				width: 10px;
				box-shadow: 1px 0 0 #ebebeb;
			}
		}
	}
	&__info-item-number {
		color: #000000;
		font-size: 18px;
		.museobold();
		margin-bottom: 10px;
	}
	&__info-item-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 14px;
		.regular();
	}

	&__dates-wrap {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}
	&__dates-item {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		&:first-child {
			margin-right: 11px;
			position: relative;
			&:after {
				content: '-';
				position: absolute;
				right: -5px;
				font-size: 14px;
				color: rgba(0, 0, 0, 0.4);
				.regular();
				top: 2px;
			}
		}
	}
	&__desc {
		font-size: 14px;
		color: black;
		line-height: 1.43;
		.regular();
		max-width: 63%;

		.route-top_watch-route & {
			max-width: none;
		}
	}
	&__serv-text {
		color: rgba(0, 0, 0, 0.4);
		.regular();
		font-size: 12px;
		font-style: italic;
	}

	&__right-part {
		width: 33%;
		display: flex;
		flex-direction: row;

		.route-top_resize & {
			width: 100%;
		}
	}
	&__button {
		&:first-child {
			margin-right: 10px;
		}
	}
	&__show-on-map {
		width: 100%;
		display: none;
		height: 42px;
		line-height: 42px;
		text-align: center;

		font-size: 14px;
		.museobold();
		color: @green-color;
		border-top: 1px solid #e7e7e7;
		text-decoration: none;
	}
}

@media screen and (max-width: 1240px) {
	.route-top {

		&__wrap {
			flex-direction: column;
			justify-content: space-between;

		}
		&__left-part {
			width: 100%;
			margin-bottom: 20px;
		}
		&__right-part {
			width: 100%;
		}
	}
}

@media screen and (max-width: 1030px) {
	.route-top {
		margin-top: 30px;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);

		&__title-wrap {
			margin-bottom: 9px;
			align-items: center;
			flex-wrap: wrap;
		}
		&__title {
			font-size: 24px;
			margin-right: 14px;
		}
		&__change {
			width: 30px;
			height: 30px;
			margin-right: 14px;
		}
		&__serv-text {
			font-style: normal;
		}
	}
}

@media screen and (max-width: 815px) {
	.route-top {
		&_watch-route {
			padding-bottom: 0;
		}
		&__right-part {
			margin-bottom: 20px;
		}
		&__desc {
			max-width: 100%;
		}
		&__show-on-map {
			display: block;
		}
	}
}

@media screen and (max-width: 560px) {
	.route-top {

		&_watch-route {
			padding-left: 0;
			padding-right: 0;
		}

		&__title-wrap {

			.route-top_watch-route & {
				padding-left: 18px;
				padding-right: 18px;
			}
		}
		&__tags-list {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__desc {
			.route-top_watch-route & {
				padding-left: 18px;
				padding-right: 18px;
			}
		}

		&__right-part {
			justify-content: center;
			flex-direction: row-reverse;
		}
		&__button {
			&:first-child {
				margin-right: 0;
			}
			&:nth-child(2) {
				margin-right: 10px;
			}
		}

		&__info {

		}

		&__info-item {
			padding: 10px 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&:nth-child(1) {
				width: 20%;
				&:before {
					display: none;
				}
			}
			&:nth-child(2) {
				width: 20%;
			}
			&:nth-child(3) {
				width: 30%;
			}
			&:nth-child(4) {
				width: 30%;
			}
		}
		&__info-item-number {
			font-size: 14px;
			.museobold();
		}
		&__info-item-text {
			font-size: 12px;
		}

	}
}