.copyright {
	background: white;
	padding: 40px;
	width: 500px;
	.box-sizing(border-box);
	&_mobile {
		width: auto;
		max-width: 415px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 18px;
		margin-bottom: 50px;
	}

	&__title {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 30px;
		.copyright_mobile & {
			margin-top: 14px;
			margin-bottom: 25px;
		}
	}
	&__button-block {
		text-align: right;
		.copyright_mobile & {
			margin-top: 30px;
		}
	}
	&__button {
		display: inline-block;
		vertical-align: middle;
		&:first-child {
			margin-right: 10px;
		}
	}
	&__button-submit {
		border: 0;
		height: 40px;
		background: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		padding: 0 50px;
		line-height: 40px;

		color: white;
		.museobold();
		letter-spacing: 1px;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;
		.transition(all .3s ease);
		&:hover {
			background: @green-color-hover;
		}
	}
}

@media screen and (max-width: 600px) {
	.copyright {


		&__title {
			font-size: 24px;
		}

		&__button {
			display: block;
			&:first-child {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
		&__button-submit {
			padding: 0;
			width: 100%;
		}
	}
}