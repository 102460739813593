.profile-top {
	margin-bottom: 35px;

	//Top part: image + avatar
	&__top-image {
		height: 278px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		position: relative;
		z-index: 10;
		.box-sizing(border-box);
		padding-left: 20px;
		padding-right: 20px;

		&_upload {
			background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%) !important;
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%) !important;
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%)  !important;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );


		}
	}
	&__manage {
		position: absolute;
		bottom: -20px;
		left: 0;
		right: 0;
		text-align: right;
	}
	&__change-image {
		display: inline-block;
		vertical-align: middle;
		.sprite(@icon-change-bg);
		margin-right: 10px;
		width: 40px;
		height: 40px;
		//background: #bc76a3;
		background: white;
		border-radius: 50%;
		.transition(background .3s ease);
		position: relative;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			//.sprite(@icon-change-bg);
			.sprite(@icon-change-bg-dark);
			margin-top: -@icon-change-bg-height / 2;
			margin-left: -@icon-change-bg-width / 2;
			z-index: 10;
		}
		&:hover {
			//background: #a6648e;
			background: #fbfbfb;
		}
	}
	&__edit {
		display: inline-block;
		vertical-align: middle;

		width: 40px;
		height: 40px;
		//background: #bc76a3;
		background: white;
		border-radius: 50%;
		.transition(background .3s ease);
		position: relative;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			//.sprite(@icon-edit);
			.sprite(@icon-edit-dark);
			margin-top: -@icon-edit-height / 2;
			margin-left: -@icon-edit-width / 2;
			z-index: 10;
		}
		&:hover {
			//background: #a6648e;
			background: #fbfbfb;
		}
	}
	&__container {
		text-align: center;
	}
	&__avatar-wrap {
		width: 218px;
		height: 218px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: -140px;
		position: relative;
		z-index: 20;
		margin-bottom: 20px;
	}
	&__avatar {
		width: 218px;
		height: 218px;
		border: 8px solid white;
		border-radius: 50%;
		overflow: hidden;
		.box-sizing(border-box);


		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.6);
			border-radius: 50%;
			opacity: 0;
			visibility: hidden;
			border: 8px solid white;
			.box-sizing(border-box);
			transition: all .3s ease;
		}

		.profile-top_my-profile & {
			&_uploaded {
				cursor: pointer;

				&:hover {
					&:after {
						opacity: 1;
						visibility: visible;
					}
				}

			}
		}
		&_upload {
			background-color: #f2f2f2;
			border: 8px solid white;
		}

	}
	&__avatar-img {
		max-width: 100%;
		width: 100%;
		height: 100%;
		display: none;
		position: relative;

		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.profile-top__avatar_uploaded & {
			display: block;
		}
		.profile-top__avatar_place & {
			display: block;
		}
	}
	&__avatar-link-delete {
		.svg-icon-param('icon-delete', 18px, 21px, '[fill: #ffffff]');
		position: absolute;
		bottom: 47px;
		left: 50%;
		margin-left: -10px;
		z-index: 20;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;

		.profile-top_my-profile .profile-top__avatar_uploaded:hover & {
			opacity: 1;
			visibility: visible;
		}
		&:hover {
			.svg-icon-param('icon-delete', 18px, 21px, '[fill]: #509B7F');
		}
	}
	&__avatar-link-change {
		color: white;
		font-size: 14px;
		.museo();
		text-decoration: none;
		text-align: center;

		position: absolute;
		top: 95px;
		left: 0;
		right: 0;
		z-index: 20;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;

		.profile-top_my-profile .profile-top__avatar_uploaded:hover & {
			opacity: 1;
			visibility: visible;
		}

		&:hover {
			color: @green-color-hover;
		}
	}
	&__avatar-link-upload {
		font-size: 14px;
		.museo();
		text-decoration: none;
		color: @green-color;
		top: 95px;
		left: 0;
		right: 0;
		position: absolute;
		display: none;
		&:hover {
			color: @green-color-hover;
		}
		.profile-top__avatar_upload & {
			display: block;
		}
	}
	&__avatar-label {
		position: absolute;
		top: 184px;
		left: 50%;
		margin-left: -119px;
		text-align: center;
		background: url(../img/person-label-bg-long.png) no-repeat 50% 50%;
		background-size: contain;
		width: 236px;
		height: 26px;
		line-height: 26px;
		color: white;
		font-size: 14px;
		.museo();
	}

	//Author line under avatar
	&__author-line {
		margin-top: -85px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__author-left-part {
		//float: left;
		margin-top: 20px;
	}

	&__visitors-block {
		margin-bottom: 20px;
	}
	&__visitors-photos {
		text-align: left;
	}
	&__visitors-photos-item {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		.box-sizing(border-box);
		border-radius: 50%;
		overflow: hidden;
		margin-left: -13px;
		&:first-child {
			margin-left: 0;
		}
	}
	&__visitors-photos-item-img {
		max-width:100%;
	}
	&__visitors-text-wrap {
		text-align: left;
	}
	&__visitors-name-wrap {
		margin-bottom: 5px;
	}
	&__visitors-name {
		.museo();
		font-size: 12px;
		color: @green-color;
		text-decoration: none;
		display: inline;
		position: relative;
		&:after {
			content: ',';
			.museo();
			font-size: 12px;
			color: @green-color;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	&__visitors-text {
		.museo();
		font-size: 12px;
		color: black;
		cursor: pointer;
		.transition(all .3s ease);
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__share-button {
		position: relative;
		text-align: left;
	}

	&__author-right-part {
		//float: right;
		text-align: right;
	}

	&__author-info {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.regular();
		margin-bottom: 22px;
	}
	&__author-contacts {
		//float: right;
	}
	&__author-contacts-item {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		margin-right: 10px;
		position: relative;
		border-radius: 50%;
		overflow: hidden;

		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -12px;
			margin-top: -12px;
		}
		&_1 {
			background-color: rgba(0, 0, 0, 0.2);
			&:after {
				.sprite(@icon-f);
				margin-top: -9px;
				margin-left: -4px;
			}
			&:hover {
				background-color: #446aa5;
			}
		}
		&_2 {
			background-color: rgba(0, 0, 0, 0.2);
			&:after {
				.sprite(@icon-t);
				margin-top: -5px;
				margin-left: -6px;
			}
			&:hover {
				background-color: #55acee;
			}
		}
	}

	&__contacts {
		position: absolute;
		right: 0;
		left: 0;
		top: 318px;
		//bottom: -105px;
		//width: 160px;
		//text-align: right;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	&__rewards-block {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: 240px;
	}
	&__contacts-wrap {
		//display: inline-block;
		//width: 180px;
		position: absolute;
		right: 30px;
		top: 0;
		text-align: left;
	}
	&__contacts-line {
		display: block;
		margin-bottom: 20px;
	}
	&__contacts-item {
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		margin-right: 6px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -12px;
			margin-left: -12px;
		}
		&_1 {
			background-color: rgba(0, 0, 0, 0.2);
			&:after {
				.sprite(@icon-f);
				margin-top: -9px;
				margin-left: -4px;
			}
			&:hover {
				background-color: #446aa5;
				//&:after {
				//	.sprite(@icon-contacts-1-hover);
				//}
			}
		}
		&_2 {
			background-color: rgba(0, 0, 0, 0.2);
			&:after {
				.sprite(@icon-t);
				margin-top: -5px;
				margin-left: -6px;
			}
			&:hover {
				background-color: #55acee;
				//&:after {
				//	.sprite(@icon-contacts-2-hover);
				//}
			}
		}
		&_3 {
			&:after {
				.sprite(@icon-contacts-3);
				border-radius: 50%;
			}
			&:hover {
				&:after {
					.sprite(@icon-contacts-3-hover);
				}
			}
		}
		&_4 {
			&:after {
				.sprite(@icon-contacts-4);
				border-radius: 50%;
			}
			&:hover {
				&:after {
					.sprite(@icon-contacts-4-hover);
				}
			}
		}
		&_5 {
			&:after {
				.sprite(@icon-contacts-5);
				border-radius: 50%;
			}
			&:hover {
				&:after {
					.sprite(@icon-contacts-5-hover);
				}
			}
		}
		&_6 {
			&:after {
				.sprite(@icon-contacts-6);
				border-radius: 50%;
			}
			&:hover {
				&:after {
					.sprite(@icon-contacts-6-hover);
				}
			}
		}
		&_7 {
			&:after {
				.sprite(@icon-contacts-7);
				border-radius: 50%;
			}
			&:hover {
				&:after {
					.sprite(@icon-contacts-7-hover);
				}
			}
		}
	}
	&__member-since {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
	}

	//Main information about place
	&__main-part {
		margin-top: -55px;
		width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
	&__country {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
		margin-bottom: 11px;
	}
	&__name {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 10px;
	}
	&__tags-line {
		margin-bottom: 20px;
		text-align: center;
		padding: 0 18px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__tags-item {
		display: flex;
		margin-right: 8px;
		flex-direction: row;
		align-items: center;
		margin-bottom: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
	&__tags-item-hash {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
	}
	&__tags-item-text {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		.regular();
		text-decoration: none;
		transition: all .2s ease;
		&:hover {
			text-decoration: underline;
		}
	}
	&__visits-info-block {
		margin-bottom: 20px;
	}
	&__been-here-block {
		display: inline-block;
		vertical-align: middle;
		margin-right: 23px;
		position: relative;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -15px;
			top: 9px;
		}
	}
	&__been-here-count {
		color: black;
		.museo();
		font-size: 13px;
		cursor: pointer;
		.transition(all .3s ease);
		.profile-top__been-here-block:hover & {
			color: @green-color-hover;
		}
	}
	&__been-here-text {
		color: black;
		.museo();
		font-size: 13px;
		cursor: pointer;
		.transition(all .3s ease);
		.profile-top__been-here-block:hover & {
			color: @green-color-hover;
		}
	}

	&__want-to-visit-block {
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}
	&__want-to-visit-count {
		color: black;
		.museo();
		font-size: 13px;
		cursor: pointer;
		.transition(all .3s ease);
		.profile-top__want-to-visit-block:hover & {
			color: @green-color-hover;
		}
	}
	&__want-to-visit-text {
		color: black;
		.museo();
		font-size: 13px;
		cursor: pointer;
		.transition(all .3s ease);
		.profile-top__want-to-visit-block:hover & {
			color: @green-color-hover;
		}
	}

	&__button-block {
		margin-bottom: 26px;
	}
	&__button {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
	}

	&__person-info {
		margin-bottom: 30px;
	}
	&__followers {
		display: inline-block;
		vertical-align: middle;
		margin-right: 23px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 9px;
		}
	}
	&__followers-count {
		color: black;
		.museobold();
		font-size: 14px;
		.transition(al .3s ease);
		cursor: pointer;
		.profile-top__followers:hover & {
			color: @green-color-hover;
		}
	}
	&__followers-text {
		color: black;
		.museobold();
		font-size: 14px;
		.transition(al .3s ease);
		cursor: pointer;
		.profile-top__followers:hover & {
			color: @green-color-hover;
		}
	}
	&__places {
		display: inline-block;
		vertical-align: middle;
		margin-right: 23px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			right: -14px;
			top: 9px;
		}
	}
	&__places-count {
		color: black;
		.museobold();
		font-size: 14px;
	}
	&__places-text {
		color: black;
		.museobold();
		font-size: 14px;
	}
	&__thanks {
		display: inline-block;
		vertical-align: middle;
	}
	&__thanks-count {
		color: black;
		.museobold();
		font-size: 14px;
	}
	&__thanks-text {
		color: black;
		.museobold();
		font-size: 14px;
	}
	&__person-follow {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
	}

	&__text {
		.regular();
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		color: black;
		text-align: center;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	&__text-link {
		.regular();
		font-size: 14px;
		color: @green-color;
		text-decoration: none;
	}

	&__serv-bottom {
		display: none;
	}
	&__serv-bottom-text {
		border-top: 1px solid #ebebeb;
		padding-top: 20px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
		text-align: center;
		margin-bottom: 10px;
	}
	&__rewards-line {
		text-align: center;
		margin-bottom: 23px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 240px;
		margin-left: auto;
		margin-right: auto;
	}
	&__rewards-item {
		width: 40px;
		height: 40px;
		margin-right: 10px;
		position: relative;
		margin-bottom: 12px;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		cursor: pointer;

		&:nth-child(5n) {
			margin-right: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__rewards-item-count {
		position: absolute;
		right: 0;
		bottom: 0;

		color: black;
		.museo();
		font-size: 10px;
		background: white;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
		border-radius: 50%;
		z-index: 30;
		height: 17px;
		width: 17px;
		text-align: center;
		line-height: 17px;
	}
	&__rewards-item-balloon {
		text-align: center;
		position: absolute;
		bottom: -30px;
		left: -20px;
		//right: -20px;
		width: auto;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		.museobold();
		font-size: 14px;
		background: white;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		white-space: nowrap;
		opacity: 0;
		visibility: hidden;
		z-index: 20;

		.profile-top__rewards-item:hover & {
			opacity: 1;
			visibility: visible;
		}
	}
}

@media screen and (max-width: 900px) {
	.profile-top {

		&__author-line {
			display: none;
		}
		&__rewards-block {
			display: none;
		}
		&__contacts-wrap {
			display: none;
		}
		&__serv-bottom {
			display: block;
		}

		&__visitors-photos {
			text-align: center;
		}
		&__visitors-text-wrap {
			text-align: center;
		}
		&__share-button {
			display: inline-block;
			text-align: center;
			margin-bottom: 26px;
		}

		&__main-part {
			margin-top: 0;
			width: 100%;
		}
	}
}

@media screen and (max-width: 750px) {
	.profile-top {
		margin-bottom: 35px;
		//overflow: hidden;

		&__manage {
			bottom: auto;
			top: 20px;
			right: 20px;
		}
		&__avatar-wrap {
			width: 150px;
			height: 150px;
			margin-top: -100px;
			margin-bottom: 20px;
		}
		&__avatar {
			width: 150px;
			height: 150px;
		}
		&__avatar-label {
			position: absolute;
			top: 136px;
		}
		&__avatar-link-delete {
			bottom: 25px;
		}
		&__avatar-link-change {
			top: 60px;
		}

		&__text {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 415px) {
	.profile-top {

		&__person-info {
			padding-left: 18px;
			padding-right: 18px;
		}
		&__text {
			padding-left: 18px;
			padding-right: 18px;
		}

	}
}