.tabs {
	&__wrapper {

	}
	&__item {
		display: none;
		&_active {
			display: block;
		}
	}

	&__about {
		margin-top: 40px;
	}
	&__about-contacts {
		float: right;
		width: 31%;
	}
	&__about-contacts-top {
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
	&__about-contacts-title {
		display: block;
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 35px;
	}
	&__about-contacts-text-wrap {

	}
	&__about-contacts-line {
		display: block;
		margin-bottom: 14px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__about-contacts-key {
		color: rgba(0, 0, 0, 0.4);
		.medium();
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		margin-right: 40px - 4px;
	}
	&__about-contacts-value {
		.regular();
		font-size: 14px;
		color: black;
		line-height: 20px;
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 20% - 40px");
		margin-right: -4px;
		&_site {
			color: @green-color;
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
		}
		&_phone {
			a {
				color: black;
				text-decoration: none;
			}
		}
	}
	&__about-time {

	}
	&__about-time-title {
		display: block;
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}
	&__about-time-sort-block {
		width: 200px;
		margin-bottom: 20px;
		margin-left: -14px;
	}
	&__about-time-table {
		padding-bottom: 31px;
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 20px;
	}
	&__about-time-line {
		margin-bottom: 10px;
		&_holiday {
			margin-top: 20px;
		}
	}
	&__about-time-key {
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		margin-right: 55px - 4px;

		color: black;
		font-size: 14px;
		.medium();
		.tabs__about-time-line_holiday & {
			vertical-align: top;
			line-height: 1.43;
		}
	}
	&__about-time-value {
		display: inline-block;
		vertical-align: middle;
		color: black;
		font-size: 14px;
		.regular();
		width: calc(~"100% - 20% - 55px");
		margin-right: -4px;
		.tabs__about-time-line_holiday & {
			line-height: 1.43;
			vertical-align: top;
		}
	}
	&__about-time-request-info {
		font-size: 14px;
		.museobold();
		color: @green-color;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__about-desc {
		float: left;
		width: calc(~"69% - 35px");
		margin-right: 35px;
		.box-sizing(border-box);
		//padding-right: 35px;
		border-right: 2px solid @border-color;
		padding-right: 50px;
	}
	&__about-desc-wrap {

	}
	&__about-desc-title {
		display: block;
		color: black;
		.museobold();
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 25px;
	}
	&__about-desc-text-wrap {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 40px;
		}
	}
	&__about-desc-text {
		color: black;
		.regular();
		font-size: 16px;
		line-height: 1.43;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__about-desc-show-more {
		font-size: 14px;
		text-decoration: none;
		color: @green-color;
		.museo();
		display: none;
	}

	&__cards {
		margin-bottom: 15px;
	}
	&__cards-top-line {
		height: 50px;
		border-bottom: 1px solid @border-color;
		.mobile-page & {
			height: auto;
		}
	}
	&__cards-search-block {
		float: left;
		margin-top: 23px;
		margin-left: 10px;
		width: 50%;
		display: flex;
		flex-direction: row;
		.mobile-page & {
			display: block;
			float: none;
			border-bottom: 1px solid @border-color;
			padding-left: 18px;
			padding-bottom: 15px;
		}
	}
	&__cards-search-block-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-search, 14px, 14px);
		//background: url(../img/src/icon-search.svg) no-repeat 0 0;
		//background-size: 14px 14px;
		//width: 14px;
		//height: 14px;
		margin-right: 20px;
	}
	&__cards-search-input-holder {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		width: calc(~"100% - 14px - 20px");
	}
	&__cards-search-input {
		border: 0;
		color: black;
		.museo();
		font-size: 14px;
		width: 100%;

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}

		.header_transparent & {
			color: white;
			background: transparent;
		}
	}
	&__cards-sort-block-wrap {
		float: right;
		margin-top: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.mobile-page & {
			padding-right: 18px;
			margin-top: 9px;
		}
	}
	&__cards-sort-block-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
	&__cards-sort-block {

	}
	&__cards-sort-text {
		.museo();
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
	&__cards-sort-block-select {
		width: 100%;
	}
	&__cards-list {
		display: block;
		margin-bottom: 40px;
		margin-top: 1px;
	}
	&__cards-item {
		display: inline-block;
		vertical-align: top;
		width: 20%;
		margin-right: -4px;
		padding-right: 4px;
		.box-sizing(border-box);
		margin-bottom: 1px;
	}
	&__cards-list-title-wrap {
		margin-bottom: 20px;
		margin-left: 37px;
	}
	&__cards-list-title {
		color: black;
		.museobold();
		font-size: 30px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 3px;
	}
	&__cards-list-title-tag-wrap {
		display: inline-block;
		vertical-align: middle;
	}
	&__cards-list-title-tag {
		color: black;
		.museobold();
		font-size: 30px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 4px;
	}
	&__cards-list-cards-count {
		color: rgba(0, 0, 0, 0.2);
		.museo();
		font-size: 14px;
		display: inline-block;
		vertical-align: top;
	}
	&__cards-button-wrapper {
		text-align: center;
		//margin-bottom: 12px;
	}
	&__cards-item-count-wrap {
		text-align: center;
		display: none;
	}
	&__cards-item-current-count {
		display: inline-block;
		vertical-align: middle;

		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
	}
	&__cards-item-count-span {
		display: inline-block;
		vertical-align: middle;

		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
	}
	&__cards-item-total-count {
		display: inline-block;
		vertical-align: middle;

		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.museo();
	}

	&__collection {
		margin-bottom: 94px - 52px;
	}
	&__collection-top-line {
		height: 50px;
		border-bottom: 1px solid @border-color;
		margin-bottom: 21px;
		.mobile-page & {
			height: auto;
		}
	}
	&__collection-search-block {
		float: left;
		margin-top: 23px;
		width: 50%;
		display: flex;
		flex-direction: row;
		.mobile-page & {
			display: block;
			float: none;
			border-bottom: 1px solid @border-color;
			padding-left: 18px;
			padding-bottom: 15px;
		}
	}
	&__collection-search-block-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-search, 14px, 14px);
		//background: url(../img/src/icon-search.svg) no-repeat 0 0;
		//background-size: 14px 14px;
		//width: 14px;
		//height: 14px;
		margin-right: 20px;
	}
	&__collection-search-input-holder {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		width: calc(~"100% - 14px - 20px");
	}
	&__collection-search-input {
		width: 100%;
		border: 0;
		color: black;
		.museo();
		font-size: 14px;

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}

		.header_transparent & {
			color: white;
			background: transparent;
		}
	}
	&__collection-sort-block-wrap {
		float: right;
		margin-top: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.mobile-page & {
			padding-right: 18px;
			margin-top: 9px;
		}
	}
	&__collection-sort-block-text {
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 14px;
	}
	&__collection-sort-block-select {
		width: 100%;
	}
	&__collection-sort-block {

	}

	&__collection-list {

	}
	&__collection-item {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px - 4px;
		width: calc(~"(100% - 20px * 3) / 4");
		margin-bottom: 52px;
		text-decoration: none;
		&:nth-child(4n) {
			margin-right: -4px;
		}
		&:last-child {
			margin-right: -4px;
		}
		.mobile-page__item & {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
			&:nth-child(4n) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&_add-new {
			text-align: center;
		}
	}
	&__collection-rout-days {
		color: white;
		.museo();
		font-size: 12px;
		background: @green-color;
		border-radius: 12px;
		padding: 0 10px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		position: absolute;
		right: 10px;
		top: 15px;
	}
	&__collection-item-image {
		width: 100%;
		height: 200px;
		margin-bottom: 16px;
		position: relative;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: -moz-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%, rgba(30,83,136,0.64) 100%);
			background: -webkit-linear-gradient(-45deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			background: linear-gradient(135deg,  rgba(30,83,136,0.1) 0%,rgba(30,83,136,0.64) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e5388', endColorstr='#a31e5388',GradientType=1 );

			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		.tabs__collection-item_add-new & {
			background-image: url(../img/add-new-card-bg.jpg);
		}
		.tabs__collection-item:hover & {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__collection-item-add-new {
		display: inline-block;
		text-decoration: none;
		cursor: pointer;
		background: @green-color;
		color: white;

		text-transform: uppercase;
		text-align: center;
		font-size: 14px;
		height: 34px;
		letter-spacing: 1px;
		line-height: 38px;
		padding-left: 14px;
		padding-right: 14px;
		.museobold();
		outline: none;
		border-radius: 2px;
		.transition(all .3s ease);

		&:hover {
			background: @green-color-hover;
		}
	}
	&__collection-place-position {
		color: white;
		.museo();
		font-size: 12px;
		position: absolute;
		left: 20px;
		bottom: 20px;
		max-width: 71px;
	}
	&__collection-item-title {
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: black;
		.museobold();
		font-size: 22px;
		line-height: 1.2;
		text-align: center;
		padding-left: 23px;
		padding-right: 23px;
		margin-bottom: 10px;
		.transition(all .3s ease);
		&:hover {
			color: @green-color-hover;
		}
	}
	&__collection-item-visited {
		text-align: center;
		text-decoration: none;
		color: rgba(0, 0, 0, 0.4);
		font-size: 11px;
		.museo();
	}
	&__collection-item-progress {
		width: 44px;
		height: 44px;
		position: absolute;
		right: 16px;
		bottom: 16px;
		z-index: 20;

		canvas {
			width: 100%;
			height: 100%;
		}
		strong {
			font-size: 11px;
			.museo();
			color: white;

			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 15px;
			line-height: normal;
		}
	}
	&__collection-item-progress-text {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		.regular();
		text-align: center;
	}

	&__map-block {

	}
	&__map-block-top-line {
		padding-top: 18px;
		padding-bottom: 14px;

		.mobile-page & {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
	&__map-top-line-container {

	}
	&__map-search-block {
		display: flex;
		flex-direction: row;
	}
	&__map-search-block-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-search, 14px, 14px);
		//background: url(../img/src/icon-search.svg) no-repeat 0 0;
		//background-size: 14px 14px;
		//width: 14px;
		//height: 14px;
		margin-right: 20px;
	}
	&__map-search-input-holder {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 14px - 20px");
	}
	&__map-search-input {
		border: 0;
		color: black;
		.museo();
		font-size: 14px;
		width: 100%;

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
		}
	}
	&__map-wrap {
		width: 100%;
		position: relative;
		//height: 850px;
		height: 80vh;
		&_mobile-page {
			height: 400px !important;
		}
	}
	&__map {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&__map-itself {
		height: 100%;
		width: 100%;
	}
	&__map-nav {
		position: absolute;
		top: 11px;
		right: 10px;
		background: white;
	}
	&__map-nav-item {
		display: block;
		padding: 13px 10px;
		min-width: 220px;
		.box-sizing(border-box);
		cursor: pointer;
	}
	&__map-nav-item-left-part {
		float: left;
	}
	&__map-nav-item-checkbox {
		display: inline-block;
		vertical-align: middle;
		width: 14px;
		height: 14px;
		border: 1px solid @border-color;
		.box-sizing(border-box);
		border-radius: 3px;
		margin-right: 10px;
		.tabs__map-nav-item_active & {
			.sprite(@icon-checkbox);
			border: 0;
		}
	}
	&__map-nav-item-icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		width: 16px;
		height: 16px;
		.box-sizing(border-box);
		border-radius: 50%;
		&_been-here {
			border: 4px solid rgba(86, 167, 137, 0.4);
			.tabs__map-nav-item_active & {
				border: 4px solid #56a789;
			}
		}
		&_want-to-visit {
			border: 4px solid rgba(188, 118, 163, 0.4);
			.tabs__map-nav-item_active & {
				border: 4px solid #bc76a3;
			}
		}
	}
	&__map-nav-item-text {
		display: inline-block;
		vertical-align: middle;
		color: rgba(0, 0, 0, 0.2);
		.museo();
		font-size: 12px;
		letter-spacing: 0.6px;
		.tabs__map-nav-item_active & {
			color: black;
		}
	}
	&__map-nav-item-count {
		color: rgba(0, 0, 0, 0.2);
		.museo();
		font-size: 10px;
		float: right;
		margin-top: 4px;
		.tabs__map-nav-item_active & {
			color: black;
		}
	}

}

@media screen and (max-width: 1330px) {
	.tabs {
		&__cards-item {
			width: 25%;
		}
	}
}

@media screen and (max-width: 1100px) {
	.tabs {

		&__cards-item {
			width: 33.333%;
		}
	}
}

@media screen and (max-width: 1030px) {
	.tabs {
		&__about {
			margin-top: 20px;
		}
		&__about-contacts {
			float: none;
			width: 100%;
			margin-bottom: 30px;
		}
		&__about-contacts-title {
			margin-bottom: 30px;
		}
		&__about-contacts-line {
			margin-bottom: 10px;
		}
		&__about-desc {
			float: none;
			margin-right: 0;
			width: 100%;
			border-right: 0;
			padding-right: 0;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 20px;
			margin-bottom: 30px;
		}

		&__collection-item {
			width: calc(~"(100% - 20px * 2) / 3");
			&:nth-child(4n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: -4px;
			}
		}

		&__map-wrap {
			.box-sizing(border-box);
			margin-left: 2%;
			margin-right: 2%;
			width: 96%;
			//height: 600px;
			height: 70vh;
		}
	}
}

@media screen and (max-width: 840px) {
	.tabs {
		&__cards-item {
			width: 50%;
		}

	}
}

@media screen and (max-width: 750px) {
	.tabs {

		&_my-profile {
			display: none;
		}
		&__cards {

			&_my-profile {
				display: none;
			}
		}
		&__cards-list-title {
			font-size: 24px;
		}
		&__cards-list-title-tag {
			font-size: 24px;
		}
		&__collection {
			&_my-profile {
				display: none;
			}
		}
		&__collection-item {
			width: calc(~"(100% - 20px) / 2");
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(2n) {
				margin-right: -4px;
			}
		}
		&__map-block {
			//display: none;
		}
	}
}

@media screen and (max-width: 550px) {
	.tabs {

		&__about-desc-wrap {
			height: 195px;
			overflow: hidden;
			margin-bottom: 16px;
			.transition(height .3s ease);
			text-overflow: ellipsis;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 30px;
				background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

			}
			&_open {
				height: auto;
				&:after {
					display: none;
				}
			}
		}
		&__about-desc-text-wrap {
			&:last-child {
				padding-bottom: 0;
			}
		}
		&__about-desc-show-more {
			display: inline-block;
		}
		&__about-time-sort-block {
			margin-left: 0;
		}


		&__cards-list-title {
			display: block;
			margin-bottom: 7px;
		}
		&__cards-list-title-tag-wrap {
			display: block;
		}


		&__cards-item {
			width: 100%;
			margin-right: 0;
		}
		&__collection-item {
			width: 100%;
			.box-sizing(border-box);
			padding-left: 2px;
			padding-right: 2px;
			margin-bottom: 20px;
			margin-right: 0;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media screen and (max-width: 460px) {
	.tabs {

		&__cards {
			margin-bottom: 20px;
		}
		&__cards-container {
			padding-left: 18px;
			padding-right: 8px;
		}
		&__about-contacts-key {
			width: 30%;
			margin-right: 30px - 4px;
		}
		&__about-contacts-value {
			width: calc(~"100% - 30% - 30px");
		}

		&__cards-list-title-wrap {
			margin-left: 20px;
			margin-bottom: 10px;
		}

		&__cards-button-wrapper {
			margin-bottom: 12px;
		}
		&__cards-item-count-wrap {
			display: block;
		}
	}
}

@media screen and (max-width: 415px) {
	.tabs {

		&__about-contacts-line {
			margin-bottom: 15px;
		}
		&__about-contacts-key {
			width: 100%;
			display: block;
			margin-bottom: 6px;
			text-align: left;
			margin-right: 0;
			position: relative;
			&:after {
				content: ':';
				color: rgba(0, 0, 0, 0.4);
				.regular();
				font-size: 14px;
			}
		}
		&__about-contacts-value {
			width: 100%;
			text-align: left;
		}


		&__cards-top-line {
			height: auto;
			border-bottom: 1px solid @border-color;
			.mobile-page & {
				height: auto;
			}
		}
		&__cards-search-block {
			//display: block;
			width: 100%;
			margin-left: 0;
			margin-top: 0;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
			border-bottom: 1px solid @border-color;
		}
		&__cards-sort-block {
			margin-top: 0;
			padding-right: 6px;
			padding-top: 3px;
			//padding-bottom: 3px;
		}

		&__collection-top-line {
			height: auto;
			border-bottom: 1px solid @border-color;
			.mobile-page & {
				height: auto;
			}
		}
		&__collection-search-block {
			//display: block;
			width: 100%;
			margin-left: 0;
			margin-top: 0;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 18px;
			padding-right: 18px;
			.box-sizing(border-box);
			border-bottom: 1px solid @border-color;
		}
		&__collection-sort-block {
			margin-top: 0;
			padding-right: 6px;
			padding-top: 3px;
			//padding-bottom: 3px;
		}

		&__map-block-top-line {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}