.unauth-mobile-menu {
	width: 240px;
	position: absolute;
	right: -240px;
	top: 57px;
	background: white;
	box-shadow: 0 -1px 0 #f2f2f2;
	height: 100%;
	z-index: 300;
	.transition(all .2s ease);

	&.active {
		right: 0;
	}

	&__list {

	}
	&__item {
		height: 50px;
		background-color: white;
		border-bottom: 1px solid @border-color;
		display: block;
		.box-sizing(border-box);
		padding-top: 17px;
		padding-left: 20px;
	}
	&__item-link {
		color: black;
		.museobold();
		font-size: 14px;
		text-decoration: none;
	}
}