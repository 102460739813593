.ask-question {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 18px;
	padding-right: 18px;
	margin-top: 12px;

	&__form {

	}

	&__button-block {

	}
	&__button {
		height: 40px;
		line-height: 40px;
		background: @green-color;
		border-radius: 2px;
		color: white;
		font-size: 14px;
		letter-spacing: 1px;
		.museo();
		text-transform: uppercase;
		width: 100%;
		border: 0;
		cursor: pointer;
		margin-bottom: 20px;
		text-align: center;
		&:hover {
			background: @green-color-hover;
		}
		&_cancel {
			display: block;
			box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
			letter-spacing: 0.6px;
			color: black;
			background: white;
			font-size: 12px;
			text-decoration: none;
			margin-bottom: 0;
			&:hover {
				background: #fbfbfb;
			}
		}
	}

	&__textarea-feedback {
		position: absolute;
		right: 0;
		top: -20px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
		font-size: 12px;
	}

	&__attach-block {
		margin-bottom: 20px;
	}
	&__attach-icon {
		display: inline-block;
		vertical-align: middle;
		.svg-icon(icon-attach-green, 10px, 13px);
		.ask-question__attach-block_active & {
			.svg-icon(icon-attach-grey, 10px, 13px);
		}
	}
	&__attach-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		.medium();
		color: @green-color;
		text-decoration: none;
		.ask-question__attach-block_active & {
			color: rgba(0, 0, 0, 0.4);
		}
		&:hover {
			color: rgba(0, 0, 0, 0.4);
		}
	}
	&__attach-desc {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		.regular();
		color: rgba(0, 0, 0, 0.4);
	}
	&__photo-block {
		margin-bottom: 30px;
	}
	&__photo-item {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px - 4px;
		width: calc(~"(100% - 20px) / 2");
		overflow: hidden;
		margin-bottom: 18px;
		&:nth-child(2n) {
			margin-right: -4px;
		}
	}
	&__photo-item-img {
		border-radius: 1.4px;
		height: 100px;
		width: 100%;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		margin-bottom: 10px;
		position: relative;
		cursor: pointer;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			.transition(all .3s ease);
		}
		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__photo-item-img-delete {
		position: absolute;
		top: 50%;
		left: 50%;
		.svg-icon(icon-delete, 18px, 21px);
		margin-left: -9px;
		margin-top: -10px;
		opacity: 0;
		visibility: hidden;
		z-index: 10;
		.transition(all .3s ease);
		.ask-question__photo-item-img:hover & {
			opacity: 1;
			visibility: visible;
		}
	}
	&__photo-item-name {
		color: black;
		font-size: 14px;
		.medium();
		text-align: center;
	}

}