.mobile-page {
	max-width: 415px;
	//margin: 20px auto;
	margin-left: auto;
	margin-right: auto;

	&__return-link {
		display: inline-block;
		margin-left: 18px;
		margin-top: 18px;
		margin-bottom: 26px;
		background: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		height: 30px;
		padding-left: 13px;
		padding-right: 13px;
		text-decoration: none;
		line-height: 30px;
		&:hover {
			background: #fbfbfb;
		}
	}
	&__return-link-icon {
		display: inline-block;
		vertical-align: middle;
		//.sprite(@icon-slider-arrow);
		.svg-icon(icon-arrow-black, 11px, 9px);
		margin-right: 9px;
	}
	&__return-link-text {
		display: inline-block;
		vertical-align: middle;
		color: black;
		.museo();
		font-size: 14px;
	}

	&__title {
		.museobold();
		font-size: 24px;
		text-align: center;
		line-height: 1.2;
		padding-bottom: 17px;
		border-bottom: 1px solid #e7e7e7;
		margin-bottom: 20px;
		&_map {
			margin-top: 25px;
			padding-bottom: 25px;
			margin-bottom: 0;
		}
		&_stat {
			margin-top: 25px;
			margin-bottom: 28px;
		}
		&_cards {
			padding-bottom: 23px;
			margin-bottom: 0;
			margin-top: 27px;
		}
		&_special {
			border-bottom: 0;
		}
	}

	&__list {
		padding-left: 18px;
		padding-right: 18px;
		margin-bottom: 30px;
	}
	&__item {
		width: 100%;
	}

	&__show-more {
		text-align: center;
		margin-top: -47px + 19px;
		margin-bottom: 48px;
	}

	&__map {
		height: 65vh;
		position: relative;
	}
	&__map-section {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__map-itself {
		width: 100%;
		height: 100%;
	}

	&__button {
		text-align: center;
		margin-bottom: 40px;
	}
}