.mobile-menu {
	width: 240px;
	position: absolute;
	left: -240px;
	top: 64px;
	background: white;
	height: calc(~"100% - 64px");
	//height: 400px;
	padding-bottom: 14px;
	.box-sizing(border-box);
	z-index: 300;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	.transition(all .2s ease);

	&.active {
		left: 0;
	}

	&__search-line {
		height: 50px;
		background-color: white;
		border-bottom: 1px solid @border-color;
		display: block;
		.box-sizing(border-box);
		padding-top: 17px;
		padding-left: 20px;
		padding-right: 10px;

	}
	&__search-wrap {
		float: left;
		max-width: 130px;
		overflow: hidden;
		.box-sizing(border-box);
		padding-left: 14px + 10px;
	}
	&__search-input-holder {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: -22px;
			top: 2px;
			background: url(../img/src/icon-search.svg) no-repeat 0 0;
			background-size: 14px 14px;
			width: 14px;
			height: 14px;
		}
	}
	&__search-input {
		border: 0;
		color: black;
		.museo();
		font-size: 14px;
		width: 100%;

		&::-moz-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&::-webkit-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
		&:-ms-input-placeholder {
			opacity: 1;
			color: rgba(0, 0, 0, 0.2);
			.header_transparent & {
				color: white;
			}
		}
	}

	&__show-tags {
		float: right;
		color: @green-color;
		.museo();
		font-size: 12px;
		text-decoration: none;
		margin-top: 3px;
	}
	&__list {
		padding-bottom: 136px;
		background: white;
	}
	&__item {
		height: 50px;
		background-color: white;
		border-bottom: 1px solid @border-color;
		display: block;
		.box-sizing(border-box);
		padding-top: 17px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__item-icon {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&_cards {
			&:after {
				.sprite(@icon-menu-cards);
				margin-top: -@icon-menu-cards-height / 2;
				margin-left: -@icon-menu-cards-width / 2;
			}
		}
		&_places {
			&:after {
				.svg-icon(icon-menu-places, 12px, 16px);
				margin-top: -8px;
				margin-left: -6px;
			}
		}
		&_collections {
			&:after {
				.svg-icon(icon-menu-collections, 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
			}
		}
		&_map {
			&:after {
				.svg-icon(icon-menu-map, 14px, 14px);
				margin-top: -7px;
				margin-left: -7px;
			}
		}
		&_community {
			&:after {
				.sprite(@icon-menu-community);
				margin-top: -@icon-menu-community-height / 2;
				margin-left: -@icon-menu-community-width / 2;
			}
		}
	}
	&__item-link {
		display: inline-block;
		vertical-align: -1px;
		color: black;
		.museobold();
		font-size: 14px;
		text-decoration: none;
	}

	&__lang-list {

	}
	&__lang-item {
		padding-left: 20px;
		padding-right: 20px;
		position: relative;
		.box-sizing(border-box);
		height: 67px;
		background-color: white;
		box-shadow: 0 -1px 0 #f2f2f2;
		padding-top: 17px;
		padding-bottom: 14px;

		.mobile-menu__lang-list & {
			&:after {
				content: '';
				position: absolute;
				right: 16px;
				top: 33px;
				.sprite(@icon-lang-mobile-caret);
			}
		}
	}
	&__lang-item-name {
		color: @green-color;
		font-size: 14px;
		.museobold();
		margin-bottom: 6px;
	}
	&__lang-item-info {
		display: block;
	}
	&__lang-item-count {
		display: inline-block;
		vertical-align: middle;
		font-size: 10px;
		color: black;
		.museo();
	}
	&__lang-item-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 10px;
		color: rgba(0, 0, 0, 0.4);
		.museo();
	}

	&__lang-dropdown {
		display: none;
	}

	@media (min-width: 600px) {
		height: auto;
	}
}