.card-error {
	background: white;
	padding: 30px 40px;
	width: 500px;
	.box-sizing(border-box);
	&_mobile {
		max-width: 415px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 18px;
		width: auto;
	}

	&__container {
		margin-top: 10px;
	}

	&__title {
		color: black;
		.museobold();
		font-size: 30px;
		margin-bottom: 20px;
	}
	&__button-block {
		display: flex;
		flex-direction: row;
		align-items: center;
		&_right {
			justify-content: flex-end;
		}
		.card-error_mobile & {
			flex-direction: column-reverse;
		}
	}
	&__button {
		width: calc(~"100% - 30% - 10px");
		&:first-child {
			margin-right: 10px;
			width: 30%;
			min-width: 30%;
		}
		.card-error_mobile & {
			display: block;
			width: 100%;
			margin-bottom: 20px;
			&:first-child {
				margin-right: 0;
				width: 100%;
			}
		}
	}

	&__report-button {
		width: 100%;
		text-align: center;
		height: 40px;
		background: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;
		color: white;
		.museobold();
		letter-spacing: 1px;
		font-size: 14px;
		text-transform: uppercase;
		.transition(background .3s ease);
		border: 0;
		cursor: pointer;
		text-decoration: none;
		&:hover {
			background: @green-color-hover;
		}
	}
	&__content {

	}
	&__photo-block {
		float: left;
		width: 180px;
		height: 180px;
		border-radius: 2px;
		margin-right: 30px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
	}
	&__text-block {
		margin-left: 180px + 30px;
	}
	&__text-block-title {
		font-size: 14px;
		color: black;
		.museo();
		margin-bottom: 16px;
	}
	&__text-block-text {
		.regular();
		font-size: 14px;
		color: black;
		line-height: 1.25;
	}
	&__cancel-button {
		display: block;
		width: 100%;
		text-align: center;
		height: 40px;
		background-color: white;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;

		font-size: 12px;
		color: black;
		.transition(background .3s ease);
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		.museobold();
		&:hover {
			background: #fbfbfb;
		}
	}
	&__send-button {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background: @green-color;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		border: 0;
		text-align: center;

		color: white;
		letter-spacing: 1px;
		font-size: 14px;
		.museobold();
		cursor: pointer;
		.transition(all .3s ease);
		&:hover {
			background: @green-color-hover;
		}
	}
}
