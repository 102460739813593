.serv-page {
	max-width: 415px;
	margin: 20px auto;

	&__title {
		.museobold();
		font-size: 24px;
		text-align: center;
		padding-bottom: 20px;
		border-bottom: 1px solid #e7e7e7;
		margin-bottom: 20px;
	}
	&__container {
		padding-left: 18px;
		padding-right: 18px;
	}
	&__text {
		color: black;
		.museo();
		font-size: 14px;
		margin-bottom: 22px;
	}
	&__links-block {
		margin-bottom: 20px;
	}
	&__links-item {
		display: block;
		margin-bottom: 8px;
		height: 40px;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&_1 {
			background-color: #446aa5;
			&:after {
				.sprite(@icon-reg-1);
				margin-top: -@icon-reg-1-height / 2;
				margin-left: -@icon-reg-1-width / 2;
			}
			&:hover {
				background-color: rgba(68, 106, 165, 0.9);
			}
		}
		&_2 {
			background-color: #55acee;
			&:after {
				.sprite(@icon-reg-2);
				margin-top: -@icon-reg-2-height / 2;
				margin-left: -@icon-reg-2-width / 2;
			}
			&:hover {
				background-color: rgba(85, 172, 238, 0.91);
			}
		}
		&_3 {
			background-color: #dc4e41;
			&:after {
				.sprite(@icon-reg-3);
				margin-top: -@icon-reg-3-height / 2;
				margin-left: -@icon-reg-3-width / 2;
			}
			&:hover {
				background-color: rgba(220, 78, 65, 0.9);
			}
		}
	}

	&__section {

		&_to-hide {
			display: none;
		}
	}

	&__wrap-for-floats {
		margin-bottom: 10px;
	}
	&__forgot-password-link {
		float: right;
		color: #56a789;
		.museobold();
		font-size: 14px;
		text-decoration: none;
		&:hover {
			color: @green-color-hover;
		}
	}
	&__submit-button {
		width: 100%;
		color: white;
		font-size: 14px;
		.museobold();
		text-transform: uppercase;

		height: 40px;
		background-color: #56a789;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;
		letter-spacing: 1px;
		text-align: center;
		border: 0;
		padding: 0;
		cursor: pointer;
		&:hover {
			background-color: @green-color-hover;
		}
	}
	&__cancel-button {
		color: black;
		font-size: 14px;
		.museobold();
		text-transform: uppercase;
		text-decoration: none;
		background: white;
		display: block;
		height: 40px;
		border-radius: 2px;
		box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.11);
		line-height: 40px;
		letter-spacing: 1px;
		text-align: center;
		border: 0;
		padding: 0;
		&:hover {
			background: #fbfbfb;
		}
	}
}