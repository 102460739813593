.arcticmodal-overlay,
.arcticmodal-container {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
}

.arcticmodal-overlay {
	background: rgba(0, 0, 0, 0.4) !important;
}

.arcticmodal-container {
	overflow: auto;
	margin: 0;
	padding: 0;
	border: 0;
	border-collapse: collapse;
}

*:first-child + html .arcticmodal-container {
	height: 100%
}

.arcticmodal-container_i {
	height: 100%;
	margin: 0 auto;
}

.arcticmodal-container_i2 {
	padding: 0;
	margin: 0;
	border: 0;
	vertical-align: middle;
}

.arcticmodal-error {
	padding: 20px;
	border-radius: 10px;
	background: #000;
	color: #fff;
}

.arcticmodal-loading {
	width: 80px;
	height: 80px;
	border-radius: 10px;
	background: #000 url(../bower_components/arcticmodalbw/arcticmodal/loading.gif) no-repeat 50% 50%;
}


.box-modal {
	position: relative;
	//width: 289px;
	padding: 0;
	background: white;
	font-size: 16px;
	.regular();
	box-sizing: border-box;
	animation-duration: 800ms;
	-webkit-animation-duration: 800ms;
	background: rgba(0, 0, 0, 0.3);
	//&#choose-places {
	//	height: 85vh;
	//}

	&__close {
		position: absolute;
		top: 25px;
		right: 25px;
		.sprite(@icon-menu-close-black);
		cursor: pointer;
	}

	&__content {

	}

	&__text {
		//background: url(../img/white-repeat-bg.jpg) repeat 0 0;
		background-origin: border-box;
		max-width: 800px;
		padding: 40px;
		border-radius: 4px;
		margin-top: 40px;
		margin-bottom: 40px;

		.info-box {
			color: #333;
			p {
				color: inherit;
			}
			h1 {
				color: inherit;
			}
		}
	}
}

@media screen and (max-width: 420px) {
	.box-modal {
		animation-duration: 0ms;
		-webkit-animation-duration: 0ms;
	}
	.arcticmodal-container_i2 {
		padding: 0;
		margin: 0;
		border: 0;
		vertical-align: middle;
	}
}