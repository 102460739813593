.dropmenu {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    transform: translateY(-100%);
    z-index: 100;
    
    &.active {
        transform: translateY(64px);
    }

    &.ready {
        transition: transform 0.3s ease-in;
    }

    .container-min {
        height: 100%;
    }

    .dropmenu-container {
        height: 100%;
        background: white;
    }

    .dropmenu-row {
        display: flex;
        height: 100%;
    }

    .dropmenu-column {
        display: flex;
        flex-direction: column;
    }

    .dropmenu-left {
        justify-content: space-between;
        width: 130px;
        flex-shrink: 0;
        background-color: #F8F8F8;
    }

    .dropmenu-right {
        width: ~"calc(100% - 130px)";
        flex-shrink: 0;
    }

    .dropmenu-map {
        padding: 16px 13px;
        .medium();
        font-size: 14px;
        color: #56A789;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;

        &:before {
            content: '';
            position: relative;
            display: inline-block;
            .svg-icon(icon-location, 24px, 24px);
            margin-right: 6px;
            vertical-align: middle;
        }
    }

    .dropmenu-tabs {
        .dropmenu-tab {
            display: block;
            padding: 18px 24px;
            .medium();
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            line-height: 20px;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            align-items: center;

            &:after {
                display: inline-block;
                content: '';
                position: relative;
                .svg-icon(icon-arrow-down-black, 6px, 4px);
                transform: rotate(270deg);
                background-position: center center;
                background-size: contain;
                vertical-align: middle;
                margin-left: 10px;
                flex-shrink: 0;
            }

            &.active {
                color: #56A789;
                background-color: #FFFFFF;
            }
        }
    }

    .dropmenu-tabs-content {
        .dropmenu-tab-content {
            display: none;

            &.active {
                display: block;
            }

            .tab-container {
                padding: 0 105px 30px;
            }

            .heading {
                padding: 20px 0;
                opacity: 0.3;
                .museobold();
                font-size: 14px;
                color: #000000;
                letter-spacing: 1px;
                line-height: 20px;
                text-transform: uppercase;
                text-align: center;
            }

            .button-container {
                display: flex;
                justify-content: center;

                .btn-see-all {
                    background: #FED54E;
                    box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.11);
                    border-radius: 2px;
                    .museobold();
                    font-size: 12px;
                    color: #8C5508;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 20px;
                    padding: 5px 20px;
                    cursor: pointer;
                    outline: none;
                    flex-shrink: 0;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }

            .countries-block {
                display: flex;
                flex-wrap: wrap;

                a,
                a:hover,
                a:focus {
                    text-decoration: none;
                }

                .country-block {
                    width: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 24px;

                    &:hover {
                        p {
                            color: #509b7f;
                        }
                    }

                    img {
                        height: 60px;
                        width: 60px;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    p {
                        .museobold();
                        font-size: 14px;
                        color: #000000;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 16px;
                        text-align: center;
                        margin-top: 7px;
                        transition: color .3s ease;
                    }
                }
            }

            .cities-block {
                display: flex;
                flex-wrap: wrap;

                a,
                a:hover,
                a:focus {
                    text-decoration: none;
                }

                .city-block {
                    width: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 24px;

                    &:hover {
                        p {
                            color: #509b7f;
                        }
                    }

                    img {
                        height: 60px;
                        width: 60px;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    p {
                        .museobold();
                        font-size: 14px;
                        color: #000000;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 16px;
                        text-align: center;
                        margin-top: 4px;
                        transition: color .3s ease;
                    }

                    .country {
                        .regular();
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.4) !important;
                        letter-spacing: 0;
                        text-align: center;
                        margin-top: 8px;
                    }
                }
            }

            .routes-block {
                display: flex;
                flex-wrap: wrap;

                a,
                a:hover,
                a:focus {
                    text-decoration: none;
                }

                .route-block {
                    width: 184px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 24px;
                    margin-right: 20px;

                    &:hover {
                        p {
                            color: #509b7f;
                        }
                    }

                    .image {
                        height: 122px;
                        width: 184px;
                        background-position: center center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        position: relative;

                        .progress {
                            width: 44px;
                            height: 44px;
                            position: absolute;
                            right: 16px;
                            bottom: 16px;
                            z-index: 20;

                            & strong {
                                font-size: 11px;
                                .museo();
                                color: white;
                                position: absolute;
                                left: 0;
                                right: 0;
                                text-align: center;
                                top: 15px;
                                line-height: normal;
                            }
                        }
                    }

                    p {
                        .museobold();
                        font-size: 14px;
                        color: #000000;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 16px;
                        text-align: center;
                        margin-top: 9px;
                        transition: color .3s ease;
                    }

                    .visited {
                        .regular();
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.4) !important;
                        letter-spacing: 0;
                        text-align: center;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.dropmenu-backdrop {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &.active {
        opacity: 1;
    }
}

.js-show-dropmenu {
    &:after {
        display: inline-block;
        content: '';
        position: relative;
        .svg-icon(icon-arrow-down-black, 6px, 4px);
        background-position: center center;
        background-size: contain;
        vertical-align: middle;
        margin-left: 12px;
        margin-top: 9px;
    }
}